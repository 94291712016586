import styles from "./skills-section.module.css";

export const SkillsSection = () => {
  return (
    <div className={styles["skills-section"]}>
      <div className={`arker-container ${styles["skills-section-content"]}`}>
        <h3>UPGRADE YOUR SKILLS</h3>
        <img
          className="divider-s"
          src="/assets/images/elements/home-divider.svg"
          alt=""
        />
        <p>
          There are tons of cards to unlock, some of them can be played by all
          heroes, others are restricted to certain races. Open chests to try
          your luck, check the daily deals or buy it directly from other
          players.
        </p>
        <div className={styles["items-container"]}>
          <div className="row">
            <div
              className={`col-xl-2 col-lg-4 col-6 ${styles["card-container"]}`}
            >
              {/*  <div className={`${styles["card"]} ${styles["card-01"]}`}></div> */}
              <img src="/assets/images/items/arkanes/card-back.png" alt="" />
            </div>
            <div
              className={`col-xl-2 col-lg-4 col-6 ${styles["card-container"]}`}
            >
              {/* <div
                className={`${styles["card"]} ${styles["card-02"]} ${styles.bottom}`}
              ></div> */}
              <img
                className={styles.bottom}
                src="/assets/images/items/arkanes/arkane-01.png"
                alt=""
              />
            </div>
            <div
              className={`col-xl-2 col-lg-4 col-6 ${styles["card-container"]}`}
            >
              {/*  <div className={`${styles["card"]} ${styles["card-03"]}`}></div> */}
              <img src="/assets/images/items/arkanes/arkane-02.png" alt="" />
            </div>
            <div
              className={`col-xl-2 col-lg-4 col-6 ${styles["card-container"]}`}
            >
              {/*  <div
                className={`${styles["card"]} ${styles["card-04"]}  ${styles.bottom}`}
              ></div> */}
              <img
                className={styles.bottom}
                src="/assets/images/items/arkanes/arkane-03.png"
                alt=""
              />
            </div>
            <div
              className={`col-xl-2 col-lg-4 col-6 ${styles["card-container"]}`}
            >
              {/*  <div className={`${styles["card"]} ${styles["card-05"]}`}></div> */}
              <img src="/assets/images/items/arkanes/arkane-04.png" alt="" />
            </div>
            <div
              className={`col-xl-2 col-lg-4 col-6 ${styles["card-container"]}`}
            >
              {/* <div
                className={`${styles["card"]} ${styles["card-06"]}  ${styles.bottom}`}
              ></div> */}
              <img
                className={styles.bottom}
                src="/assets/images/items/arkanes/arkane-05.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <button
          className={`button-green button-green-small ${styles["play-button"]}`}
        >
          <a href="/account-settings/download">Play free</a>
        </button>
      </div>
    </div>
  );
};
