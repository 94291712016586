import styles from "./arrows.module.css";

export const NextArrow = ({
  className,
  onClick,
  itemsSlider = false,
  marketplaceSlider = false,
}) => {
  return (
    <div
      className={`${className} ${styles["next-arrow"]} ${
        itemsSlider && styles["items-slider-next-arrow"]
      } ${marketplaceSlider && styles["marketplace-slider-next-arrow"]}`}
      onClick={onClick}
    />
  );
};
