import { CheckItem } from "../check-item/check-item";
import { Feature } from "./feature/feature";
import styles from "./features-section.module.css";

export const FeaturesSection = () => {
  return (
    <div className={styles["features-section"]}>
      <div className={styles.features}>
        <div className={`row ${styles["features-container"]}`}>
          <div className="col center">
            <Feature
              id="feature01"
              img="/assets/images/features/game-features01.png"
              imgMobile="/assets/images/features/game-features01-mobile.png"
              title="UNIQUE COLLECTIBLE CARDS"
              text="Your heroe can cast powerful spells or attacks. Each Arkane (Card) represents one of those movements. Collect it all."
            />
          </div>
          <div className="col center">
            <Feature
              id="feature02"
              img="/assets/images/features/game-features02.png"
              imgMobile="/assets/images/features/game-features02-mobile.png"
              title="CROSS-PLAY"
              text="Play between Windows, tablet or phone, doesn’t matter, your heroe is always by your side."
            />
          </div>
          <div className="col center">
            <Feature
              id="feature03"
              img="/assets/images/features/game-features03.png"
              imgMobile="/assets/images/features/game-features03-mobile.png"
              title="DIFFERENT GAME MODES"
              text="Train and evolve your character playing PVE and get ready to smash other heroes in our PVP arena."
            />
          </div>
          <div className="col center">
            <Feature
              id="feature04"
              img="/assets/images/features/game-features04.png"
              imgMobile="/assets/images/features/game-features04-mobile.png"
              title="SELL YOUR ITEMS ANYTIME"
              text="You own your items and you can trade them for ARKER whenever you want. Sell or buy directly from other players."
            />
          </div>
        </div>
      </div>

      <div className={`${styles["bg-img"]}`}>
        <div className={`${styles["start-journey-container"]} arker-container`}>
          <div className={styles["start-journey"]}>
            <h2>
              Start your <br />
              journey now!
            </h2>
            <p>
              Arker is an online turn-based game which will place you in the
              role of controlling a hero with his pet in order to regain control
              of the kingdom of Ohm. <br />
              <br /> Skills, runes, equipment, characters, pets and incredible
              PVP / PVE content will make your stay in Ohm unforgettable.
            </p>

            <div className={styles["start-journey-features"]}>
              <div>
                <img src="/assets/images/icons/player-icon.svg" alt="" />
                <span>Active players in the community</span>
              </div>
              <div>
                <img src="/assets/images/icons/tournament-icon.svg" alt="" />
                <span>Weekly Tournaments</span>
              </div>
              <div>
                <img src="/assets/images/icons/coin-icon.svg" alt="" />
                <span>Earn money by playing</span>
              </div>
              <div>
                <img src="/assets/images/icons/security-icon.svg" alt="" />
                <span>Transaction security</span>
              </div>
            </div>
          </div>
          <div className={styles["start-journey-img"]}>
            <img src="/assets/images/img-ui-combat.png" alt="" />
          </div>
        </div>

        <div className={`${styles.highlights} arker-container`}>
          <div className={styles["highlights-content"]}>
            <img
              className={styles["highlights-img"]}
              src="/assets/images/all-character.png"
              alt=""
            />
            <div className={styles["get-highlights"]}>
              <h2>Get the highlights</h2>
              <p>
                In this strategic card game, it's skill, not luck, that defines
                your success. Combine cards, allies, and relics to create
                unprecedented synergies between.
              </p>

              <div className={styles["highlights-items"]}>
                <CheckItem text={"Deck Building"} />
                <CheckItem text={"Buying and Selling Cards"} />
                <CheckItem text={"Various game modes"} />
                <CheckItem text={"PVP, PVE, CLANS"} />
              </div>
            </div>
          </div>
          <button
            className={`button-green button-green-small ${styles["play-button"]}`}
          >
            <a href="/account-settings/download">Play free</a>
          </button>
        </div>
      </div>
    </div>
  );
};
