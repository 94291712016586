/* eslint-disable jsx-a11y/anchor-has-content */
import styles from "./main-section.module.css";

export const MainSection = () => {
  return (
    <div className={styles["main-section"]}>
      <div className={styles["social-media-container"]}>
        <a
          href="https://t.me/ArkerCommunity"
          target="_blank"
          rel="noreferrer"
          className={styles["telegram"]}
        ></a>
        <a
          href="https://www.youtube.com/@ArkerOfficial"
          target="_blank"
          rel="noreferrer"
          className={styles["youtube"]}
        ></a>
        <a
          href="https://twitter.com/ArkerCommunity"
          target="_blank"
          rel="noreferrer"
          className={styles["twitter"]}
        ></a>
        {/*  <a
          href="https://github.com/Arker-The-legend-of-Ohm"
          target="_blank"
          rel="noreferrer"
          className={styles["github"]}
        ></a> */}
      </div>
      <div className={`arker-container ${styles["main-section-content"]}`}>
        <img
          className={styles["logo-small"]}
          src="/assets/images/logos/logo-arker-small.png"
          alt=""
        />
        <h1>TEST YOUR SKILLS IN EPIC BATTLES AND PLAY TO EARN</h1>
        <img
          className="divider-g"
          src="/assets/images/elements/home-divider.svg"
          alt=""
        />
        <button
          className={`button-green button-green-big ${styles["play-button"]}`}
        >
          <a href="/account-settings/download">Play free</a>
        </button>
        <div className={styles["platforms-container"]}>
          <div className={styles["platforms-buttons-container"]}>
            <a
              href="/account-settings/download"
              target="_blank"
              rel="noreferrer"
              className={`${styles["windows"]} ${styles["mobile-icon"]}`}
            />
            <a
              href="/account-settings/download"
              target="_blank"
              rel="noreferrer"
              className={`${styles["platform-button"]}`}
            >
              <img src="/assets/images/icons/windows-icon.svg" alt="" />
              <span>
                Available in the
                <br /> PC version
              </span>
            </a>
            <a
              href="https://apps.apple.com/us/app/arker-the-legend-of-ohm/id1369545258"
              target="_blank"
              rel="noreferrer"
              className={`${styles["ios"]} ${styles["mobile-icon"]}`}
            />
            <a
              href="https://apps.apple.com/us/app/arker-the-legend-of-ohm/id1369545258"
              target="_blank"
              rel="noreferrer"
              className={`${styles["platform-button"]}`}
            >
              <img src="/assets/images/icons/ios-icon.svg" alt="" />
              <span>
                Download from
                <br /> the APP Store
              </span>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.arkerlabs.arker&pli=1"
              target="_blank"
              rel="noreferrer"
              className={`${styles["android"]} ${styles["mobile-icon"]}`}
            />
            <a
              href="https://play.google.com/store/apps/details?id=com.arkerlabs.arker&pli=1"
              target="_blank"
              rel="noreferrer"
              className={`${styles["platform-button"]}`}
            >
              <img src="/assets/images/icons/android-icon.svg" alt="" />
              <span>
                Available on
                <br /> Google Play
              </span>
            </a>
          </div>
          <img
            className={styles["blockchain-platform"]}
            src="/assets/images/icons/bsc-icon.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
