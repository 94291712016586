import { InventorySection } from "./inventory-section";
import styles from "./inventory-section.module.css";

export const InventoryContainer = () => {
  return (
    <div className={styles.inventory}>
      <h4>Inventory</h4>

      <InventorySection type="EGGS" />
      <InventorySection type="PETS" />

      <a href="/inventory/fheral/breeding" className={`${styles.breeding}`}>
        <button className={`see-detail-button`}>Breeding</button>
      </a>
    </div>
  );
};
