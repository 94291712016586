import { useWriteContract } from "wagmi";

export const useSell = () => {
  const {
    writeContractAsync: sellItemWrite,
    error: sellItemError,
    isError: isSellItemError,
  } = useWriteContract();

  return { sellItemWrite, sellItemError, isSellItemError };
};
