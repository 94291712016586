import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

export const RedirectPage = () => {
  //LOCATION VARIABLES
  const location = useLocation();
  const pathname = location.pathname.split("/")[2];
  const [searchParams] = useSearchParams();
  const wombat_UID = searchParams.get("uid");
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === "wombat") {
      localStorage.setItem("wombat.uid", wombat_UID);
    }

    navigate("/");
  }, [pathname, wombat_UID, navigate]);
};
