import { getApiURL } from "./utils";

export const withdraw = async (amount) => {
  const WITHDRAW_ENDPOINT = `${getApiURL()}/users/arker/withdraw`;

  const res = await fetch(WITHDRAW_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("arker_auth"))[1]
      }`,
    },
    body: JSON.stringify({ amount }),
  });

  if (!res.ok) {
    const data = await res.json();
    if (res.status === 404) throw new Error("404, Not found");
    if (res.status === 500) throw new Error("500, internal server error");
    throw new Error(`${data.statusCode} ${data.error} - ${data.message}`);
  }

  const data = await res.json();
  return data;
};

export const getWithdraws = async (quantity) => {
  const GET_WITHDRAWS_ENDPOINT = `${getApiURL()}/users/arker/last-withdraws`;

  const res = await fetch(GET_WITHDRAWS_ENDPOINT, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("arker_auth"))[1]
      }`,
    },
  });

  if (!res.ok) {
    const data = await res.json();
    if (res.status === 404) throw new Error("404, Not found");
    if (res.status === 500) throw new Error("500, internal server error");
    throw new Error(`${data.statusCode} ${data.error} - ${data.message}`);
  }

  const data = await res.json();
  return data;
};

export const filterWithdraws = (withdraws) => {};

export const checkNonce = () => {};
