import { useEffect, useState } from "react";
import { useCheckNonce } from "../../../../hooks/paymentHooks/useCheckNonce";
import { formatEther } from "viem";
import moment from "moment";
import styles from "./account-settings-profile-details.module.css";
import {
  getChainId,
  getPaymentManagerAddress,
} from "../../../../services/utils";
import { useChainId, useSwitchChain } from "wagmi";
import { useClaimPayment } from "../../../../hooks/paymentHooks/useClaimPayment";
import toast from "react-hot-toast";
import paymentManagerABI from "../../../../assets/abis/payments.json";

export const WithdrawTableItem = ({ withdraw, i }) => {
  console.log(i);
  //USESTATE VARIABLES
  const [completed, setCompleted] = useState(false);
  const [humanValue, setHumanValue] = useState();
  const [loading, setLoading] = useState(false);

  //WAGMI HOOKS
  const chainId = useChainId();
  const { switchChain } = useSwitchChain();

  //PAYMENT HOOKS
  const { usedNonce, usedNonceLoading } = useCheckNonce(withdraw.nonce);
  const { claimPaymentWrite, claimPaymentError, isClaimPaymentError } =
    useClaimPayment();

  const handleWithdrawending = async () => {
    setLoading(true);

    try {
      await claimPaymentWrite({
        abi: paymentManagerABI,
        address: getPaymentManagerAddress(),
        functionName: "claimPayment",
        args: [withdraw.finalAmount, withdraw.nonce, withdraw.signature],
        chainId: getChainId(),
      });
      setLoading(false);
      toast.success("Withdraw successful");
    } catch (error) {
      toast.error(error.toString());
      setLoading(false);
    }
  };

  useEffect(() => {
    setHumanValue(formatEther(withdraw.finalAmount ?? 0n));
    if (withdraw.created < new Date(1712944800000)) {
      setCompleted(true);
    } else {
      if (!usedNonceLoading) {
        setCompleted(usedNonce);
      }
    }
  }, [usedNonceLoading, usedNonce, withdraw]);

  useEffect(() => {
    if (isClaimPaymentError) {
      toast.error(claimPaymentError.shortMessage);
    }
  }, [claimPaymentError, isClaimPaymentError]);

  if (usedNonceLoading) {
    return (
      <tr>
        <td>Loading…</td>
        <td>Loading…</td>
        <td>Loading…</td>
        <td>Loading…</td>
      </tr>
    );
  }
  return (
    <tr className={i % 2 === 0 ? styles.odd : null}>
      <td>{moment(withdraw.created).format("MMM D Y HH:mm")}</td>
      <td>{humanValue}</td>
      <td>{completed ? "Completed" : "Pending"}</td>
      <td>
        {!completed ? (
          chainId !== getChainId() ? (
            <button
              className={styles["withdraw-table-button"]}
              onClick={() =>
                switchChain({
                  chainId: getChainId(),
                })
              }
            >
              Change network
            </button>
          ) : (
            <button
              onClick={handleWithdrawending}
              className={styles["withdraw-table-button"]}
              disabled={loading}
            >
              {loading ? "Loading…" : "Withdraw"}
            </button>
          )
        ) : (
          <></>
        )}
      </td>
    </tr>
  );
};
