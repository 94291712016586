import styles from "./feature.module.css";

export const Feature = ({ img, imgMobile, title, text, id }) => {
  return (
    <>
      <div
        className={`${styles.feature} ${styles[id]}`}
        /* style={{
          "--desktop-img": `url(${img})`,
          "--mobile-img": `url(${imgMobile})`,
        }} */
      >
        <div className={styles["feature-content"]}>
          <h2>{title}</h2>
          <p>{text}</p>
        </div>
      </div>
    </>
  );
};
