import { useEffect, useState } from "react";
import { useAccount, useSignMessage } from "wagmi";
import {
  getProfileData,
  linkExternalWallet,
} from "../../../../services/account";
import styles from "../buttons.module.css";
import toast, { Toaster } from "react-hot-toast";
import { useUserAuthenticated } from "../../../../hooks/useUserAuthenticated";

export const ArkerPairWalletButton = ({ setNewWalletPaired }) => {
  //USESTATE VARIABLES
  const [userData, setUserData] = useState();

  //USER HOOKS
  const { isAuthenticated } = useUserAuthenticated();

  //WAGMI HOOKS
  const { signMessageAsync } = useSignMessage();
  const { address } = useAccount();

  const handlePairWallet = async () => {
    try {
      const signature = await signMessageAsync({
        message: `Arker Sync - ${userData.id}`,
      });
      await linkExternalWallet(signature);
      setNewWalletPaired(address);
    } catch (error) {
      console.log(error);
      toast.error(error.toString());
    }
  };

  useEffect(() => {
    const getUserData = async () => {
      const data = await getProfileData();
      setUserData(data);
    };

    isAuthenticated && getUserData();
  }, [isAuthenticated]);

  return (
    <>
      <Toaster />
      <button
        className={`${styles["account-button"]}`}
        onClick={handlePairWallet}
        type="button"
      >
        {userData?.externalWallet ? "Pair different walllet" : "Pair wallet"}
      </button>
    </>
  );
};
