import styles from "./blog-post-card-skelton.module.css";

export const BlogPostCardSkeleton = () => {
  return (
    <div className={styles["blog-post-card"]}>
      <div
        className={`${styles["blog-post-skeleton-img"]} ${styles.skeleton}`}
      />
      <div className={styles["blog-post-card-content"]}>
        <h4>
          <div className={styles.separator} />
          <div
            className={`${styles["blog-post-skeleton-title"]} ${styles.skeleton}`}
          />
        </h4>
        <div
          className={`${styles["blog-post-skeleton-text"]} ${styles.skeleton}`}
        />
        <div className={styles["blog-post-footer"]}>
          <span>
            <img
              className={styles.heart}
              src="/assets/images/icons/heart-icon.svg"
              alt=""
            />{" "}
            <div
              className={`${styles["blog-post-skeleton-footer-item"]} ${styles.skeleton}`}
            />
          </span>
          <span>
            <img
              className={styles.minus}
              src="/assets/images/icons/minus-icon.svg"
              alt=""
            />{" "}
            <div
              className={`${styles["blog-post-skeleton-footer-item"]} ${styles.skeleton}`}
            />
          </span>
          <img
            className={styles.share}
            src="/assets/images/icons/share-icon.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
