import styles from "./partners-section.module.css";

export const PartnersSection = () => {
  return (
    <div className={styles.partinersSection}>
      <h3 className={styles["partners-title"]}>partners & backers</h3>
      <img
        className="divider-s"
        src="/assets/images/elements/home-divider.svg"
        alt=""
      />
      <p className={styles["partners-text"]}>
        Find some of the people that helps to build and grow the Arker
        ecosystem.
      </p>
      <img
        className={styles["partners-img"]}
        src="/assets/images/logos/partners.svg"
        alt=""
      />

      <img
        className={styles["partners-img-mobile"]}
        src="/assets/images/logos/partners-mobile.svg"
        alt=""
      />
    </div>
  );
};
