import styles from "./account-activity-table.module.css";
import moment from "moment";

export const AccountActivityTable = ({ activity }) => {
  if (activity?.length === 0) {
    return (
      <div className={styles.table}>
        <ul>
          <li>Event</li>
          <li>Price</li>
          <li>To(Wallet)</li>
          <li>Buyed(Date)</li>
        </ul>

        <div className={styles["p-container"]}>
          <p>Any activity to display</p>
        </div>
      </div>
    );
  }
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>Date</th>
          <th>Purchase</th>
          <th>Total</th>
          <th>Order#</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {activity?.map((item, i) => {
          return (
            <tr className={i % 2 === 0 ? styles.odd : <></>}>
              <td>{moment.unix(item.created).format("MMM DD, YYYY")}</td>
              <td>{item.buyed.toString() === "true" ? "Yes" : "No"}</td>
              <td>{item.amount} ARKER</td>
              <td>{item.saleId}</td>
              <td>
                {item.buyed
                  ? "Purchased"
                  : item.expire >= (Date.now() / 1000).toFixed(0)
                  ? "Active"
                  : "Expired"}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
