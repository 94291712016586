import { useWriteContract } from "wagmi";

export const useHatch = () => {
  const {
    writeContractAsync: hatchWrite,
    error: hatchError,
    isError: isHatchError,
  } = useWriteContract();

  return { hatchWrite, hatchError, isHatchError };
};
