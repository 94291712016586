import { useEffect, useState } from "react";
import { getProfileData } from "../services/account";
import { useUserAuthenticated } from "./useUserAuthenticated";

export const useUserData = (refetch = false) => {
  const [userData, setUserData] = useState();
  const [userDataLoading, setUserDataLoading] = useState(true);
  const { isAuthenticated } = useUserAuthenticated();

  useEffect(() => {
    const getUserData = async () => {
      setUserData(await getProfileData());
      setUserDataLoading(false);
    };

    isAuthenticated && getUserData();
  }, [isAuthenticated, refetch]);

  return { userData, userDataLoading };
};
