import { getApiURL } from "./utils";

export const getPetsAddress = () => {
  return process.env.REACT_APP_PRODUCTION === "1"
    ? process.env.REACT_APP_BSC_PETS
    : process.env.REACT_APP_SEPOLIA_PETS;
};

export const getTypeName = (type) => {
  if (parseInt(type + "", undefined) === 1) {
    return "crab";
  } else if (parseInt(type + "", undefined) === 2) {
    return "wolf";
  } else if (parseInt(type + "", undefined) === 4) {
    return "beetle";
  } else if (parseInt(type + "", undefined) === 5) {
    return "tiger";
  } else {
    return "phoenix";
  }
};

export const getRarityNumber = (pet) => {
  let rarity = 1;
  if (parseInt(pet._skin ?? pet[5] + "", undefined) > 0) {
    rarity = rarity + 1;
  }
  const total =
    parseInt(pet._attack ?? pet[1] + "", undefined) +
    parseInt(pet._life ?? pet[2] + "", undefined) +
    parseInt(pet._energy ?? pet[3] + "", undefined) +
    parseInt(pet._agility ?? pet[4] + "", undefined) +
    parseInt(pet._power ?? pet[6] + "", undefined);
  if (total > 360) {
    rarity = rarity + 2;
  } else if (total > 230) {
    rarity = rarity + 1;
  }
  return rarity;
};

export const getRarity = (pet) => {
  const rarity = getRarityNumber(pet);
  if (rarity === 1) {
    return "Common";
  } else if (rarity === 2) {
    return "Rare";
  } else if (rarity === 3) {
    return "Epic";
  } else if (rarity === 4) {
    return "Legendary";
  }
};

export const setPassiveDesc = (pet) => {
  const type = getTypeName(pet[0]);
  if (type === "crab") {
    return "Deals damage to the enemy each turn depending on their power.";
  } else if (type === "wolf") {
    return "Restores energy to your hero each turn depending on their power.";
  } else if (type === "beetle") {
    return "Restores life to your hero each turn depending on their power.";
  } else if (type === "tiger") {
    return "Deals damage to the enemy each turn depending on their power.";
  } else {
    return "Restores life to your hero each turn depending on their power.";
  }
};

export const parsePetDataForEggParents = (petId, petData, petParents) => {
  const type = getTypeName(petData._type ?? petData[7]);
  const tempName =
    parseInt(petParents[0]) === 0
      ? `${type.toUpperCase()} Genesis`
      : `${type.toUpperCase()}`;

  return {
    skin: petData._skin ?? petData[6],
    type: type,
    name: `${tempName} #${petId}`,
  };
};

export const getOffchainPetData = async (petId) => {
  const GET_PET_DATA_OFFCHAIN = `${getApiURL()}/pets/by-id/${petId}`;

  const res = await fetch(GET_PET_DATA_OFFCHAIN, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!res.ok) {
    const data = await res.json();
    if (res.status === 404) throw new Error("404, Not found");
    if (res.status === 500) throw new Error("500, internal server error");
    throw new Error(`${data.statusCode} ${data.error} - ${data.message}`);
  }

  return await res.json();
};
