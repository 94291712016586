import { useLocation } from "react-router-dom";
import styles from "./marketplace-footer.module.css";

export const MarketplaceFooter = () => {
  const { pathname } = useLocation();

  return (
    <>
      {pathname !== "/login" &&
        pathname !== "/register" &&
        pathname.substring(0, 16) !== "/profile/recover" && (
          <div className={styles["img-footer"]}>
            <h2>Are you ready for the fight?</h2>
            <p>
              Still thinking about it? The Ohm world needs new heroes to protect
              it and it’s precious resources. Download it now for free, create
              your first hero, open your first cards and start fighting right
              now.
            </p>
            <button className={`button-green`}>
              <a href="/account-settings/download">Play arker for free</a>
            </button>
          </div>
        )}
      <div className={styles["text-footer"]}>
        <div className={styles["footer-links"]}>
          <ul>
            <li>
              <a
                href="https://docs.playarker.com/"
                target="_blank"
                rel="noreferrer"
              >
                docs
              </a>
            </li>
            <li>
              <a href="/marketplace">marketplace</a>
            </li>
            <li>
              <a
                href="https://docs.playarker.com/"
                target="_blank"
                rel="noreferrer"
              >
                how to play
              </a>
            </li>
            <li>
              <a
                href="https://playarker.com/assets/docs/whitepaper.pdf"
                target="_blank"
                rel="noreferrer"
              >
                whitepaper
              </a>
            </li>
            {/* <li>pet fight</li> */}
            {/* <li>support</li> */}
          </ul>
          <ul>
            <li>
              <a
                href="https://medium.com/@arker-official"
                target="_blank"
                rel="noreferrer"
              >
                medium
              </a>{" "}
            </li>
            <li>
              <a
                href="https://github.com/Arker-The-legend-of-Ohm"
                target="_blank"
                rel="noreferrer"
              >
                github
              </a>
            </li>
            <li>
              <a
                href="https://t.me/ArkerOfficial"
                target="_blank"
                rel="noreferrer"
              >
                news
              </a>
            </li>
            <li>
              <a
                href="https://skynet.certik.com/projects/arker"
                target="_blank"
                rel="noreferrer"
              >
                certik audit
              </a>
            </li>
            <li>
              <a href="/privacy" target="_blank" rel="noreferrer">
                privacy & policies
              </a>
            </li>
          </ul>
        </div>

        <img
          className={styles["footer-logo"]}
          src="/assets/images/logos/logo-arker.png"
          alt=""
        />

        <hr />
        <div className={styles["sub-footer"]}>
          <p>Arker the legend of ohm - all rights reserved 2024</p>
          <ul
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "30px 0 0 0",
            }}
          >
            <li>
              <a
                href="https://t.me/ArkerCommunity"
                target="_blank"
                rel="noreferrer"
                className={styles["telegram"]}
              >
                {" "}
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@ArkerOfficial"
                target="_blank"
                rel="noreferrer"
                className={styles["youtube"]}
              >
                {" "}
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/ArkerCommunity"
                target="_blank"
                rel="noreferrer"
                className={styles["twitter"]}
              >
                {" "}
              </a>
            </li>
            <li>
              <a
                href="https://github.com/Arker-The-legend-of-Ohm"
                target="_blank"
                rel="noreferrer"
                className={styles["github"]}
              >
                {" "}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
