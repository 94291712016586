import { useParsePetData } from "../../../../../../hooks/petsHooks/useParsePetData";
import { parseSkinNumToImage } from "../../../../../../services/utils";
import styles from "./pet-selected-to-show.module.css";

export const PetSelectedToShow = ({ id }) => {
  //PETS HOOKS
  const { petData, petDataLoading } = useParsePetData(id);

  if (petDataLoading) {
    return <h1>Loading…</h1>;
  }

  return (
    <div className={styles["pet-to-show-container"]}>
      <img
        src={`/assets/images/marketplace/pets/${petData.type}/${
          parseSkinNumToImage(petData.skin)
        }.gif`}
        alt=""
      />
    </div>
  );
};
