import { encodePacked, fromHex, keccak256 } from "viem";
import { publicClient } from "./viem-client";

export const getEggsAddress = () => {
  return process.env.REACT_APP_PRODUCTION === "1"
    ? process.env.REACT_APP_BSC_BREEDER
    : process.env.REACT_APP_SEPOLIA_BREEDER;
};

export const getBreedCooldownForPet = async (petId) => {
  const pad = (text) => {
    const size = 64;
    let s = String(text);
    while (s.length < (size || 2)) {
      s = "0" + s;
    }
    return s;
  };

  const key = pad(petId);

  const hex = await publicClient.getStorageAt({
    address: getEggsAddress(),
    slot: keccak256(encodePacked(["uint256", "uint"], [key, 17])),
  });

  const timestamp = fromHex(hex, "number");

  if (timestamp === 0) {
    return null;
  }

  return new Date(timestamp * 1000);
};
