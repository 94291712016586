import styles from "./champions-slider.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PrevArrow } from "../../../slick-slider-arrows/prev-arrow";
import { NextArrow } from "../../../slick-slider-arrows/next-arrow";

export const ChampionsSlider = () => {
  const slickSliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "400px",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1860,
        settings: {
          centerMode: true,
          centerPadding: "300px",
        },
      },
      {
        breakpoint: 1658,
        settings: {
          centerMode: true,
          centerPadding: "250px",
        },
      },
      {
        breakpoint: 1560,
        settings: {
          centerMode: true,
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 1462,
        settings: {
          centerMode: true,
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 1366,
        settings: {
          centerMode: true,
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 1264,
        settings: {
          centerMode: true,
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 1200,
        settings: {
          centerMode: true,
          centerPadding: "70px",
        },
      },
      {
        breakpoint: 485,
        settings: {
          centerMode: false,
        },
      },
    ],
  };

  return (
    <Slider {...slickSliderSettings}>
      <div
        className={`${styles["slick-slider-item"]} ${styles["izarian-slide"]}`}
      >
        <div className={styles["slick-slider-item-content"]}>
          <h3>Izarian</h3>
          <p>
            Are recognized for their incredible physical prowess and acrobatics,
            as well as his amazing mimetic capacities. They use light and curved
            blades, perfect for attacking without giving an option.
          </p>
        </div>
      </div>
      <div
        className={`${styles["slick-slider-item"]} ${styles["berserker-slide"]}`}
      >
        <div className={styles["slick-slider-item-content"]}>
          <h3>Berserker</h3>
          <p>
            Are powerful warriors, strong as animals, who are used to fight
            half-naked carrying bloody shields and don't hesitate to bite their
            enemies if they have the chance.
          </p>
        </div>
      </div>
      <div
        className={`${styles["slick-slider-item"]} ${styles["alchemist-slide"]}`}
      >
        <div className={styles["slick-slider-item-content"]}>
          <h3>alchemist</h3>
          <p>
            Are cult and intelligent investigators, studious of the old
            protocience doctrines. They spend most of their time working in
            their basements, where they study ancient books and scrolls.
          </p>
        </div>
      </div>
      <div
        className={`${styles["slick-slider-item"]} ${styles["wasteller-slide"]}`}
      >
        <div className={styles["slick-slider-item-content"]}>
          <h3>wasteller</h3>
          <p>
            They are known for their musical prowess and captivating
            storytelling. However, a dark shadow surrounds them, and their
            mysterious past suggests a darker side to their nature. There is
            always an unsettling sense that accompanies them...
          </p>
        </div>
      </div>
      <div
        className={`${styles["slick-slider-item"]} ${styles["soulhunter-slide"]}`}
      >
        <div className={styles["slick-slider-item-content"]}>
          <h3>soulhunter</h3>
          <p>
            Always clad in pure darkness, conceal in long pleated cloaks or
            covered in thick coats used to protect themselves from outdoors,
            these Shikâr seeds are as unfathomable as their imperturbable
            hieratic faces and their sharp gazes lacking in emotion.
          </p>
        </div>
      </div>
    </Slider>
  );
};
