import { useReadContract } from "wagmi";
import paymentManagerABI from "../../assets/abis/payments.json";
import { getChainId, getPaymentManagerAddress } from "../../services/utils";

export const useCheckNonce = (nonce) => {
  const { data: usedNonce, isLoading: usedNonceLoading } = useReadContract({
    abi: paymentManagerABI,
    address: getPaymentManagerAddress(),
    functionName: "usedNonces",
    args: [nonce],
    chainId: getChainId(),
  });

  return { usedNonce, usedNonceLoading };
};
