import { MarketplaceItemPet } from "./marketplace-item/marketplace-item-pet";
import styles from "./marketplace.module.css";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { getEggsAddress } from "../../services/eggs";
import { MarketplaceItemEgg } from "./marketplace-item/marketplace-item-egg";
import {
  MarketplaceQuery,
  marketplaceQueryVariables,
  MarketplaceQueryWithAddress,
  num_of_items,
} from "../../services/graphql-queries";
import { SelectedItemContext } from "../..";
import { getPetsAddress } from "../../services/pets";

export const Marketplace = () => {
  //CONTEXT VARIABLES
  const { marketplaceItemNavSelected } = useContext(SelectedItemContext);

  //USESTATE VARIABLES
  const [items, setItems] = useState([]);
  const [allSalesItemsLoaded, setAllSalesItemsLoaded] = useState(false);

  //GRAPHQL QUERY
  const { loading, fetchMore, refetch } = useQuery(
    marketplaceItemNavSelected !== "ALL_ITEMS"
      ? MarketplaceQueryWithAddress
      : MarketplaceQuery,
    {
      context: {
        clientName:
          process.env.REACT_APP_PRODUCTION === "1"
            ? "marketplaceEndpointProd"
            : "marketplaceEndpointTest",
      },
      variables: {
        ...marketplaceQueryVariables,
        tokenAddress:
          marketplaceItemNavSelected === "EGGS"
            ? getEggsAddress()
            : marketplaceItemNavSelected === "PETS"
            ? getPetsAddress()
            : "",
      },
      onCompleted(queryData) {
        setItems(queryData.saleEntities);
        queryData.saleEntities.length < num_of_items &&
          setAllSalesItemsLoaded(true);
      },
      onError(error) {
        console.log(error);
      },
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFilter = async (e) => {
    if (e.target.checked) {
      switch (e.target.id) {
        case "expensive":
          marketplaceQueryVariables.orderBy = "amount";
          marketplaceQueryVariables.orderDirection = "desc";
          marketplaceQueryVariables.skip = 0;
          marketplaceQueryVariables.actualTimestamp = (
            Date.now() / 1000
          ).toFixed(0);
          marketplaceQueryVariables.first = 25;
          break;
        case "cheaper":
          marketplaceQueryVariables.orderBy = "amount";
          marketplaceQueryVariables.orderDirection = "asc";
          marketplaceQueryVariables.skip = 0;
          marketplaceQueryVariables.actualTimestamp = (
            Date.now() / 1000
          ).toFixed(0);
          marketplaceQueryVariables.first = 35;
          break;
        case "older":
          marketplaceQueryVariables.orderBy = "created";
          marketplaceQueryVariables.orderDirection = "asc";
          marketplaceQueryVariables.skip = 0;
          marketplaceQueryVariables.actualTimestamp = (
            Date.now() / 1000
          ).toFixed(0);
          marketplaceQueryVariables.first = 35;
          break;
        case "newer":
          marketplaceQueryVariables.orderBy = "created";
          marketplaceQueryVariables.orderDirection = "desc";
          marketplaceQueryVariables.skip = 0;
          marketplaceQueryVariables.actualTimestamp = (
            Date.now() / 1000
          ).toFixed(0);
          marketplaceQueryVariables.first = 25;
          break;
        default:
          break;
      }
    } else {
      marketplaceQueryVariables.orderBy = "created";
      marketplaceQueryVariables.orderDirection = "desc";
      marketplaceQueryVariables.skip = 0;
      marketplaceQueryVariables.actualTimestamp = (Date.now() / 1000).toFixed(
        0
      );
    }

    const res = await refetch(marketplaceQueryVariables);
    setItems(res.data.saleEntities);
    setAllSalesItemsLoaded(false);
  };

  if (loading) {
    return (
      <div className={styles["loading-container"]}>
        <h1>Loading…</h1>
      </div>
    );
  }

  return (
    <div className={styles["marketplace-content"]}>
      <div
        className={`arker-container ${styles["marketplace-content-container"]}`}
      >
        <div className={styles.sidebar}>
          <div className={styles.filters}>
            <section className={styles.accordion}>
              <div className={styles.tab}>
                <input type="checkbox" name="accordion-1" id="cb1" />
                <label htmlFor="cb1" className={styles.tab__label}>
                  Status{" "}
                  <img
                    src="/assets/images/icons/bottom-arrow-icon.svg"
                    alt=""
                  />
                </label>
                <div className={styles.tab__content}>
                  <ul>
                    <li>
                      <label htmlFor="expensive">
                        Expensive
                        <input
                          onChange={handleFilter}
                          type="radio"
                          name="status"
                          id="expensive"
                        />{" "}
                        <div className={styles.checkbox_square}>
                          <img
                            src="/assets/images/icons/checkbox-input.svg"
                            alt=""
                          />
                        </div>
                      </label>
                    </li>
                    <li>
                      <label htmlFor="cheaper">
                        cheaper
                        <input
                          onChange={handleFilter}
                          type="radio"
                          name="status"
                          id="cheaper"
                        />{" "}
                        <div className={styles.checkbox_square}>
                          <img
                            src="/assets/images/icons/checkbox-input.svg"
                            alt=""
                          />
                        </div>
                      </label>
                    </li>
                    <li>
                      <label htmlFor="older">
                        older
                        <input
                          onChange={handleFilter}
                          type="radio"
                          name="status"
                          id="older"
                        />{" "}
                        <div className={styles.checkbox_square}>
                          <img
                            src="/assets/images/icons/checkbox-input.svg"
                            alt=""
                          />
                        </div>
                      </label>
                    </li>
                    <li>
                      <label htmlFor="newer">
                        newer
                        <input
                          onChange={handleFilter}
                          type="radio"
                          name="status"
                          id="newer"
                        />{" "}
                        <div className={styles.checkbox_square}>
                          <img
                            src="/assets/images/icons/checkbox-input.svg"
                            alt=""
                          />
                        </div>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>

              <>
                {/* <div className={styles.tab}>
                <input type="checkbox" name="accordion-2" id="cb2" />
                <label htmlFor="cb2" className={styles.tab__label}>
                  Archetype
                  <img
                    src="/assets/images/icons/bottom-arrow-icon.svg"
                    alt=""
                  />
                </label>
                <div className={styles.tab__content}>
                  <ul>
                    <li>
                      <label htmlFor="izarian">
                        izarian
                        <input type="checkbox" id="izarian" />{" "}
                        <div className={styles.checkbox_square}>
                          <img
                            src="/assets/images/icons/checkbox-input.svg"
                            alt=""
                          />
                        </div>
                      </label>
                    </li>
                    <li>
                      <label htmlFor="berserker">
                        berserker
                        <input type="checkbox" id="berserker" />{" "}
                        <div className={styles.checkbox_square}>
                          <img
                            src="/assets/images/icons/checkbox-input.svg"
                            alt=""
                          />
                        </div>
                      </label>
                    </li>
                    <li>
                      <label htmlFor="alchemist">
                        alchemist
                        <input type="checkbox" id="alchemist" />{" "}
                        <div className={styles.checkbox_square}>
                          <img
                            src="/assets/images/icons/checkbox-input.svg"
                            alt=""
                          />
                        </div>
                      </label>
                    </li>
                    <li>
                      <label htmlFor="wasteller">
                        wasteller
                        <input type="checkbox" id="wasteller" />{" "}
                        <div className={styles.checkbox_square}>
                          <img
                            src="/assets/images/icons/checkbox-input.svg"
                            alt=""
                          />
                        </div>
                      </label>
                    </li>
                    <li>
                      <label htmlFor="soulhunter">
                        soulhunter
                        <input type="checkbox" id="soulhunter" />{" "}
                        <div className={styles.checkbox_square}>
                          <img
                            src="/assets/images/icons/checkbox-input.svg"
                            alt=""
                          />
                        </div>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={styles.tab}>
                <input type="checkbox" name="accordion-3" id="cb3" />
                <label htmlFor="cb3" className={styles.tab__label}>
                  Rarity
                  <img
                    src="/assets/images/icons/bottom-arrow-icon.svg"
                    alt=""
                  />
                </label>
                <div className={styles.tab__content}>
                  <ul>
                    <li>
                      <label htmlFor="epic">
                        epic
                        <input type="checkbox" id="epic" />{" "}
                        <div className={styles.checkbox_square}>
                          <img
                            src="/assets/images/icons/checkbox-input.svg"
                            alt=""
                          />
                        </div>
                      </label>
                    </li>
                    <li>
                      <label htmlFor="legendary">
                        legendary
                        <input type="checkbox" id="legendary" />{" "}
                        <div className={styles.checkbox_square}>
                          <img
                            src="/assets/images/icons/checkbox-input.svg"
                            alt=""
                          />
                        </div>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={styles.tab}>
                <input type="checkbox" name="accordion-4" id="cb4" />
                <label htmlFor="cb4" className={styles.tab__label}>
                  Other
                  <img
                    src="/assets/images/icons/bottom-arrow-icon.svg"
                    alt=""
                  />
                </label>
                <div className={styles.tab__content}>
                  <ul>
                    <li>
                      <label htmlFor="skin">
                        skin
                        <input type="checkbox" id="skin" />{" "}
                        <div className={styles.checkbox_square}>
                          <img
                            src="/assets/images/icons/checkbox-input.svg"
                            alt=""
                          />
                        </div>
                      </label>
                    </li>
                    <li>
                      <label htmlFor="weapon">
                        weapon
                        <input type="checkbox" id="weapon" />{" "}
                        <div className={styles.checkbox_square}>
                          <img
                            src="/assets/images/icons/checkbox-input.svg"
                            alt=""
                          />
                        </div>
                      </label>
                    </li>
                    <li>
                      <label htmlFor="relic">
                        relic
                        <input type="checkbox" id="relic" />{" "}
                        <div className={styles.checkbox_square}>
                          <img
                            src="/assets/images/icons/checkbox-input.svg"
                            alt=""
                          />
                        </div>
                      </label>
                    </li>
                    <li>
                      <label htmlFor="arkane">
                        arkane
                        <input type="checkbox" id="arkane" />{" "}
                        <div className={styles.checkbox_square}>
                          <img
                            src="/assets/images/icons/checkbox-input.svg"
                            alt=""
                          />
                        </div>
                      </label>
                    </li>
                  </ul>
                </div>
              </div> */}
              </>
            </section>
          </div>
          <div className={styles.downloads}>
            <div className={styles.title}>
              <img src="/assets/images/icons/download-icon.svg" alt="" />
              <h4>Download app</h4>
            </div>
            <a
              href="/account-settings/download"
              target="_blank"
              rel="noreferrer"
              className={`${styles["platform-button"]}`}
            >
              <img src="/assets/images/icons/windows-icon.svg" alt="" />
              <span>
                Available in the
                <br /> PC version
              </span>
            </a>

            <a
              href="https://apps.apple.com/us/app/arker-the-legend-of-ohm/id1369545258"
              target="_blank"
              rel="noreferrer"
              className={`${styles["platform-button"]}`}
            >
              <img src="/assets/images/icons/ios-icon.svg" alt="" />
              <span>
                Download from
                <br /> the APP Store
              </span>
            </a>

            <a
              href="https://play.google.com/store/apps/details?id=com.arkerlabs.arker&pli=1"
              target="_blank"
              rel="noreferrer"
              className={`${styles["platform-button"]}`}
            >
              <img src="/assets/images/icons/android-icon.svg" alt="" />
              <span>
                Available on
                <br /> Google Play
              </span>
            </a>
          </div>
        </div>
        {items.length === 0 && (
          <div className={styles["no-items-available"]}>
            <h2>No items available</h2>
          </div>
        )}
        <div className={`${styles.content}`}>
          <div className="row">
            {items?.map((item, i) => {
              return item.tokenAddress === getEggsAddress() ? (
                <MarketplaceItemEgg
                  key={i}
                  id={item.id}
                  tokenId={item.tokenId}
                  saleId={item.saleId}
                  price={item.amount}
                />
              ) : (
                <MarketplaceItemPet
                  key={i}
                  id={item.id}
                  tokenId={item.tokenId}
                  saleId={item.saleId}
                  price={item.amount}
                />
              );
            })}
          </div>
          {!allSalesItemsLoaded && items.length > 0 && (
            <button
              onClick={() => {
                fetchMore({
                  variables: {
                    ...marketplaceQueryVariables,
                    skip: items.length,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    fetchMoreResult.saleEntities.length < 6 &&
                      setAllSalesItemsLoaded(true);

                    setItems((prevArray) => [
                      ...prevArray,
                      ...fetchMoreResult.saleEntities,
                    ]);
                  },
                });
              }}
              className={`load-more-button ${styles["load-more"]}`}
            >
              Load More
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
