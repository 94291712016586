import { Link, Outlet, useLocation } from "react-router-dom";
import { MarketplaceHeader } from "../../header/marketplace-header/marketplace-header";
import { MarketplaceFooter } from "../../footer/marketplace-footer/marketplace-footer";
import styles from "./account-settings-layout.module.css";
import { useState } from "react";

export const AccountSettingsLayout = () => {
  //LOCATION VARIABLES
  const location = useLocation();

  //USESTATE VARIABLES
  const [sidebarItemSelected, setSidebarItemSelected] = useState(
    location.pathname.split("/")[2].toUpperCase().replace("-", "_")
  );

  return (
    <>
      <MarketplaceHeader />

      <div className={`${styles.content}`}>
        <div className="marketplace-container">
          <div className={styles.sidebar}>
            <h2>
              <img src="/assets/images/icons/settings-icon.svg" alt="" />{" "}
              Settings
            </h2>
            <ul>
              <Link
                to={"/account-settings/overview"}
                onClick={() => setSidebarItemSelected("OVERVIEW")}
                className={`${
                  sidebarItemSelected === "OVERVIEW" && styles.active
                }`}
              >
                <li>
                  <img
                    src="/assets/images/marketplace/icons/account.svg"
                    alt=""
                  />{" "}
                  Account Overview
                </li>
              </Link>
              <Link
                to={"/account-settings/profile-details"}
                onClick={() => setSidebarItemSelected("PROFILE_DETAILS")}
                className={`${
                  sidebarItemSelected === "PROFILE_DETAILS" && styles.active
                }`}
              >
                <li>
                  <img
                    className={styles.profile_details}
                    src="/assets/images/icons/user-icon-green.svg"
                    alt=""
                  />
                  Profile Details
                </li>
              </Link>
              <Link to={"/inventory/all"}>
                <li>
                  <img
                    className={styles.inventory}
                    src="/assets/images/icons/inventory.svg"
                    alt=""
                  />
                  Inventory
                </li>
              </Link>
              {/* <Link
                to={"/account-settings/support"}
                onClick={() => setSidebarItemSelected("SUPPORT")}
                className={`${
                  sidebarItemSelected === "SUPPORT" && styles.active
                }`}
              >
                <li>
                  <img
                    className={styles.support}
                    src="/assets/images/icons/suport-icon.svg"
                    alt=""
                  />
                  Support
                </li>
              </Link> */}
              <Link
                to={"/account-settings/security"}
                onClick={() => setSidebarItemSelected("SECURITY")}
                className={`${
                  sidebarItemSelected === "SECURITY" && styles.active
                }`}
              >
                <li>
                  {" "}
                  <img
                    className={styles.security}
                    src="/assets/images/marketplace/icons/security.svg"
                    alt=""
                  />
                  Security
                </li>
              </Link>
              <Link
                to={"/account-settings/download"}
                onClick={() => setSidebarItemSelected("DOWNLOAD")}
                className={`${
                  sidebarItemSelected === "DOWNLOAD" && styles.active
                }`}
              >
                <li>
                  {" "}
                  <img
                    className={styles.download}
                    src="/assets/images/marketplace/icons/download.svg"
                    alt=""
                  />
                  Download Files
                </li>
              </Link>
              <Link
                to={"/account-settings/activity"}
                onClick={() => setSidebarItemSelected("ACTIVITY")}
                className={`${
                  sidebarItemSelected === "ACTIVITY" && styles.active
                }`}
              >
                <li>
                  {" "}
                  <img
                    className={styles.activity}
                    src="/assets/images/marketplace/icons/activity.svg"
                    alt=""
                  />
                  Account Activity
                </li>
              </Link>
            </ul>
          </div>

          <div className={styles.outlet}>
            <Outlet />
          </div>
        </div>
      </div>
      <MarketplaceFooter />
    </>
  );
};
