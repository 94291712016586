import { useEffect, useState } from "react";
import { useGetParents } from "../../../../../hooks/eggsHooks/useGetParents";
import styles from "./parents-info.module.css";
import { parsePetDataForEggParents } from "../../../../../services/pets";

export const ParentItemInfo = ({ parentData, parent }) => {
  //USESTATE HOOKS
  const [type, setType] = useState();
  const [name, setName] = useState();
  const [skin, setSkin] = useState();

  //EGGS HOOKS
  const { petParents, petParentsLoading } = useGetParents(parentData.id);

  useEffect(() => {
    if (petParents && !petParentsLoading) {
      const petData = parsePetDataForEggParents(
        parentData.id,
        parentData,
        petParents
      );

      setType(petData.type);
      setName(petData.name);
      setSkin(petData.skin);
    }
  }, [petParents, petParentsLoading]);

  return (
    <div
      className={`${styles.parent} ${
        parent === "father" ? styles.father : styles.mother
      }`}
    >
      <div>
        <img
          src={`/assets/images/marketplace/icons/${
            parent === "father" ? "male" : "female"
          }-fheral.svg`}
          alt=""
        />
        <span>{parent}</span>
      </div>

      <div className={styles["fheral-img-container"]}>
        <img
          className={styles["fheral-img"]}
          src={`/assets/images/marketplace/pets/${type}/${skin}.gif`}
          alt=""
        />
      </div>

      <p>{name}</p>
    </div>
  );
};
