import { useQuery } from "@apollo/client";
import styles from "./marketplace-related-items.module.css";
import { useState } from "react";
import { MarketplaceItemEgg } from "../marketplace-item/marketplace-item-egg";
import { MarketplaceItemPet } from "../marketplace-item/marketplace-item-pet";
import { getEggsAddress } from "../../../services/eggs";
import { useLocation } from "react-router-dom";
import {
  RelatedItemsQuery,
  relatedItemsVariables,
} from "../../../services/graphql-queries";
import { getPetsAddress } from "../../../services/pets";

export const MarketplaceRelatedItems = () => {
  //LOCATION VARIABLES
  const { pathname } = useLocation();
  const id = pathname.split("/")[3];
  const tokenAddress =
    pathname.split("/")[2] === "pet" ? getPetsAddress() : getEggsAddress();

  //USESTATE VARIABLES
  const [items, setItems] = useState([]);

  //GRAPHQL QUERY
  const { loading } = useQuery(RelatedItemsQuery, {
    context: {
      clientName:
        process.env.REACT_APP_PRODUCTION === "1"
          ? "marketplaceEndpointProd"
          : "marketplaceEndpointTest",
    },
    variables: { ...relatedItemsVariables },
    onCompleted(queryData) {
      setItems(queryData.saleEntities);
    },
    onError(error) {
      console.log(error);
    },
  });

  if (loading) {
    return <h1>Loading…</h1>;
  }

  if (items.length > 0) {
    return (
      <div className={styles["related-items-container"]}>
        <div className={styles["related-items-header"]}>
          <span>related items</span>
        </div>
        <div className={`row ${styles.row}`}>
          {items.map((item, i) => {
            return item.tokenId.toString() !== id ||
              (item.tokenId.toString() === id &&
                item.tokenAddress !== tokenAddress) ? (
              item.tokenAddress === getEggsAddress() ? (
                <MarketplaceItemEgg
                  key={`Egg-item${i}`}
                  id={item.id}
                  tokenId={item.tokenId}
                  saleId={item.saleId}
                  price={item.amount}
                />
              ) : (
                <MarketplaceItemPet
                  key={`Pet-item-${i}`}
                  id={item.id}
                  tokenId={item.tokenId}
                  saleId={item.saleId}
                  price={item.amount}
                />
              )
            ) : null;
          })}
        </div>
      </div>
    );
  }

  return null;
};
