import { useState } from "react";
import styles from "./account-settings-security.module.css";
import { getPasswordStrength } from "../../../../services/utils";
import { changePassword } from "../../../../services/account";
import toast, { Toaster } from "react-hot-toast";

export const AccountSettingsSecurity = () => {
  //USESTATE VARIABLES
  const [showPreviuosPass, setShowPreviuosPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [oldPass, setOldPass] = useState();
  const [newPass, setNewPass] = useState();
  const [confirmPass, setConfirmPass] = useState();
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  const onChangeHandle = (e) => {
    let passwordStrength = "1%";
    const value = e.target.value;

    setNewPass(e.target.value);
    passwordStrength = getPasswordStrength(value);

    document.getElementById("power-point").style.width = passwordStrength;
  };

  const updatePassword = async () => {
    setLoading(true);
    setHasError(false);

    if (newPass.length < 6) {
      setHasError(true);
      setErrorMessage("Error: Passwords must have more than 6 digits");
      setLoading(false);
      return;
    }

    if (newPass !== confirmPass) {
      setHasError(true);
      setErrorMessage("Error: Passwords don't match");
      setLoading(false);
      return;
    }

    try {
      await changePassword(oldPass, newPass);

      toast.success("Password updated correctly");

      setOldPass("");
      setNewPass("");
      setConfirmPass("");
      setLoading(false);
    } catch (error) {
      setHasError(true);
      setErrorMessage(error.toString());
      setLoading(false);
    }
  };

  return (
    <>
      <Toaster />
      <div className={styles["security-container"]}>
        <h1>Security</h1>
        <h4>Password</h4>
        <p>
          We recommend that you update your password periodically to prevent
          unauthorized access.
        </p>

        <div className={styles["input-container"]}>
          <label htmlFor="previous-pass">Previous password</label>
          <input
            type={showPreviuosPass ? "text" : "password"}
            placeholder="Enter the previous password"
            onChange={(e) => setOldPass(e.target.value)}
            value={oldPass}
          />
          <button
            className={`${styles["show-pass"]} ${
              showPreviuosPass && styles["hide-pass"]
            }`}
            onClick={() => setShowPreviuosPass(!showPreviuosPass)}
          />
        </div>
        <div className={styles["input-container"]}>
          <label htmlFor="previous-pass">New password</label>
          <input
            type={showNewPass ? "text" : "password"}
            placeholder="Enter the new password"
            onChange={onChangeHandle}
            value={newPass}
          />
          <button
            className={`${styles["show-pass"]} ${
              showNewPass && styles["hide-pass"]
            }`}
            onClick={() => setShowNewPass(!showNewPass)}
          />
        </div>

        <div className={styles["password-strength-container"]}>
          <div className={styles["password-strength"]}>
            <div id="power-point" className={styles["power-point"]} />
          </div>
          <span>Password strength</span>
        </div>

        <div className={styles["input-container"]}>
          <label htmlFor="previous-pass">Confirm password</label>
          <input
            type={showConfirmPass ? "text" : "password"}
            placeholder="Enter the new password again."
            onChange={(e) => setConfirmPass(e.target.value)}
            className={hasError && styles["error-input"]}
            value={confirmPass}
          />
          <button
            className={`${styles["show-pass"]} ${
              showConfirmPass && styles["hide-pass"]
            }`}
            onClick={() => setShowConfirmPass(!showConfirmPass)}
          />
        </div>

        {hasError && (
          <div className={styles["error-container"]}>
            <span>{errorMessage}</span>
          </div>
        )}

        <div className={styles["buttons-container"]}>
          <button
            className={`see-detail-button`}
            onClick={() =>
              !loading && oldPass && newPass && confirmPass && updatePassword()
            }
          >
            {loading ? "Loading…" : "Save"}
          </button>
        </div>
      </div>
    </>
  );
};
