import { useEffect, useState } from "react";
import styles from "./inventory-section.module.css";
import { getPetsAddress } from "../../../../../services/pets";
import { getEggsAddress } from "../../../../../services/eggs";
import { useTokensOfOwner } from "../../../../../hooks/useTokensOfOwner";
import { InventoryItemEgg, InventoryItemPet } from "./inventory-item";

const EGGS_ADDRESS = getEggsAddress();
const PETS_ADDRESS = getPetsAddress();

export const InventorySection = ({ type }) => {
  //USESTATE VARIABLES
  const [title, setTitle] = useState();
  const [pathname, setPathname] = useState();

  //USER HOOKS
  const { tokensOfOwner, tokensOfOwnerLoading } = useTokensOfOwner(
    type === "EGGS" ? EGGS_ADDRESS : PETS_ADDRESS
  );

  useEffect(() => {
    switch (type) {
      case "EGGS":
        setTitle("My Eggs");
        setPathname("/inventory/fheral/eggs");
        break;

      default:
        setTitle("My Pets");
        setPathname("/inventory/fheral/pets");
        break;
    }
  }, [type]);

  return (
    <div className={styles["inventory-section"]}>
      <div className={styles["inventory-section-header"]}>
        <p className={styles["inventory-section-title"]}>{title}</p>
        <a href={pathname}>See all</a>
      </div>
      <div className={styles["inventory-content"]}>
        {tokensOfOwnerLoading ? (
          <h2>Loading…</h2>
        ) : tokensOfOwner?.length === 0 || tokensOfOwner === undefined ? (
          <h2>Your {type.toLowerCase()} inventory is empty</h2>
        ) : (
          tokensOfOwner?.slice(0, 3).map((item) => {
            return type === "EGGS" ? (
              <InventoryItemEgg tokenId={parseInt(item)} />
            ) : (
              <InventoryItemPet tokenId={parseInt(item)} />
            );
          })
        )}
      </div>
    </div>
  );
};
