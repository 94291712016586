import { useAccount, useReadContract } from "wagmi";
import petsABI from "../../assets/abis/pets.json";
import { getChainId, getMarketAddress } from "../../services/utils";
import { getPetsAddress } from "../../services/pets";

export const useApprovedForAll = () => {
  const { address } = useAccount();

  const { data: isApprovedForAll, refetch: refetchApprovedForAll } =
    useReadContract({
      abi: petsABI,
      functionName: "isApprovedForAll",
      address: getPetsAddress(),
      chainId: getChainId(),
      args: [address, getMarketAddress()],
    });

  return { isApprovedForAll, refetchApprovedForAll };
};
