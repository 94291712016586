import styles from "./inventory-section.module.css";
import { useParsePetData } from "../../../../../hooks/petsHooks/useParsePetData";
import { useParseEggData } from "../../../../../hooks/eggsHooks/useParseEggData";
import { parseSkinNumToImage } from "../../../../../services/utils";
import { useGetSaleIdByToken } from "../../../../../hooks/marketHooks/useGetSaleIdByToken";
import { getPetsAddress } from "../../../../../services/pets";
import { getEggsAddress } from "../../../../../services/eggs";

export const InventoryItemEgg = ({ tokenId }) => {
  //MARKET HOOKS
  const { saleId: tokenSaleId, saleIdLoading } = useGetSaleIdByToken(
    tokenId,
    getEggsAddress()
  );

  //PETS HOOKS
  const { eggData, eggDataLoading } = useParseEggData(tokenId, tokenSaleId);

  if (eggDataLoading || saleIdLoading) {
    return <h2>Loading…</h2>;
  }

  return (
    <div className={styles["inventory-item"]}>
      <div className={styles["img-container-egg"]}>
        {eggData.onSale && !eggData.isBanned && (
          <img
            className={styles["for-sale"]}
            src={`/assets/images/marketplace/icons/forsale.png`}
            alt=""
          />
        )}
        <img
          src={`/assets/images/marketplace/eggs/${eggData.type}.png`}
          alt=""
        />
      </div>

      <span>{eggData.name}</span>
    </div>
  );
};

export const InventoryItemPet = ({ tokenId }) => {
  //MARKET HOOKS
  const { saleId: tokenSaleId, saleIdLoading } = useGetSaleIdByToken(
    tokenId,
    getPetsAddress()
  );

  //PETS HOOKS
  const { petData, petDataLoading } = useParsePetData(tokenId, tokenSaleId);

  if (petDataLoading || saleIdLoading) {
    return <h2>Loading…</h2>;
  }

  return (
    <div className={styles["inventory-item"]}>
      <div className={styles["img-container-pet"]}>
        {petData.onSale && !petData.isBanned && (
          <img
            className={styles["for-sale"]}
            src={`/assets/images/marketplace/icons/forsale.png`}
            alt=""
          />
        )}

        {petData.isBanned && (
          <img
            className={styles["banned"]}
            src={`/assets/images/marketplace/icons/isbanned.png`}
            alt=""
          />
        )}
        <img
          src={`/assets/images/marketplace/pets/${
            petData.type
          }/${parseSkinNumToImage(petData.skin)}.gif`}
          alt=""
        />
      </div>

      <span>{petData.name}</span>
    </div>
  );
};
