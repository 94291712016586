import { useReadContract } from "wagmi";
import { getChainId, getMarketAddress } from "../../services/utils";
import marketABI from "../../assets/abis/marketplace.json";

export const useGetSaleIdByToken = (tokenId, nftAddress) => {
  const { data: saleId, isLoading: saleIdLoading } = useReadContract({
    abi: marketABI,
    functionName: "_lastSaleByToken",
    address: getMarketAddress(),
    chainId: getChainId(),
    args: [nftAddress, tokenId],
  });

  return {
    saleId,
    saleIdLoading,
  };
};
