import { gql } from "@apollo/client";

const addressConnected = localStorage.getItem("address.connected");

//MARKETPLACE QUERY
export const num_of_items = 25;
export const marketplaceQueryVariables = {
  skip: 0,
  first: num_of_items,
  orderBy: "created",
  orderDirection: "desc",
  actualTimestamp: (Date.now() / 1000).toFixed(0),
};

export const MarketplaceQuery = gql`
  query getSalesEntities(
    $actualTimestamp: String
    $first: Int
    $skip: Int
    $orderBy: String
    $orderDirection: String
  ) {
    saleEntities(
      first: $first
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: { buyed: false, expire_gt: $actualTimestamp }
    ) {
      amount
      buyed
      created
      expire
      id
      tokenAddress
      saleId
      tokenId
      wallet
    }
  }
`;

export const MarketplaceQueryWithAddress = gql`
  query getSalesEntities(
    $actualTimestamp: String
    $first: Int
    $skip: Int
    $orderBy: String
    $orderDirection: String
    $tokenAddress: String
  ) {
    saleEntities(
      first: $first
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: {
        buyed: false
        expire_gt: $actualTimestamp
        tokenAddress: $tokenAddress
      }
    ) {
      amount
      buyed
      created
      expire
      id
      tokenAddress
      saleId
      tokenId
      wallet
    }
  }
`;

//RELATED ITEMS QUERY
export const relatedItemsVariables = {
  first: 4,
  skip: 0,
  orderBy: "created",
  orderDirection: "desc",
  actualTimestamp: (Date.now() / 1000).toFixed(0),
};

export const RelatedItemsQuery = gql`
  query getSalesEntities(
    $first: Int
    $skip: Int
    $orderBy: String
    $orderDirection: String
    $actualTimestamp: String
  ) {
    saleEntities(
      first: $first
      skip: $skip
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: { buyed: false, expire_gt: $actualTimestamp }
    ) {
      amount
      buyed
      created
      expire
      id
      tokenAddress
      saleId
      tokenId
      wallet
    }
  }
`;

//WALLET RECENT ACTIVITY QUERY
export const recentActivityQueryVariables = {
  address: addressConnected,
  orderBy: "created",
  orderDirection: "desc",
};
export const recentActivityQuery = gql`
  query getSalesEntities(
    $orderBy: String
    $orderDirection: String
    $address: String
    $first: Int
  ) {
    saleEntities(
      orderBy: $orderBy
      orderDirection: $orderDirection
      first: $first
      where: { wallet: $address }
    ) {
      amount
      buyed
      created
      saleId
      expire
    }
  }
`;

//ITEM HISTORY QUERY
export const historyQuery = gql`
  query getHistory($tokenAddress: String, $tokenId: Int) {
    saleEntities(
      where: { buyed: true, tokenAddress: $tokenAddress, tokenId: $tokenId }
    ) {
      id
      saleId
      tokenId
      tokenAddress
      wallet
      amount
      created
      expire
      buyed
    }
  }
`;

//SALE ITEM QUERY
export const SaleItemQuery = gql`
  query getSaleEntity($saleId: Int) {
    saleEntities(where: { saleId: $saleId }) {
      amount
      expire
      wallet
      saleId
    }
  }
`;
