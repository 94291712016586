import { getApiURL } from "./utils";

export const login = async (email, password) => {
  if (!email || !password) {
    return;
  }

  const LOGIN_ENDPOINT = `${getApiURL()}/users/login`;

  const res = await fetch(LOGIN_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });
  const data = await res.json();
  if (res.ok) {
    localStorage.setItem(
      "arker_auth",
      JSON.stringify({ 1: data.access_token })
    );
    localStorage.setItem("user.email", email);
  } else {
    if (res.status === 400) throw new Error("Invalid email/password");
    if (res.status === 404) throw new Error("404, Not found");
    if (res.status === 500) throw new Error("500, internal server error");
    throw new Error(`${data.statusCode} ${data.error} - ${data.message}`);
  }
};

export const register = async (email, password, username) => {
  if (!email || !password || !username) {
    return;
  }

  const REGISTER_ENDPOINT = `${getApiURL()}/users`;

  const res = await fetch(REGISTER_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password, username }),
  });
  const data = await res.json();
  if (res.ok) {
    localStorage.setItem(
      "arker_auth",
      JSON.stringify({ 1: data.access_token })
    );
    localStorage.setItem("user.email", email);
    window.location.replace("/marketplace");
  } else {
    if (res.status === 404) throw new Error("404, Not found");
    if (res.status === 500) throw new Error("500, internal server error");
    throw new Error(`${data.statusCode} ${data.error} - ${data.message}`);
  }
};

export const logout = () => {
  localStorage.removeItem("arker_auth");
  localStorage.removeItem("user.email");
  window.location.replace("/login");
};

export const getProfileData = async () => {
  const PROFILE_DATA = `${getApiURL()}/users`;

  const res = await fetch(PROFILE_DATA, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("arker_auth"))[1]
      }`,
    },
  });
  const data = await res.json();
  if (res.ok) {
    return data;
  } else {
    if (res.status === 404) throw new Error("404, Not found");
    if (res.status === 500) throw new Error("500, internal server error");
    throw new Error(`${data.statusCode} ${data.error} - ${data.message}`);
  }
};

export const linkExternalWallet = async (signature) => {
  const EXTERNAL_WALLET_ENDPOINT = `${getApiURL()}/users/external-wallet`;
  const res = await fetch(EXTERNAL_WALLET_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("arker_auth"))[1]
      }`,
    },
    body: JSON.stringify({ signature }),
  });
  if (!res.ok) {
    const data = await res.json();
    if (res.status === 404) throw new Error("404, Not found");
    if (res.status === 500) throw new Error("500, internal server error");
    throw new Error(`${data.statusCode} ${data.error} - ${data.message}`);
  }
};

export const validateEmail = async (code) => {
  const VALIDATE_EMAIL_ENDPOINT = `${getApiURL()}/users/validate`;

  const res = await fetch(VALIDATE_EMAIL_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("arker_auth"))[1]
      }`,
    },
    body: JSON.stringify({ code }),
  });

  if (!res.ok) {
    const data = await res.json();
    if (res.status === 404) throw new Error("404, Not found");
    if (res.status === 500) throw new Error("500, internal server error");
    if (res.status === 403)
      throw new Error(
        "Code received is invalid. The link that you has used is expired or invalid.\nIf you want try again, must request it again fill the 'Forgot password?' again."
      );
    throw new Error(`${data.statusCode} ${data.error} - ${data.message}`);
  }
};

export const recoverPassword = async (email) => {
  const RECOVER_PASS_ENDPOINT = `${getApiURL()}/users/recover`;
  const res = await fetch(RECOVER_PASS_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  });

  if (!res.ok) {
    const data = await res.json();
    if (res.status === 404) throw new Error("404, Not found");
    if (res.status === 500) throw new Error("500, internal server error");
    throw new Error(`${data.statusCode} ${data.error} - ${data.message}`);
  }
};

export const resetPassword = async (password, code) => {
  const RESET_PASS_ENDPOINT = `${getApiURL()}/users/recover/new`;

  const res = await fetch(RESET_PASS_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ code, password }),
  });

  if (!res.ok) {
    const data = await res.json();
    if (res.status === 404) throw new Error("404, Not found");
    if (res.status === 500) throw new Error("500, internal server error");
    throw new Error(`${data.statusCode} ${data.error} - ${data.message}`);
  }
};

export const getChangeUsernamePrice = async () => {
  const GET_CHANGE_USERNAME_PRICE_ENDPOINT = `${getApiURL()}/users/get-price-to-change-username`;
  const res = await fetch(GET_CHANGE_USERNAME_PRICE_ENDPOINT, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("arker_auth"))[1]
      }`,
    },
  });

  if (!res.ok) {
    const data = await res.json();
    if (res.status === 404) throw new Error("404, Not found");
    if (res.status === 500) throw new Error("500, internal server error");
    throw new Error(`${data.statusCode} ${data.error} - ${data.message}`);
  }

  const price = await res.json();

  return price;
};

export const changeUsername = async (newUsername) => {
  const CHANGE_USERNAME_ENDPOINT = `${getApiURL()}/users/change-username`;

  const res = await fetch(CHANGE_USERNAME_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("arker_auth"))[1]
      }`,
    },
    body: JSON.stringify({ newUsername }),
  });

  if (!res.ok) {
    const data = await res.json();
    if (res.status === 404) throw new Error("404, Not found");
    if (res.status === 500) throw new Error("500, internal server error");
    throw new Error(`${data.statusCode} ${data.error} - ${data.message}`);
  }
};

export const changePassword = async (oldPassword, newPassword) => {
  const CHANGE_PASSWORD_ENDPOINT = `${getApiURL()}/users/change-password`;

  const res = await fetch(CHANGE_PASSWORD_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("arker_auth"))[1]
      }`,
    },
    body: JSON.stringify({ oldPassword, newPassword }),
  });

  if (!res.ok) {
    const data = await res.json();
    if (res.status === 404) throw new Error("404, Not found");
    if (res.status === 500) throw new Error("500, internal server error");
    throw new Error(`${data.statusCode} ${data.error} - ${data.message}`);
  }
};

export const linkAccountWithWombat = async (uid) => {
  const LINK_ACCOUNT_WOMBAT_ENDPOINT = `${getApiURL()}/wombat/save-wombat-user-info`;

  const res = await fetch(LINK_ACCOUNT_WOMBAT_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem("arker_auth"))[1]
      }`,
    },
    body: JSON.stringify({ wombatId: uid }),
  });

  if (!res.ok) {
    const data = await res.json();
    if (res.status === 404) throw new Error("404, Not found");
    if (res.status === 500) throw new Error("500, internal server error");
    throw new Error(`${data.statusCode} ${data.error} - ${data.message}`);
  }
};
