import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";

const apolloCache = new InMemoryCache();
const marketplaceEndpointTest = new HttpLink({
  uri: process.env.REACT_APP_SUBGRAPH_MARKETPLACE_URI_TEST,
});
const marketplaceEndpointProd = new HttpLink({
  uri: process.env.REACT_APP_SUBGRAPH_MARKETPLACE_URI_PROD,
});

export const graphqlClient = new ApolloClient({
  cache: apolloCache,
  link: ApolloLink.split(
    (operation) =>
      operation.getContext().clientName === "marketplaceEndpointProd",
    marketplaceEndpointProd,
    marketplaceEndpointTest
  ),
});
