import { Outlet } from "react-router-dom";
import { MarketplaceHeader } from "../header/marketplace-header/marketplace-header";
import { MarketplaceFooter } from "../footer/marketplace-footer/marketplace-footer";
import { useEffect } from "react";

export const AppLayout = () => {
  useEffect(() => {
    const getItems = async () => {
      const response = await fetch("https://test-api.playarker.com/items");
      const data = await response.json();

      localStorage.setItem("ARKER_ITEMS", JSON.stringify(data));
    };

    const getAbilities = async () => {
      const response = await fetch("https://test-api.playarker.com/abilities");
      const data = await response.json();

      localStorage.setItem("ARKER_ABILITIES", JSON.stringify(data));
    };

    const getClasses = async () => {
      const response = await fetch("https://test-api.playarker.com/classes");
      const data = await response.json();

      localStorage.setItem("ARKER_CLASSES", JSON.stringify(data));
    };

    getItems();
    getAbilities();
    getClasses();
  }, []);

  return (
    <>
      <MarketplaceHeader />
      <Outlet test={"test"} />
      <MarketplaceFooter />
    </>
  );
};
