import { MainSection } from "./main-section/main-section";
import { FeaturesSection } from "./features-section/features-section";
import styles from "./home.module.css";
import { SkillsSection } from "./skills-section/skills-section";
import { BlogSection } from "./blog-section/blog-section";
import { PartnersSection } from "./partners-section/partners-section";
import { SlidersSection } from "./sliders-section/sliders-section";
import { ChestsPetsSection } from "./chests-pets-section/chests-pets-section";

export const Home = () => {
  return (
    <main className={styles["home-container"]}>
      <MainSection />
      <FeaturesSection />
      <SkillsSection />
      <ChestsPetsSection />
      <SlidersSection />
      <BlogSection />
      <PartnersSection />
    </main>
  );
};
