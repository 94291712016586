import styles from "./marketplace-item.module.css";

export const MarketplaceItemSkeleton = ({
  id,
  inventory = false,
  wishlist = false,
}) => {
  return (
    <div
      id={`marketplace-item${id}`}
      className={`${styles["marketplace-item"]} ${
        inventory && styles["inventory-item"]
      }`}
    >
      {(!inventory || wishlist) && (
        <div
          className={`${styles["wishlist-skeleton"]} ${styles.skeleton} ${styles.wishlist}`}
        />
      )}
      <div
      /* className={
              styles[
                `image-container-${
                  item.archetype === "common" && item.type === "fheral"
                    ? item.type
                    : item.archetype
                }`
              ]
            } */
      >
        {/* <img className={styles.img} src={item.image} alt="" /> */}
      </div>
      <div
        className={`${styles["skeleton"]} ${styles["item-name-skeleton"]}`}
      />

      <div className={`${styles["item-characteristics-skeleton-container"]}`}>
        <div
          className={`${styles["item-characteristic-skeleton"]} ${styles.skeleton}`}
        />
        <div
          className={`${styles["item-characteristic-skeleton"]} ${styles.skeleton}`}
        />
        <div
          className={`${styles["item-characteristic-skeleton"]} ${styles.skeleton}`}
        />
      </div>

      {!inventory && (
        <div
          className={`${styles.price} ${styles.skeleton} ${styles["item-price-skeleton"]}`}
        />
      )}

      <div className={`${styles.skeleton} ${styles["item-button-skeleton"]}`} />
    </div>
  );
};
