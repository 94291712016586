import { useEffect, useState } from "react";
import { useGetSale } from "./useGetSale";
import { formatEther } from "viem";
import { useIsSaleAvailable } from "./useIsSaleAvailable";

export const useGetOnSale = (saleId) => {
  /* const [tokenId, setTokenId] = useState();
  const [tokenAddress, setTokenAddress] = useState();
  const [wallet, setWallet] = useState();
  const [amount, setAmount] = useState();
  const [humanAmount, setHumanAmount] = useState();
  const [created, setCreated] = useState();
  const [expire, setExpire] = useState();
  const [buyed, setBuyed] = useState(); */

  const [onSale, setOnSale] = useState();

  const { sale, saleLoading } = useGetSale(saleId);
  const { isSaleAvailable, isSaleAvailableLoading } =
    useIsSaleAvailable(saleId);

  useEffect(() => {
    if (sale && !saleLoading && !isSaleAvailableLoading && isSaleAvailable) {
      /* setTokenId(sale.tokenId);
      setTokenAddress(sale.tokenAddress);
      setWallet(sale.owner);
      setAmount(sale.price);
      setHumanAmount(parseFloat(formatEther(sale.price ?? 0)));
      setCreated(null);
      setExpire(sale.endDate);
      setBuyed(sale.buyed); */
      setOnSale(true);
    }
  }, [sale, saleLoading, isSaleAvailable, isSaleAvailableLoading]);

  return { onSale, onSaleLoading: saleLoading };

  /* return {
    saleData: {
      id: saleId,
      saleId,
      tokenId,
      tokenAddress,
      wallet,
      amount,
      humanAmount,
      created,
      expire,
      buyed,
    },
    saleDataLoading: saleLoading,
  }; */
};
