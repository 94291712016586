import { useReadContract } from "wagmi";
import eggsABI from "../../assets/abis/breeder.json";
import { getEggsAddress } from "../../services/eggs";
import { getChainId } from "../../services/utils";

export const useMaxBreeds = () => {
  const { data: maxBreeds, isLoading: maxBreedsLoading } = useReadContract({
    abi: eggsABI,
    functionName: "_maxBreeds",
    address: getEggsAddress(),
    chainId: getChainId(),
  });

  return { maxBreeds, maxBreedsLoading };
};
