import { useEffect, useState } from "react";
import { ParentItemInfo } from "./parent-item-info";
import styles from "./parents-info.module.css";
import { getBreedCooldownForPet } from "../../../../../services/eggs";

export const ParentsInfo = ({
  parents,
  breedCount,
  maxBreeds,
  type,
  petId,
}) => {
  const [breedCooldown, setBreedCooldown] = useState();

  useEffect(() => {
    getBreedCooldownForPet(petId).then((data) => {
      setBreedCooldown(data ? data.toLocaleString() : null);
    });
  }, [petId]);

  return (
    <div className={styles["parents-info"]}>
      <div className={styles["parents-info-header"]}>
        <p>parents info</p>
      </div>

      <div className={styles["parents-container"]}>
        {/* FATHER */}
        <ParentItemInfo parentId={parents[0]} parent="father" type={type} />

        {/* MOTHER */}
        <ParentItemInfo parentId={parents[1]} parent="mother" type={type} />
      </div>

      <div className={styles["breed-info"]}>
        <div className={styles["breed-info-header"]}>
          <p>Breed info</p>
        </div>

        <span className={!breedCooldown ? styles["no-cooldown"] : ""}>
          Current breeds <strong>{`${breedCount}/${maxBreeds}`}</strong>
        </span>

        {breedCount < maxBreeds && breedCooldown && (
          <span className={styles["time-to-breed"]}>
            This pet can breed again at: <strong>{breedCooldown}</strong>
          </span>
        )}
      </div>
    </div>
  );
};
