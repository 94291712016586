import { useWriteContract } from "wagmi";

export const useBuy = () => {
  const {
    writeContractAsync: buyItemWrite,
    error: buyItemError,
    isError: isBuyItemError,
  } = useWriteContract();

  return { buyItemWrite, buyItemError, isBuyItemError };
};
