export const getMediumPosts = async () => {
  const rss2jsonURL = `https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2F%40arker-official%2Ffeed&api_key=${process.env.REACT_APP_RSSTOJSON_API_KEY}`;
  try {
    const response = await fetch(rss2jsonURL);
    const data = await response.json();

    return data.items.slice(0, 3);
  } catch (error) {
    return "ERROR";
  }
};
