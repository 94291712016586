import { useQuery } from "@apollo/client";
import { AccountActivityTable } from "../../../account-activity-table/account-activity-table";
import styles from "./account-settings-activity.module.css";
import {
  recentActivityQuery,
  recentActivityQueryVariables,
} from "../../../../services/graphql-queries";
import { useState } from "react";

export const AccountSettingsActivity = () => {
  //USESTATE VARIABLES
  const [recentActivity, setRecentActivity] = useState();

  //GRAPHQL QUERY
  const { recentActivityQueryLoading } = useQuery(recentActivityQuery, {
    context: {
      clientName:
        process.env.REACT_APP_PRODUCTION === "1"
          ? "marketplaceEndpointProd"
          : "marketplaceEndpointTest",
    },
    variables: { ...recentActivityQueryVariables },
    onCompleted(queryData) {
      setRecentActivity(queryData.saleEntities);
    },
    onError(error) {
      console.log(error);
    },
  });

  return (
    <div className={styles["activity-container"]}>
      <h1>Account Activity</h1>
      <h4>Transaction history</h4>

      {recentActivityQueryLoading ? (
        <h2 className="text-center mt-3">Loading…</h2>
      ) : (
        <AccountActivityTable activity={recentActivity} />
      )}
    </div>
  );
};
