import { formatUnits } from "viem";
import { useGetArkerBalance } from "../../../../hooks/useGetArkerBalance";
import { useUserData } from "../../../../hooks/useUserData";
import {
  getArkerBalanceInUSD,
  getArkerPriceFromKucoin,
  truncateETHAddress,
} from "../../../../services/utils";
import styles from "./account-settings-overview.module.css";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useQuery } from "@apollo/client";
import moment from "moment/moment";
import { InventoryContainer } from "./inventory-section/inventory-container";
import {
  recentActivityQuery,
  recentActivityQueryVariables,
} from "../../../../services/graphql-queries";
import { AccountActivityTable } from "../../../account-activity-table/account-activity-table";

export const AccountSettingsOverview = () => {
  //LOCALSTORAGE
  const EMAIL_REGISTERED = localStorage.getItem("user.email");

  //USER HOOKS
  const { userData, userDataLoading } = useUserData();
  const { arkerBalance, arkerBalanceLoading } = useGetArkerBalance();

  //USESTATE VARIABLES
  const [arkerPrice, setArkerPrice] = useState();
  const [recentActivity, setRecentActivity] = useState();

  //GRAPHQL QUERY
  const { recentActivityQueryLoading } = useQuery(recentActivityQuery, {
    context: {
      clientName:
        process.env.REACT_APP_PRODUCTION === "1"
          ? "marketplaceEndpointProd"
          : "marketplaceEndpointTest",
    },
    variables: { ...recentActivityQueryVariables, first: 6 },
    onCompleted(queryData) {
      setRecentActivity(queryData.saleEntities);
    },
    onError(error) {
      console.log(error);
    },
  });

  useEffect(() => {
    const getArkerPrice = async () => {
      setArkerPrice(await getArkerPriceFromKucoin());
    };

    try {
      getArkerPrice();
    } catch (error) {
      console.log(error);
    }
  }, []);

  if (userDataLoading) {
    return (
      <div className={styles.loading}>
        <h1 className="text-white text-center">Loading…</h1>
      </div>
    );
  }

  return (
    <div className={styles.overview}>
      <Toaster />
      <div className={styles["overview-content"]}>
        <div className={styles["account-overview"]}>
          <a href="/account-settings/profile-details">
            <button className={styles.edit}>Edit</button>
          </a>
          <h4>Account overview</h4>
          <p className={styles.joined}>
            Joined {moment(userData?.created).format("MMM DD, YYYY")}{" "}
          </p>

          <div className={styles["user-info"]}>
            <div className={styles["user-icon-container"]}>
              <img src="/assets/images/icons/user-icon-green.svg" alt="" />
            </div>
            <div className={styles["user-info-content"]}>
              <p>{userData.username}</p>
              <span>{EMAIL_REGISTERED ?? "Darkblade@gmail.com"}</span>
            </div>
          </div>
          <div
            className={styles.address}
            onClick={() => {
              if (userData?.externalWallet) {
                navigator.clipboard.writeText(userData?.externalWallet);
                toast.success("Address copied to clipboard");
              }
            }}
          >
            {userData?.externalWallet && (
              <img
                src="/assets/images/marketplace/icons/copy-paste.svg"
                alt=""
              />
            )}

            <span>
              {userData?.externalWallet ? (
                truncateETHAddress(userData?.externalWallet)
              ) : (
                <a href="/account-settings/profile-details">No paired wallet</a>
              )}
            </span>
          </div>
        </div>
        <div className={styles.wallet}>
          <button className={`simple-button ${styles["buy-arker"]}`}>
            <a
              href="https://www.kucoin.com/trade/ARKER-USDT"
              target="_blank"
              rel="noreferrer"
            >
              Buy arker
            </a>
          </button>
          <h4>Wallet</h4>

          <div className={styles.balance}>
            <img src="/assets/images/icons/arker-icon.svg" alt="" />
            <span className={styles["arker-qty"]}>
              {arkerBalanceLoading
                ? "Loading…"
                : `${
                    parseFloat(
                      formatUnits(arkerBalance.value, arkerBalance.decimals)
                    ).toFixed(2) ?? "0"
                  } ARKER`}
            </span>
            <span className={styles["arker-price"]}>
              ${getArkerBalanceInUSD(arkerBalance, arkerPrice) ?? "Loading…"}
            </span>
          </div>

          <div className={styles["table-title"]}>
            <span>Recent activity</span>

            <a
              className={styles["see-all-button"]}
              href="/account-settings/activity"
            >
              See all
            </a>
          </div>

          {recentActivityQueryLoading ? (
            <h2>Loading…</h2>
          ) : (
            <AccountActivityTable activity={recentActivity} />
          )}
        </div>
      </div>

      <InventoryContainer />
    </div>
  );
};
