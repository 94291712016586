import styles from "../items-slider.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PrevArrow } from "../../../../slick-slider-arrows/prev-arrow";
import { NextArrow } from "../../../../slick-slider-arrows/next-arrow";

export const RelicsSlider = () => {
  const slickSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <NextArrow itemsSlider={true} />,
    prevArrow: <PrevArrow itemsSlider={true} />,
    responsive: [
      { breakpoint: 1520, settings: { slidesToShow: 4 } },
      { breakpoint: 1090, settings: { slidesToShow: 3 } },
      { breakpoint: 835, settings: { slidesToShow: 2 } },
      { breakpoint: 605, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <Slider {...slickSliderSettings}>
      <div className={styles["slick-slider-item"]}>
        <img src="/assets/images/items/relics/ar-relic-01.png" alt="" />
        <div className={styles["slick-slider-item-content"]}>
          <p>Vestalis Blindkrone</p>
        </div>
      </div>

      <div className={styles["slick-slider-item"]}>
        <img src="/assets/images/items/relics/bz-relic-01.png" alt="" />
        <div className={styles["slick-slider-item-content"]}>
          <p>Bloodenbort Pendant</p>
        </div>
      </div>

      <div className={styles["slick-slider-item"]}>
        <img src="/assets/images/items/relics/com-relic-01.png" alt="" />
        <div className={styles["slick-slider-item-content"]}>
          <p>OHM's Fury Ring</p>
        </div>
      </div>

      <div className={styles["slick-slider-item"]}>
        <img src="/assets/images/items/relics/iz-relic-01.png" alt="" />
        <div className={styles["slick-slider-item-content"]}>
          <p>KHARAXES IZHARMASK</p>
        </div>
      </div>

      <div className={styles["slick-slider-item"]}>
        <img src="/assets/images/items/relics/sh-relic-01.png" alt="" />
        <div className={styles["slick-slider-item-content"]}>
          <p>ANEMY HANDS "SHARPULSE" SERIES</p>
        </div>
      </div>

      {/*  <div className={styles["slick-slider-item"]}>
        <img
          src="/assets/images/items/relics/was-relic-01.png"
          alt=""
        />
        <div className={styles["slick-slider-item-content"]}>
          <p>"Scary" Joe Fearmaker Skinface</p>
        </div>
      </div> */}
    </Slider>
  );
};
