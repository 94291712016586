import { useParsePetData } from "../../../../../hooks/petsHooks/useParsePetData";
import { parseSkinNumToImage } from "../../../../../services/utils";
import styles from "./parents-info.module.css";

export const ParentItemInfo = ({ parentId, parent, type }) => {
  //EGGS HOOKS
  const { petData, petDataLoading } = useParsePetData(parentId);

  if (petDataLoading) {
    return (
      <div className={`${styles.parent} ${styles.loading}`}>
        <img
          width={35}
          alt="Loading pet item"
          src="/assets/images/icons/loading.svg"
        />
      </div>
    );
  }

  return (
    <div
      className={`${styles.parent} ${
        parent === "father" ? styles.father : styles.mother
      }`}
    >
      <div>
        <img
          src={`/assets/images/marketplace/icons/${
            parent === "father" ? "male" : "female"
          }-fheral.svg`}
          alt=""
        />
        <span>{parent}</span>
      </div>

      <div className={styles["fheral-img-container"]}>
        {parentId.toString() === "0" ? (
          <img
            className={styles["fheral-img"]}
            src={`/assets/images/marketplace/pets/${type}/1.gif`}
            alt=""
          />
        ) : (
          <img
            className={styles["fheral-img"]}
            src={`/assets/images/marketplace/pets/${
              petData.type
            }/${parseSkinNumToImage(petData.skin)}.gif`}
            alt=""
          />
        )}
      </div>

      <p>
        {parentId.toString() === "0"
          ? `${type} Genesis #${parentId}`
          : petData.name}
      </p>
    </div>
  );
};
