import styles from "./password-strength.module.css";
export const PasswordStrength = () => {
  return (
    <div className={styles["password-strength-container"]}>
      <div className={styles["password-strength"]}>
        <div id="power-point" className={styles["power-point"]} />
      </div>
      <span>Password strength</span>
    </div>
  );
};
