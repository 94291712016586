import { useReadContract } from "wagmi";
import eggsABI from "../../assets/abis/breeder.json";
import { getEggsAddress } from "../../services/eggs";
import { getChainId } from "../../services/utils";

export const useGetParents = (petId) => {
  const { data: petParents, isLoading: petParentsLoading } = useReadContract({
    abi: eggsABI,
    functionName: "getParents",
    address: getEggsAddress(),
    chainId: getChainId(),
    args: [petId],
  });

  return { petParents, petParentsLoading };
};
