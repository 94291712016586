import { CheckItem } from "../check-item/check-item";
import styles from "./chests-pets-section.module.css";

export const ChestsPetsSection = () => {
  return (
    <div className={styles["chests-pets-section"]}>
      <div className={`${styles["chests-pets-section-content"]}`}>
        <h3>TRADE OR COLLECT YOUR ITEMS</h3>
        <img
          className="divider-s"
          src="/assets/images/elements/home-divider.svg"
          alt=""
        />
        <p>
          Equip your hero with the best abilities, weapons and relics. Tired of
          one of your relics? Or simply looking to upgrade your collection? Take
          a look at our marketplace and trade directly with other heroes.
        </p>

        <div className={styles["bg-img"]}>
          <div className={`row ${styles.chests}`}>
            <div
              className={`col-lg-7 col-sm-12 ${styles["market-img-container"]}`}
            >
              <img
                className={styles["market-img"]}
                src="/assets/images/img-market.png"
                alt=""
              />
            </div>
            <div className={`col-lg-5 col-sm-12 ${styles["chests-content"]}`}>
              <h3>Arkhas (CHESTS)</h3>
              <p>
                Feeling lucky? Get a few Arkhas and open them. Maybe you find
                something useful for your hero or something to trade in the
                marketplace.
              </p>
              <img
                className={styles["arkha-box"]}
                src="/assets/images/arkha-box.png"
                alt=""
              />
            </div>
          </div>
          <div className={`row ${styles.pets}`}>
            <div className={`col-lg-5 col-sm-12 ${styles["pets-content"]}`}>
              <h3>Fherals (Pets)</h3>
              <p>
                Looking for a lifelong companion? Adopt yourself a Fheral to
                boost your hero in a combat. You can also get two Fherals of the
                same kind to breed new and powerful creatures.
              </p>
              <div className={styles["pets-items"]}>
                <CheckItem text={"Fherals boost your hero stats"} />
                <CheckItem
                  text={
                    "Fherals will give your hero a passive effect each turn"
                  }
                />
                <CheckItem
                  text={
                    "Breed your Fherals and create new life in the Ohm’s universe!"
                  }
                />
                <CheckItem text={"Trade your Fherals with other heroes."} />
              </div>
            </div>
            <div
              className={`col-lg-7 col-sm-12 ${styles["pets-img-container"]}`}
            >
              <img
                className={styles["pets-img"]}
                src="/assets/images/img-feral.png"
                alt=""
              />
            </div>
          </div>
          <button
            className={`button-green button-green-small ${styles["play-button"]}`}
          >
            <a href="/account-settings/download">Play free</a>
          </button>
        </div>
      </div>
    </div>
  );
};
