import { useAccount, useBalance, useBlockNumber } from "wagmi";
import { getArkerTokenAddress, getChainId } from "../services/utils";
import { useEffect } from "react";
const ARKER_TOKEN_ADDRESS = getArkerTokenAddress();
const ARKER_TOKEN_CHAIN_ID = getChainId();

export const useGetArkerBalance = () => {
  const { isConnected, address } = useAccount();
  const { data: blockNumber } = useBlockNumber({ watch: true });
  const {
    data: arkerBalance,
    isLoading: arkerBalanceLoading,
    refetch: refetchArkerBalance,
  } = useBalance({
    address: isConnected && address,
    token: ARKER_TOKEN_ADDRESS,
    chainId: parseInt(ARKER_TOKEN_CHAIN_ID),
  });
  useEffect(() => {
    refetchArkerBalance();
  }, [blockNumber]);

  if (isConnected) {
    return { arkerBalance, arkerBalanceLoading };
  }
  return {
    arkerBalance: { value: 0, decimals: 18 },
    arkerBalanceLoading: false,
  };
};
