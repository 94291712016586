import { useContext, useEffect, useState } from "react";
import styles from "./marketplace-header.module.css";
import { Link, useLocation } from "react-router-dom";
import { PrevArrow } from "../../slick-slider-arrows/prev-arrow";
import { NextArrow } from "../../slick-slider-arrows/next-arrow";
import Slider from "react-slick";
import { getProfileData, logout } from "../../../services/account";
import { ArkerConnectButton } from "../../account/buttons/arker-connect-button/arker-connect-button";
import { useUserAuthenticated } from "../../../hooks/useUserAuthenticated";
import { SelectedItemContext } from "../../..";

export const MarketplaceHeader = () => {
  //CONTEXT VARIABLES
  const { marketplaceItemNavSelected, setMarketplaceItemNavSelected } =
    useContext(SelectedItemContext);

  //LOCATION VARIABLES
  const location = useLocation();
  let headerItemSelected;
  if (
    location.pathname.split("/")[1] === "inventory" &&
    location.pathname.split("/")[3] === "breeding"
  ) {
    headerItemSelected = "BREEDING";
  } else {
    if (
      (location.pathname.split("/")[1] === "inventory" &&
        location.pathname.split("/")[3] === "pets") ||
      (location.pathname.split("/")[1] === "inventory" &&
        location.pathname.split("/")[3] === "eggs") ||
      location.pathname.split("/")[2] === "inventory"
    ) {
      headerItemSelected = "INVENTORY";
    } else {
      headerItemSelected = "MARKET";
    }
  }

  //USER HOOKS
  const { isAuthenticated } = useUserAuthenticated();

  //USESTATE VARIABLES
  const [userData, setUserData] = useState();
  const [navigationItemSelected, setNavigationItemSelected] =
    useState(headerItemSelected);
  const [menuItemSelected, setMenuItemSelected] = useState(
    marketplaceItemNavSelected
  );
  const [itemsViewSelected, setItemsViewSelected] = useState("GRID");
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showMobileUserMenu, setShowMobileUserMenu] = useState(false);
  const [showMobileNavigationMenu, setShowMobileNavigationMenu] =
    useState(false);
  const [showStoreItemDropdown, setShowStoreItemDropdown] = useState(false);
  const [showInventoryItemDropdown, setShowInventoryItemDropdown] =
    useState(false);

  const showNavigationTabs =
    location.pathname === "/login" ||
    location.pathname === "/register" ||
    (location.pathname.split("/")[1] === "profile" &&
      (location.pathname.split("/")[2] === "validate" ||
        location.pathname.split("/")[2] === "recover"))
      ? false
      : true;

  const slickSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <NextArrow marketplaceSlider={true} />,
    prevArrow: <PrevArrow marketplaceSlider={true} />,
  };

  const setMenuItem = (item) => {
    setShowStoreItemDropdown(false);
    setShowInventoryItemDropdown(false);
    setNavigationItemSelected(item);
  };

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    const getUserData = async () => {
      const userDataRes = await getProfileData();
      localStorage.setItem("user.name", userDataRes.username);
      setUserData(userDataRes);
    };

    isAuthenticated && getUserData();
  }, [isAuthenticated]);

  return (
    <div className={styles.header}>
      <div className="marketplace-container">
        <div className={styles.navbar}>
          <div className={styles["mobile-navbar"]}>
            <div
              className={`${styles["mobile-navbar-item"]} ${
                styles["mobile-navbar-item-hamburguer"]
              } ${showMobileUserMenu ? styles["hide-icon-menu-open"] : <></>}`}
              onClick={() => setShowMobileNavigationMenu(true)}
            >
              <img
                src="/assets/images/marketplace/icons/menu-mobile-icon.svg"
                alt=""
              />
            </div>
            <div
              className={`${styles["mobile-logo"]} ${
                (showMobileUserMenu || showMobileNavigationMenu) &&
                styles["mobile-logo-user-open"]
              }`}
            >
              <a href="/marketplace">
                <img src="/assets/images/logos/logo-arker.png" alt="" />
                <span>shop</span>
              </a>
            </div>
            <div
              className={`${styles["mobile-navbar-item"]} ${
                styles["mobile-navbar-item-user"]
              } ${showMobileUserMenu ? styles["hide-icon-menu-open"] : <></>}`}
              onClick={() => setShowMobileUserMenu(true)}
            >
              <div>
                <img src="/assets/images/icons/user-icon-green.svg" alt="" />
              </div>
            </div>
          </div>

          <div
            className={`${styles["mobile-navbar-user"]} ${
              showMobileUserMenu ? styles["show-mobile-menu-user"] : <></>
            }`}
          >
            <div className={styles["user-menu-header"]}>
              <div
                className={`${styles["mobile-logo"]} ${
                  (showMobileUserMenu || showMobileNavigationMenu) &&
                  styles["mobile-logo-user-open"]
                }`}
              >
                <a href="/marketplace">
                  <img src="/assets/images/logos/logo-arker.png" alt="" />
                  <span>shop</span>
                </a>
              </div>
              <img
                className={styles["close-icon-green"]}
                src="/assets/images/marketplace/icons/close-x-green.svg"
                alt=""
                onClick={() => setShowMobileUserMenu(false)}
              />
            </div>
            <hr />
            <div className={styles["user-menu-user-info"]}>
              <div className={styles["user-menu-icon"]}>
                <img src="/assets/images/icons/user-icon-green.svg" alt="" />
              </div>
              <div className={styles["user-menu-info"]}>
                <span className={styles.username}>
                  {userData?.username ?? "Darkblade"}
                </span>
                <span className={styles.email}>
                  {localStorage.getItem("user.email") ?? "Darkblade@gmail.com"}
                </span>
              </div>
            </div>
            <hr />

            <div className={styles["user-menu-content"]}>
              {isAuthenticated ? (
                <ul>
                  {/* WISHLIST */}
                  {/* <Link
                  onClick={() => {
                    setShowMobileUserMenu(false);
                  }}
                  to={"/inventory/wishlist"}
                >
                  {" "}
                  <li>
                    <img src="/assets/images/icons/wishlist.svg" alt="" />
                    Whishlist
                  </li>
                </Link> */}

                  {/* ACCOUNT SETTINGS */}
                  <Link
                    onClick={() => {
                      setShowMobileUserMenu(false);
                    }}
                    to={"/account-settings/overview"}
                  >
                    <li>
                      <img
                        src="/assets/images/icons/settings-icon.svg"
                        alt=""
                      />
                      Account Settings
                    </li>
                  </Link>

                  {/* INVENTORY */}
                  <Link
                    onClick={() => {
                      setShowMobileUserMenu(false);
                    }}
                    to={"/inventory/all"}
                  >
                    <li>
                      <img src="/assets/images/icons/inventory.svg" alt="" />
                      Inventory
                    </li>
                  </Link>

                  {/* LANGUAGE */}
                  {/* <li>
                    <img src="/assets/images/icons/language.svg" alt="" />
                    Language
                  </li> */}

                  {/* SUPPORT */}
                  <Link
                    onClick={() => {
                      setShowMobileUserMenu(false);
                    }}
                    to={"/account-settings/support"}
                  >
                    <li>
                      <img
                        className={styles.support}
                        src="/assets/images/icons/suport-icon.svg"
                        alt=""
                      />
                      Support
                    </li>
                  </Link>
                  {/* LOGOUT */}
                  <button onClick={handleLogout} className="simple-button">
                    <img src="/assets/images/icons/arrow-logout.svg" alt="" />
                    Log out
                  </button>
                </ul>
              ) : (
                <>
                  {/* LOGIN */}
                  <div className={styles["login-container"]}>
                    <button className={`simple-button ${styles.login}`}>
                      <a href="/login">
                        Log in{" "}
                        <img
                          src="/assets/images/icons/arrow-logout.svg"
                          alt=""
                        />
                      </a>
                    </button>
                  </div>
                </>
              )}
            </div>

            <hr />

            {/* TOTAL CURRENCY */}
            <div className={styles["user-menu-footer"]}>
              <p>Total curency</p>
              <ArkerConnectButton header={true} />
            </div>
          </div>

          <div
            className={`${styles["mobile-navbar-navigation"]} ${
              showMobileNavigationMenu ? (
                styles["show-mobile-menu-navigation"]
              ) : (
                <></>
              )
            }`}
          >
            <div className={styles["user-menu-header"]}>
              <div
                className={`${styles["mobile-logo"]} ${
                  showMobileUserMenu || showMobileNavigationMenu
                    ? styles["mobile-logo-user-open"]
                    : undefined
                }`}
              >
                <a href="/marketplace">
                  <img src="/assets/images/logos/logo-arker.png" alt="" />
                  <span>shop</span>
                </a>
              </div>
              <img
                className={styles["close-icon"]}
                src="/assets/images/marketplace/icons/close-x.svg"
                alt=""
                onClick={() => setShowMobileNavigationMenu(false)}
              />
            </div>

            <hr />

            <div className={styles["navigation-menu-content"]}>
              <ul>
                {/* HOME */}
                <li>
                  <Link
                    onClick={() => {
                      setShowMobileNavigationMenu(false);
                    }}
                    to={"/"}
                  >
                    Home
                  </Link>
                </li>

                {/* STORE ITEM */}
                {/* <li
                  className={
                    navigationItemSelected === "STORE" &&
                    showStoreItemDropdown ? (
                      styles.active
                    ) : (
                      <></>
                    )
                  }
                  onClick={() => {
                    setMenuItem("STORE");
                    setShowInventoryItemDropdown(false);
                    setShowStoreItemDropdown(!showStoreItemDropdown);
                  }}
                >
                  Store
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8.696"
                    height="9.062"
                    viewBox="0 0 8.696 9.062"
                  >
                    <path
                      id="Polígono_2"
                      data-name="Polígono 2"
                      d="M3.5,0,7,6H0Z"
                      transform="translate(5.196) rotate(60)"
                      fill="#ffffff"
                    />
                  </svg>
                </li>
                <div
                  className={`${styles["navigation-mobile-dropdown"]} ${
                    showStoreItemDropdown &&
                    styles["show-navigation-mobile-dropdown"]
                  }`}
                >
                  <ul>
                    <li>
                      <Link
                        onClick={() => {
                          setShowMobileNavigationMenu(false);
                        }}
                        to={"/marketplace"}
                      >
                        Arkanes
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setShowMobileNavigationMenu(false);
                        }}
                        to={"/marketplace"}
                      >
                        Weapons
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setShowMobileNavigationMenu(false);
                        }}
                        to={"/marketplace"}
                      >
                        Relics
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setShowMobileNavigationMenu(false);
                        }}
                        to={"/marketplace"}
                      >
                        Fheral
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setShowMobileNavigationMenu(false);
                        }}
                        to={"/marketplace"}
                      >
                        Chests
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setShowMobileNavigationMenu(false);
                        }}
                        to={"/marketplace"}
                      >
                        Eggs
                      </Link>
                    </li>
                  </ul>
                </div> */}

                {/* MARKET */}
                <li
                  className={
                    navigationItemSelected === "MARKET"
                      ? styles.active
                      : undefined
                  }
                  onClick={() => setMenuItem("MARKET")}
                >
                  <Link
                    onClick={() => {
                      setShowMobileNavigationMenu(false);
                    }}
                    to={"/marketplace"}
                  >
                    Market
                  </Link>
                </li>

                {/* INVENTORY */}
                <li
                  className={
                    navigationItemSelected === "INVENTORY" &&
                    showInventoryItemDropdown
                      ? styles.active
                      : undefined
                  }
                  onClick={() => {
                    setMenuItem("INVENTORY");
                    setShowStoreItemDropdown(false);
                    setShowInventoryItemDropdown(!showInventoryItemDropdown);
                  }}
                >
                  Inventory
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8.696"
                    height="9.062"
                    viewBox="0 0 8.696 9.062"
                  >
                    <path
                      id="Polígono_2"
                      data-name="Polígono 2"
                      d="M3.5,0,7,6H0Z"
                      transform="translate(5.196) rotate(60)"
                      fill="#ffffff"
                    />
                  </svg>
                </li>

                {/* INVENTORY DROPDOWN */}
                <div
                  className={`${styles["navigation-mobile-dropdown"]} ${
                    showInventoryItemDropdown
                      ? styles["show-navigation-mobile-dropdown"]
                      : undefined
                  }`}
                  style={{
                    overflow: "hidden",
                    transition: "all ease-in-out 0.3s",
                  }}
                >
                  <ul>
                    {/* ARKANES */}
                    {/* <li>
                      <Link
                        onClick={() => {
                          setShowMobileNavigationMenu(false);
                        }}
                        to={"/inventory/arkanes"}
                      >
                        Arkanes
                      </Link>
                    </li> */}
                    {/* WEAPONS */}
                    {/* <li>
                      <Link
                        onClick={() => {
                          setShowMobileNavigationMenu(false);
                        }}
                        to={"/inventory/weapons"}
                      >
                        Weapons
                      </Link>
                    </li> */}
                    {/* RELICS */}
                    {/* <li>
                      <Link
                        onClick={() => {
                          setShowMobileNavigationMenu(false);
                        }}
                        to={"/inventory/relics"}
                      >
                        Relics
                      </Link>
                    </li> */}
                    {/* SKINS */}
                    {/* <li>
                      <Link
                        onClick={() => {
                          setShowMobileNavigationMenu(false);
                        }}
                        to={"/inventory/skins"}
                      >
                        Skins
                      </Link>
                    </li> */}
                    {/* PETS */}
                    <li>
                      <Link
                        onClick={() => {
                          setShowMobileNavigationMenu(false);
                          setMenuItem("INVENTORY");
                        }}
                        to={"/inventory/fheral/pets"}
                      >
                        Pets
                      </Link>
                    </li>
                    {/* CHESTS */}
                    {/* <li>
                      <Link
                        onClick={() => {
                          setShowMobileNavigationMenu(false);
                        }}
                        to={"/inventory/chests"}
                      >
                        Chests
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        onClick={() => {
                          setShowMobileNavigationMenu(false);
                          setMenuItem("INVENTORY");
                        }}
                        to={"/inventory/fheral/eggs"}
                      >
                        Eggs
                      </Link>
                    </li>
                  </ul>
                </div>

                {/* BREEDING */}
                <li
                  className={
                    navigationItemSelected === "BREEDING"
                      ? styles.active
                      : undefined
                  }
                  onClick={() => setMenuItem("BREEDING")}
                >
                  <Link to={"/inventory/fheral/breeding"}>Breeding</Link>
                </li>

                {/* PET FIGHT */}
                {/* <li
                  className={
                    navigationItemSelected === "PET_FIGHT" ? (
                      styles.active
                    ) : (
                      <></>
                    )
                  }
                  onClick={() => setMenuItem("PET_FIGHT")}
                >
                  <Link to={"/inventory/fheral/pet-fight"}>Pet Fight</Link>
                </li> */}
              </ul>
            </div>

            <hr />

            <div className={styles["navigation-menu-footer"]}>
              {/* PLAY ONLINE */}
              {/* <Link
                className={`${styles.item} ${styles["play-button"]}`}
                to="/play-online"
                onClick={() => {
                  setShowMobileNavigationMenu(false);
                }}
              >
                <div>
                  <img src="/assets/images/icons/play.svg" alt="" />
                </div>
                Play online
              </Link> */}

              {/* DOWNLOAD APP */}
              <Link
                to="/account-settings/download"
                onClick={() => {
                  setShowMobileNavigationMenu(false);
                }}
                className={styles.item}
              >
                <div>
                  <img src="/assets/images/icons/download-icon.svg" alt="" />
                </div>
                Download app
              </Link>
            </div>
          </div>

          <div className={styles.logo}>
            <a href="/marketplace">
              <img src="/assets/images/logos/logo-arker.png" alt="" />
              <span>shop</span>
            </a>
          </div>
          <div className={styles["navbar-items"]}>
            <div className={`${styles["nav-item"]}`}>
              {/* PLAY ONLINE */}
              {/* <Link
                className={`${styles.item} ${styles["play-button"]}`}
                to="/play-online"
              >
                <div>
                  <img src="/assets/images/icons/play.svg" alt="" />
                </div>
                Play online
              </Link> */}
            </div>
            <div className={styles["nav-item"]}>
              <ArkerConnectButton header={true} />
            </div>

            {/* USER BUTTON */}
            <div
              className={styles["nav-item"]}
              onClick={() => setShowUserMenu(!showUserMenu)}
            >
              <div className={`${styles.item} ${styles.user}`}>
                <div>
                  <img src="/assets/images/icons/user-icon-green.svg" alt="" />
                </div>
                <img
                  src="/assets/images/icons/bottom-arrow-icon-green.svg"
                  alt=""
                />
              </div>
            </div>

            {/* USER DROPDOWN */}
            {showUserMenu && (
              <div className={styles["profile-dropdown"]}>
                {isAuthenticated ? (
                  <>
                    {/* DROPDOWN HEADER */}
                    <div className={styles["dropdown-header"]}>
                      <div className={styles["dropdown-user-icon"]}>
                        <img
                          src="/assets/images/icons/user-icon-green.svg"
                          alt=""
                        />
                      </div>
                      <div className={styles["user-info"]}>
                        <span className={styles.username}>
                          {userData?.username ?? "Darkblade"}
                        </span>
                        <span className={styles.id}>
                          {localStorage.getItem("user.email") ??
                            "Darkblade@gmail.com"}
                        </span>
                      </div>
                    </div>

                    {/* DROPDOWN CONTENT */}
                    <ul>
                      {/* WISHLIST */}
                      {/* <Link to={"/inventory/wishlist"}>
                        <li>
                          <img src="/assets/images/icons/wishlist.svg" alt="" />
                          Whishlist
                        </li>
                      </Link> */}

                      {/* ACCOUNT SETTINGS */}
                      <Link to={"/account-settings/overview"}>
                        <li>
                          <img
                            src="/assets/images/icons/settings-icon.svg"
                            alt=""
                          />
                          Account Settings
                        </li>
                      </Link>

                      {/* INVENTORY */}
                      <Link to={"/inventory/all"}>
                        <li>
                          <img
                            src="/assets/images/icons/inventory.svg"
                            alt=""
                          />
                          Inventory
                        </li>
                      </Link>

                      {/* LANGUAE */}
                      {/*  <li>
                        <img src="/assets/images/icons/language.svg" alt="" />
                        Language
                      </li> */}

                      {/* SUPPORT */}
                      <Link to={"/account-settings/support"}>
                        <li>
                          <img
                            className={styles.support}
                            src="/assets/images/icons/suport-icon.svg"
                            alt=""
                          />
                          Support
                        </li>
                      </Link>
                    </ul>

                    {/* LOGOUT */}
                    <button
                      onClick={handleLogout}
                      className={`simple-button ${styles.logout}`}
                    >
                      <img src="/assets/images/icons/arrow-logout.svg" alt="" />
                      Log out
                    </button>
                  </>
                ) : (
                  /* LOGIN */
                  <div className={styles["login-container"]}>
                    <button className={`simple-button ${styles.login}`}>
                      <a href="/login">
                        Log in{" "}
                        <img
                          src="/assets/images/icons/arrow-logout.svg"
                          alt=""
                        />
                      </a>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {showNavigationTabs && (
          <div className={styles["navigation-tabs"]}>
            <ul className={styles["nav-links-container"]}>
              <li className={styles["nav-item"]}>
                <Link to={"/"}>Home</Link>
              </li>
              {/* STORE */}
              {/* <li
                className={
                  navigationItemSelected === "STORE" && showStoreItemDropdown
                    ? `${styles["nav-item"]} ${styles.active}`
                    : styles["nav-item"]
                }
                onClick={() => {
                  setMenuItem("STORE");
                  setShowInventoryItemDropdown(false);
                  setShowStoreItemDropdown(!showStoreItemDropdown);
                }}
              >
                <span>Store</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8.696"
                  height="9.062"
                  viewBox="0 0 8.696 9.062"
                >
                  <path
                    id="Polígono_2"
                    data-name="Polígono 2"
                    d="M3.5,0,7,6H0Z"
                    transform="translate(5.196) rotate(60)"
                    fill="#ffffff"
                  />
                </svg>
                <div
                  className={`${styles["navigation-dropdown"]} ${
                    showStoreItemDropdown ? (
                      styles["show-navigation-dropdown"]
                    ) : (
                      <></>
                    )
                  }`}
                >
                  <ul>
                    <li>
                      <Link
                        onClick={() => {
                          setShowStoreItemDropdown(false);
                          setShowInventoryItemDropdown(false);
                        }}
                        to={"/marketplace"}
                      >
                        Arkanes
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setShowStoreItemDropdown(false);
                          setShowInventoryItemDropdown(false);
                        }}
                        to={"/marketplace"}
                      >
                        Weapons
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setShowStoreItemDropdown(false);
                          setShowInventoryItemDropdown(false);
                        }}
                        to={"/marketplace"}
                      >
                        Relics
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setShowStoreItemDropdown(false);
                          setShowInventoryItemDropdown(false);
                        }}
                        to={"/marketplace"}
                      >
                        Fheral
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setShowStoreItemDropdown(false);
                          setShowInventoryItemDropdown(false);
                        }}
                        to={"/marketplace"}
                      >
                        Chests
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setShowStoreItemDropdown(false);
                          setShowInventoryItemDropdown(false);
                        }}
                        to={"/marketplace"}
                      >
                        Eggs
                      </Link>
                    </li>
                  </ul>
                </div>
              </li> */}
              {/* MARKET */}
              <li
                className={
                  navigationItemSelected === "MARKET"
                    ? `${styles["nav-item"]} ${styles.active}`
                    : styles["nav-item"]
                }
                onClick={() => setMenuItem("MARKET")}
              >
                <Link to={"/marketplace"}>Market</Link>
              </li>
              {/* INVENTORY */}
              <li
                className={
                  navigationItemSelected === "INVENTORY" ||
                  showInventoryItemDropdown
                    ? `${styles["nav-item"]} ${styles.active}`
                    : styles["nav-item"]
                }
                onClick={() => {
                  setMenuItem("INVENTORY");
                  setShowInventoryItemDropdown(!showInventoryItemDropdown);
                  setShowStoreItemDropdown(false);
                }}
              >
                <span>Inventory</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8.696"
                  height="9.062"
                  viewBox="0 0 8.696 9.062"
                >
                  <path
                    id="Polígono_2"
                    data-name="Polígono 2"
                    d="M3.5,0,7,6H0Z"
                    transform="translate(5.196) rotate(60)"
                    fill="#ffffff"
                  />
                </svg>
                <div
                  className={`${styles["navigation-dropdown"]} ${
                    showInventoryItemDropdown &&
                    styles["show-navigation-dropdown"]
                  }`}
                >
                  <ul>
                    {/* ARKANES */}
                    {/* <li>
                      <Link
                        onClick={() => {
                          setShowStoreItemDropdown(false);
                          setShowInventoryItemDropdown(false);
                        }}
                        to={"/inventory/arkanes"}
                      >
                        Arkanes
                      </Link>
                    </li> */}
                    {/* WEAPONS */}
                    {/* <li>
                      <Link
                        onClick={() => {
                          setShowStoreItemDropdown(false);
                          setShowInventoryItemDropdown(false);
                        }}
                        to={"/inventory/weapons"}
                      >
                        Weapons
                      </Link>
                    </li> */}
                    {/* RELICS */}
                    {/* <li>
                      <Link
                        onClick={() => {
                          setShowStoreItemDropdown(false);
                          setShowInventoryItemDropdown(false);
                        }}
                        to={"/inventory/relics"}
                      >
                        Relics
                      </Link>
                    </li> */}
                    {/* PETS */}
                    <li>
                      <Link
                        onClick={() => {
                          setMenuItem("INVENTORY");
                          setShowStoreItemDropdown(false);
                          setShowInventoryItemDropdown(false);
                        }}
                        to={"/inventory/fheral/pets"}
                      >
                        Pets
                      </Link>
                    </li>
                    {/* CHESTS */}
                    {/*  <li>
                      <Link
                        onClick={() => {
                          setShowStoreItemDropdown(false);
                          setShowInventoryItemDropdown(false);
                        }}
                        to={"/inventory/chests"}
                      >
                        Chests
                      </Link>
                    </li> */}
                    {/* SKINS */}
                    {/* <li>
                      <Link
                        onClick={() => {
                          setShowMobileNavigationMenu(false);
                        }}
                        to={"/inventory/skins"}
                      >
                        Skins
                      </Link>
                    </li> */}
                    {/* EGGS */}
                    <li>
                      <Link
                        onClick={() => {
                          setMenuItem("INVENTORY");
                          setShowStoreItemDropdown(false);
                          setShowInventoryItemDropdown(false);
                        }}
                        to={"/inventory/fheral/eggs"}
                      >
                        Eggs
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              {/* BREEDING */}
              <li
                className={
                  navigationItemSelected === "BREEDING"
                    ? `${styles["nav-item"]} ${styles.active}`
                    : styles["nav-item"]
                }
                onClick={() => setMenuItem("BREEDING")}
              >
                <Link to={"/inventory/fheral/breeding"}>Breeding</Link>
              </li>
              {/* <li
              className={
                navigationItemSelected === "PET_FIGHT"
                  ? `${styles["nav-item"]} ${styles.active}`
                  : styles["nav-item"]
              }
              onClick={() => setMenuItem("PET_FIGHT")}
            >
              <Link to={"/marketplace"}>Pet fight</Link>
            </li> */}
            </ul>
            {/* <ul className={styles.search}>
              <li className={styles["nav-item"]}>
                <input type="text" placeholder="Search for items" />
              </li>
            </ul> */}
          </div>
        )}

        {location.pathname === "/marketplace" && (
          <>
            <Slider {...slickSliderSettings}>
              <div className={styles["slider-banner"]}>
                <div className={styles.content}>
                  <h3>3D Beta Available!</h3>
                  <p className="mt-4">
                    Try our new version of Arker 3D for PC!
                  </p>
                  <Link className="link-inactive" to={"/account-settings/download"}>
                    <button className="button-green-effect">Download</button>
                  </Link>
                </div>
                <div className={styles.portraits}>
                  <img
                    src="/assets/images/marketplace/portrait/portrait-berserker-3000x2048px.png"
                    alt=""
                  />
                  <img
                    src="/assets/images/marketplace/portrait/AR_Victory-Portrait2_Final_NBG.png"
                    alt=""
                  />
                </div>
              </div>
            </Slider>

            <div className={styles["items-tabs-container"]}>
              <ul className={styles["items-tabs"]}>
                <li
                  className={`${styles["item-tab"]} ${
                    menuItemSelected === "ALL_ITEMS" ? styles.active : <></>
                  }`}
                  onClick={() => {
                    setMenuItemSelected("ALL_ITEMS");
                    setMarketplaceItemNavSelected("ALL_ITEMS");
                  }}
                  title="All items"
                >
                  <span className={styles["navbar-text"]}>All items</span>
                  <img
                    className={styles["navbar-img"]}
                    src="/assets/images/marketplace/icons/all-items-navbar.svg"
                    alt=""
                  />
                </li>

                {/* <li
                  className={`${styles["item-tab"]} ${
                    menuItemSelected === "TRENDING" ? styles.active : <></>
                  }`}
                  onClick={() => setMenuItemSelected("TRENDING")}
                  title="Trending"
                >
                  <span className={styles["navbar-text"]}>Trending</span>
                  <img
                    className={styles["navbar-img"]}
                    src="/assets/images/marketplace/icons/trending-navbar.svg"
                    alt=""
                  />
                </li>

                <li
                  className={`${styles["item-tab"]} ${
                    menuItemSelected === "CHESTS" ? styles.active : <></>
                  }`}
                  onClick={() => setMenuItemSelected("CHESTS")}
                  title="Chests"
                >
                  <span className={styles["navbar-text"]}>Chests</span>
                  <img
                    className={styles["navbar-img"]}
                    src="/assets/images/marketplace/icons/chest-navbar.svg"
                    alt=""
                  />
                </li>

                <li
                  className={`${styles["item-tab"]} ${
                    menuItemSelected === "ARKANES" ? styles.active : <></>
                  }`}
                  onClick={() => setMenuItemSelected("ARKANES")}
                  title="Arkanes"
                >
                  <span className={styles["navbar-text"]}>Arkanes</span>
                  <img
                    className={styles["navbar-img"]}
                    src="/assets/images/marketplace/icons/arkane-navbar.svg"
                    alt=""
                  />
                </li>

                <li
                  className={`${styles["item-tab"]} ${
                    menuItemSelected === "WEAPONS" ? styles.active : <></>
                  }`}
                  onClick={() => setMenuItemSelected("WEAPONS")}
                  title="Weapons"
                >
                  <span className={styles["navbar-text"]}>Weapons</span>
                  <img
                    className={styles["navbar-img"]}
                    src="/assets/images/marketplace/icons/weapon-navbar.svg"
                    alt=""
                  />
                </li>

                <li
                  className={`${styles["item-tab"]} ${
                    menuItemSelected === "RELICS" ? styles.active : <></>
                  }`}
                  onClick={() => setMenuItemSelected("RELICS")}
                  title="Relics"
                >
                  <span className={styles["navbar-text"]}>Relics</span>
                  <img
                    className={styles["navbar-img"]}
                    src="/assets/images/marketplace/icons/relic-navbar.svg"
                    alt=""
                  />
                </li>

                <li
                  className={`${styles["item-tab"]} ${
                    menuItemSelected === "TOKENS" ? styles.active : <></>
                  }`}
                  onClick={() => setMenuItemSelected("TOKENS")}
                  title="Tokens"
                >
                  <span className={styles["navbar-text"]}>Tokens</span>
                  <img
                    className={styles["navbar-img"]}
                    src="/assets/images/marketplace/icons/token-navbar.svg"
                    alt=""
                  />
                </li>

                <li
                  className={`${styles["item-tab"]} ${
                    menuItemSelected === "SKINS" ? styles.active : <></>
                  }`}
                  onClick={() => setMenuItemSelected("SKINS")}
                  title="Skins"
                >
                  <span className={styles["navbar-text"]}>Skins</span>
                  <img
                    className={styles["navbar-img"]}
                    style={{ width: "35px" }}
                    src="/assets/images/marketplace/icons/skin-navbar.svg"
                    alt=""
                  />
                </li> */}

                <li
                  className={`${styles["item-tab"]} ${
                    menuItemSelected === "PETS" ? styles.active : <></>
                  }`}
                  onClick={() => {
                    setMenuItemSelected("PETS");
                    setMarketplaceItemNavSelected("PETS");
                  }}
                  title="Fherals"
                >
                  <span className={styles["navbar-text"]}>Pets</span>
                  <img
                    className={styles["navbar-img"]}
                    src="/assets/images/marketplace/icons/fheral-navbar.svg"
                    alt=""
                  />
                </li>

                <li
                  className={`${styles["item-tab"]} ${
                    menuItemSelected === "EGGS" ? styles.active : <></>
                  }`}
                  onClick={() => {
                    setMenuItemSelected("EGGS");
                    setMarketplaceItemNavSelected("EGGS");
                  }}
                  title="Eggs"
                >
                  <span className={styles["navbar-text"]}>Eggs</span>
                  <img
                    className={styles["navbar-img"]}
                    src="/assets/images/marketplace/icons/egg-navbar.svg"
                    alt=""
                  />
                </li>
              </ul>

              {/* <ul className={styles["select-view-container"]}>
                <li
                  className={`${styles["select-view-item"]} ${
                    itemsViewSelected === "LIST" ? styles.active : <></>
                  }`}
                  onClick={() => setItemsViewSelected("LIST")}
                >
                  <img
                    src="/assets/images/icons/list-items.svg"
                    alt="list items icon"
                  />
                </li>
                <li
                  className={`${styles["select-view-item"]} ${
                    itemsViewSelected === "GRID" ? styles.active : <></>
                  }`}
                  onClick={() => setItemsViewSelected("GRID")}
                >
                  <img
                    src="/assets/images/icons/normal-view-list.svg"
                    alt="default view icon"
                  />
                </li>
              </ul> */}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
