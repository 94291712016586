import { Link, Navigate } from "react-router-dom";
import styles from "./login.module.css";
import { useState } from "react";
import { linkAccountWithWombat, login } from "../../../services/account";
import toast, { Toaster } from "react-hot-toast";
import { useUserAuthenticated } from "../../../hooks/useUserAuthenticated";

export const Login = () => {
  //USESTATE VARIABLES
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState();
  const [loading, setLoading] = useState(false);

  //USER HOOKS
  const { isAuthenticated } = useUserAuthenticated();

  const handleOnchangePassword = (e) => {
    const value = e.target.value;

    if (value.length >= 6) {
      setPassword(e.target.value);
    }
  };

  const handleLogin = async () => {
    try {
      setLoading(true);

      await login(email, password);

      try {
        const wombat_UID = localStorage.getItem("wombat.uid");
        if (wombat_UID) {
          await linkAccountWithWombat(wombat_UID);
        }
      } catch (error) {
        console.log(error);
      }

      setLoading(false);

      window.location.replace("/marketplace");
    } catch (error) {
      toast.error(error.toString());
      setLoading(false);
    }
  };

  if (isAuthenticated) {
    return (
      <div style={{ height: "100vh" }}>
        <Navigate to={"/marketplace"} />
      </div>
    );
  }

  return (
    <div className={styles["main-container"]}>
      <Toaster />
      <div className={styles["login-container"]}>
        <Link className={styles["close-button"]} to="/marketplace">
          <img src="/assets/images/icons/close-icon.svg" alt="" />
        </Link>
        <h1>Log in to your account</h1>
        <hr />
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quos ex
          quasi ratione possimus sequi cupiditate quidem a dignissimos at
        </p>
        <div className={styles["form-container"]}>
          <form>
            <div className={styles["form-item"]}>
              <label htmlFor="email">Email</label>
              <div className={styles["input-container"]}>
                <div>
                  <img src="/assets/images/icons/email-icon.svg" alt="" />
                </div>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Enter email"
                />
              </div>
            </div>

            <div className={styles["form-item"]}>
              <label htmlFor="password">Password</label>
              <div className={styles["input-container"]}>
                <div>
                  <img
                    className={styles["password-icon"]}
                    src="/assets/images/icons/key-password.svg"
                    alt=""
                  />
                </div>
                <input
                  onChange={handleOnchangePassword}
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  autoComplete="new-password"
                  placeholder="Enter the password"
                />
              </div>
              <button
                type="button"
                className={`${styles["show-pass"]} ${
                  showPassword && styles["hide-pass"]
                }`}
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            <div className={styles["register_recover_pass"]}>
              <p>
                New here? <Link to="/register">Register now</Link>
              </p>
              <p>
                Forgot your password?{" "}
                <Link to="/profile/recover" state={{ email: email }}>
                  Recover it
                </Link>
              </p>
            </div>
            <button
              type="button"
              className={`button-green ${styles["login-button"]}`}
              onClick={!loading ? handleLogin : undefined}
            >
              {loading ? "Loading…" : "Log in"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
