import { InventoryFheralEggs } from "../fheral/inventory-fheral-eggs";
import { InventoryFheralPets } from "../fheral/inventory-fheral-pets";

export const InventoryAll = () => {
  return (
    <>
      <InventoryFheralEggs />
      <div className="mt-4"></div>
      <InventoryFheralPets />
    </>
  );
};
