import styles from "./marketplace-item-characteristics.module.css";

export const MarketplaceItemCharacteristics = ({
  type,
  archetype = undefined,
  rarity = undefined,
  genre = undefined,
  classType = undefined,
  small = false,
}) => {
  return (
    <div className={styles["item-characteristics"]}>
      <div className={styles["item-genre"]}>
        <img
          src={`/assets/images/marketplace/icons/${genre}-fheral.svg`}
          alt=""
        />
      </div>
      {!small && (
        <>
          <div className={styles["item-type"]}>
            <img
              src={`/assets/images/marketplace/icons/${classType}.svg`}
              alt=""
            />
          </div>

          <div className={styles["item-rarity"]}>
            <span>{classType}</span>
          </div>
        </>
      )}

      {classType === "fheral" && (
        <div className={styles["item-rarity"]}>
          <span>{type}</span>
        </div>
      )}

      {type === "chest" ? (
        <div className={`${styles["item-archetype"]} ${styles[archetype]}`}>
          <span>Card packs</span>
        </div>
      ) : (
        archetype && (
          <div className={`${styles["item-archetype"]} ${styles[archetype]}`}>
            <span>{archetype}</span>
          </div>
        )
      )}

      {rarity && (
        <div className={styles["item-rarity"]}>
          <span>{rarity}</span>
        </div>
      )}
    </div>
  );
};
