import styles from "./blog-section.module.css";
import { BlogPosts } from "./blog-posts/blog-posts";

export const BlogSection = () => {
  return (
    <>
      <div className={styles.blogSection}>
        <div className={`arker-container ${styles["blog-section-content"]}`}>
          <h3 className={styles["blogSection-title"]}>
            CODES, STATS, AND NEWS
          </h3>
          <img
            className="divider-s"
            src="/assets/images/elements/home-divider.svg"
            alt=""
          />
          <p className={styles["blogSection-text"]}>
            Explore Arker's world, challenge your limits and immerse yourself in
            unforgettable adventures. Welcome to our Arker blog
          </p>

          <BlogPosts />

          <button
            className={`button-green button-green-small ${styles["load-more-btn"]}`}
          >
            <a href="/account-settings/download">Play Free</a>
          </button>
        </div>
      </div>
    </>
  );
};
