import { useReadContract } from "wagmi";
import marketABI from "../../assets/abis/marketplace.json";
import { getChainId, getMarketAddress } from "../../services/utils";

export const useMaxExpireDate = () => {
  const { data: maxExpireDate, isLoading: maxExpireDateLoading } =
    useReadContract({
      abi: marketABI,
      functionName: "_maxExpireDate",
      address: getMarketAddress(),
      chainId: getChainId(),
    });

  return { maxExpireDate, maxExpireDateLoading };
};
