import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Home } from "./components/home/home";
import "bootstrap/dist/css/bootstrap.css";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

//FONT
import "@fontsource/bai-jamjuree/200.css";
import "@fontsource/bai-jamjuree/300.css";
import "@fontsource/bai-jamjuree/400.css";
import "@fontsource/bai-jamjuree/500.css";
import "@fontsource/bai-jamjuree/600.css";
import "@fontsource/bai-jamjuree/700.css";
import "@fontsource/orbitron/400.css";
import "@fontsource/orbitron/500.css";
import "@fontsource/orbitron/600.css";
import "@fontsource/orbitron/700.css";
import "@fontsource/orbitron/800.css";
import "@fontsource/orbitron/900.css";

import { Marketplace } from "./components/marketplace/marketplace";
import { LandingLayout } from "./components/layouts/landing-layout";
import { AppLayout } from "./components/layouts/marketplace-layout";
import { PlayOnline } from "./components/play-online/play-online";
import { AccountSettingsOverview } from "./components/marketplace/account-settings/overview/account-settings-overview";
import { AccountSettingsLayout } from "./components/layouts/account-settings/account-settings-layout";
import { AccountSettingsProfileDetails } from "./components/marketplace/account-settings/profile-details/account-settings-profile-details";
import { AccountSettingsSupport } from "./components/marketplace/account-settings/support/account-settings-support";
import { AccountSettingsSecurity } from "./components/marketplace/account-settings/security/account-settings-security";
import { AccountSettingsDownloadFiles } from "./components/marketplace/account-settings/download/account-settings-download-files";
import { AccountSettingsActivity } from "./components/marketplace/account-settings/activity/account-settings-activity";
import { InventoryLayout } from "./components/layouts/inventory/inventory-layout";
import { InventoryArkanes } from "./components/marketplace/inventory/arkanes/inventory-arkanes";
import { InventorySkins } from "./components/marketplace/inventory/skins/inventory-skins";
import { InventoryAchivements } from "./components/marketplace/inventory/achivements/inventory-achivements";
import { InventoryAll } from "./components/marketplace/inventory/all/inventory-all";
import { InventoryWishlist } from "./components/marketplace/inventory/wishlist/inventory-wishlist";
import { InventoryWeapons } from "./components/marketplace/inventory/weapons/inventory-weapons";
import { InventoryChests } from "./components/marketplace/inventory/chests/inventory-chests";
import { InventoryRelics } from "./components/marketplace/inventory/relics/inventory-relics";
import { InventoryFheralPets } from "./components/marketplace/inventory/fheral/inventory-fheral-pets";
import { InventoryFheralEggs } from "./components/marketplace/inventory/fheral/inventory-fheral-eggs";
import { InventoryFheralBreeding } from "./components/marketplace/inventory/fheral/breeding/inventory-fheral-breeding";
import { InventoryFheralPetFight } from "./components/marketplace/inventory/fheral/inventory-fheral-pet-fight";
import { Privacy } from "./pages/privacy/privacy";
import { MarketplaceItemInfo } from "./components/marketplace/marketplace-item-info/marketplace-item-info";
import { Login } from "./components/account/login/login";
import { Register } from "./components/account/register/register";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { config } from "./services/wagmi";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { Validate } from "./components/account/validate/validate";
import { RecoverPassword } from "./components/account/recover-password/recover-password";
import { graphqlClient } from "./services/apollo";
import { useUserAuthenticated } from "./hooks/useUserAuthenticated";
import { MarketplacePetInfo } from "./components/marketplace/marketplace-item-info/pet-info/marketplace-pet-info";
import { MarketplaceEggInfo } from "./components/marketplace/marketplace-item-info/egg-info/marketplace-egg-info";
import { RedirectPage } from "./components/redirect-page/redirect-page";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

export const SelectedItemContext = React.createContext();
export const SelectedItemProvider = ({ children }) => {
  const [marketplaceItemNavSelected, setMarketplaceItemNavSelected] =
    useState("ALL_ITEMS");
  return (
    <SelectedItemContext.Provider
      value={{ marketplaceItemNavSelected, setMarketplaceItemNavSelected }}
    >
      {children}
    </SelectedItemContext.Provider>
  );
};

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useUserAuthenticated();

  if (isAuthenticated) {
    return <>{children}</>;
  }

  return <Navigate to="/login" />;
};

const router = createBrowserRouter([
  {
    element: <LandingLayout />,
    children: [
      {
        path: "/privacy",
        element: <Privacy />,
      },
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "*",
        element: <Navigate to="/" />,
      },
      {
        path: "/redirect/:pageName",
        element: <RedirectPage />,
      },
    ],
  },
  {
    element: <AppLayout />,
    children: [
      {
        path: "/marketplace",
        element: <Marketplace />,
      },
      {
        path: "/marketplace/egg/:id/:saleId",
        element: <MarketplaceEggInfo />,
      },
      {
        path: "/marketplace/pet/:id/:saleId",
        element: <MarketplacePetInfo />,
      },
      {
        path: "/marketplace/inventory/egg/:id",
        element: <MarketplaceEggInfo />,
      },
      {
        path: "/marketplace/inventory/pet/:id",
        element: <MarketplacePetInfo />,
      },
      /* {
        path: "/marketplace/item/:id",
        element: <MarketplaceItemInfo />,
      }, */
      /* {
        path: "/play-online",
        element: <PlayOnline />,
      }, */
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/profile/validate/:code",
        element: (
          <PrivateRoute>
            <Validate />
          </PrivateRoute>
        ),
      },
      {
        path: "/profile/recover",
        element: <RecoverPassword />,
      },
      {
        path: "/profile/recover/:code",
        element: <RecoverPassword />,
      },
    ],
  },
  {
    element: <AccountSettingsLayout />,
    children: [
      {
        path: "/account-settings/overview",
        element: (
          <PrivateRoute>
            <AccountSettingsOverview />
          </PrivateRoute>
        ),
      },
      {
        path: "/account-settings/profile-details",
        element: (
          <PrivateRoute>
            <AccountSettingsProfileDetails />
          </PrivateRoute>
        ),
      },
      /* {
        path: "/account-settings/support",
        element: (
          <PrivateRoute>
            <AccountSettingsSupport />
          </PrivateRoute>
        ),
      }, */
      {
        path: "/account-settings/security",
        element: (
          <PrivateRoute>
            <AccountSettingsSecurity />
          </PrivateRoute>
        ),
      },
      {
        path: "/account-settings/download",
        element: <AccountSettingsDownloadFiles />,
      },
      {
        path: "/account-settings/activity",
        element: (
          <PrivateRoute>
            <AccountSettingsActivity />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    element: <InventoryLayout />,
    children: [
      {
        path: "/inventory/all",
        element: (
          <PrivateRoute>
            <InventoryAll />
          </PrivateRoute>
        ),
      },
      /*{
        path: "/inventory/arkanes",
        element: (
          <PrivateRoute>
            <InventoryArkanes />
          </PrivateRoute>
        ),
      },
      {
        path: "/inventory/skins",
        element: (
          <PrivateRoute>
            <InventorySkins />
          </PrivateRoute>
        ),
      },
      {
        path: "/inventory/weapons",
        element: (
          <PrivateRoute>
            <InventoryWeapons />
          </PrivateRoute>
        ),
      },
      {
        path: "/inventory/chests",
        element: (
          <PrivateRoute>
            <InventoryChests />
          </PrivateRoute>
        ),
      },
      {
        path: "/inventory/relics",
        element: (
          <PrivateRoute>
            <InventoryRelics />
          </PrivateRoute>
        ),
      }, */
      {
        path: "/inventory/fheral/pets",
        element: (
          <PrivateRoute>
            <InventoryFheralPets />
          </PrivateRoute>
        ),
      },
      {
        path: "/inventory/fheral/eggs",
        element: (
          <PrivateRoute>
            <InventoryFheralEggs />
          </PrivateRoute>
        ),
      },
      {
        path: "/inventory/fheral/breeding",
        element: (
          <PrivateRoute>
            <InventoryFheralBreeding />
          </PrivateRoute>
        ),
      },
      /* {
        path: "/inventory/fheral/pet-fight",
        element: (
          <PrivateRoute>
            <InventoryFheralPetFight />
          </PrivateRoute>
        ),
      },
      {
        path: "/inventory/achivements",
        element: (
          <PrivateRoute>
            <InventoryAchivements />
          </PrivateRoute>
        ),
      },
      {
        path: "/inventory/wishlist",
        element: (
          <PrivateRoute>
            <InventoryWishlist />
          </PrivateRoute>
        ),
      }, */
    ],
  },
]);

root.render(
  <SelectedItemProvider>
    <WagmiProvider config={config}>
      <ApolloProvider client={graphqlClient}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider>
            <RouterProvider router={router} />
          </RainbowKitProvider>
        </QueryClientProvider>
      </ApolloProvider>
    </WagmiProvider>
  </SelectedItemProvider>
);
