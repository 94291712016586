import { ParentItemInfo } from "./parent-item-info";
import styles from "./parents-info.module.css";

export const ParentsInfo = ({ parentsData, breedCount, maxBreeds }) => {
  return (
    <div className={styles["parents-info"]}>
      <div className={styles["parents-info-header"]}>
        <p>parents info</p>
      </div>

      <div className={styles["parents-container"]}>
        {parentsData.fatherData && parentsData.motherData ? (
          <>
            {/* FATHER */}
            <ParentItemInfo
              parentData={parentsData.fatherData}
              parent="father"
            />

            {/* MOTHER */}
            <ParentItemInfo
              parentData={parentsData.motherData}
              parent="mother"
            />
          </>
        ) : (
          <h2>Loading…</h2>
        )}
      </div>
    </div>
  );
};
