import { getRandNum, removeHTMLTags } from "../../../../../services/utils";
import styles from "./blog-post-card.module.css";

export const BlogPostCard = ({ img, title, text, href }) => {
  return (
    <div className={styles["blog-post-card"]}>
      <img
        className={styles["blog-post-img"]}
        src={
          img
            ? img
            : `/assets/images/blogSection/blog-post-${getRandNum(3)}.png`
        }
        alt="Blog Post pic"
      />
      <div className={styles["blog-post-card-content"]}>
        <h4>
          <div className={styles.separator} />
          {title}
        </h4>
        <p
        /* dangerouslySetInnerHTML={{
            __html: text.substring(0, 230),
          }} */
        >
          {removeHTMLTags(text.substring(0, 230))}
        </p>
        <p className={styles["three-dots"]}>...</p>
        <div
          className={`simple-button ${styles["read-more-container"]} ${
            !navigator.share && "mt-4"
          }`}
        >
          <a
            href={href}
            target="_blank"
            rel="noreferrer"
            className={styles["read-more"]}
          >
            {" "}
            Read more
          </a>
        </div>

        <div className={styles["blog-post-footer"]}>
          {/* <span>
            <img
              className={styles.heart}
              src="/assets/images/icons/heart-icon.svg"
              alt=""
            />{" "}
            609
          </span>
          <span>
            <img
              className={styles.minus}
              src="/assets/images/icons/minus-icon.svg"
              alt=""
            />{" "}
            120
          </span> */}
          {navigator.share && (
            <img
              className={styles.share}
              src="/assets/images/icons/share-icon.svg"
              alt=""
              onClick={() => {
                navigator.share({
                  title: title,
                  text: "Medium post from ArkerOffficial in medium",
                  url: href,
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
