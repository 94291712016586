import { useAccount, useReadContract } from "wagmi";
import { getArkerTokenAddress, getChainId } from "../../services/utils";
import { erc20Abi } from "viem";

export const useAllowance = (target) => {
  const { address } = useAccount();

  const { data: allowance, refetch: refetchAllowance } = useReadContract({
    abi: erc20Abi,
    address: getArkerTokenAddress(),
    functionName: "allowance",
    args: [address, target],
    chainId: getChainId(),
  });

  return { allowance, refetchAllowance };
};
