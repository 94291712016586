import { useEffect, useState } from "react";
import {
  getOffchainPetData,
  getRarity,
  getTypeName,
  setPassiveDesc,
} from "../../services/pets";
import { useGetParents } from "../eggsHooks/useGetParents";
import { usePetData } from "./usePetData";
import { useGetOnSale } from "../marketHooks/useGetOnSale";

export const useParsePetData = (petId, saleId) => {
  const [name, setName] = useState();
  const [type, setType] = useState();
  const [skin, setSkin] = useState();
  const [rarity, setRarity] = useState();
  const [genre, setGenre] = useState();
  const [description, setDescription] = useState();
  const [attack, setAttack] = useState();
  const [life, setLife] = useState();
  const [energy, setEnergy] = useState();
  const [agility, setAgility] = useState();
  const [power, setPower] = useState();
  const [isBanned, setIsBanned] = useState(false);

  const { petParents, petParentsLoading } = useGetParents(petId);
  const { pet, petLoading } = usePetData(petId);
  const { onSale, onSaleLoading } = useGetOnSale(saleId);

  useEffect(() => {
    const getPetIsBanned = async () => {
      try {
        const isBanned = (await getOffchainPetData(petId)).isBanned;
        setIsBanned(isBanned);
      } catch (error) {
        console.log(error);
      }
    };

    if (
      pet &&
      petParents &&
      !petLoading &&
      !petParentsLoading &&
      petId !== 0n
    ) {
      getPetIsBanned();
      setSkin(pet[5]);
      const petType = getTypeName(pet[0]);
      setType(petType);

      const tempName =
        parseInt(petParents[0]) === 0
          ? `${petType.toUpperCase()} Genesis`
          : `${petType.toUpperCase()}`;

      setName(`${tempName} #${petId}`);

      setRarity(getRarity(pet));
      setGenre(pet[7] ? "male" : "female");
      setDescription(setPassiveDesc(pet));
      setAttack(pet[1].toString());
      setLife(pet[2].toString());
      setEnergy(pet[3].toString());
      setAgility(pet[4].toString());
      setPower(pet[6].toString());
    }
  }, [pet, petParents, petId, petLoading, petParentsLoading]);

  return {
    petData: {
      name,
      type,
      skin,
      rarity,
      genre,
      description,
      attack,
      life,
      energy,
      agility,
      power,
      isBanned,
      onSale,
    },
    petDataLoading: petLoading || petParentsLoading || onSaleLoading,
  };
};
