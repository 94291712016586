import { useAccount, useReadContract } from "wagmi";
import utilsABI from "../assets/abis/nftutils.json";
import { getChainId, getNFTUtilsAddress } from "../services/utils";

export const useTokensOfOwner = (tokenAddress) => {
  const { address } = useAccount();

  const { data: tokensOfOwner, isLoading: tokensOfOwnerLoading } =
    useReadContract({
      abi: utilsABI,
      address: getNFTUtilsAddress(),
      functionName: "tokensOfOwner",
      args: [tokenAddress, address],
      chainId: getChainId(),
    });

  return { tokensOfOwner, tokensOfOwnerLoading };
};
