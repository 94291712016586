import { useAccount, useReadContract } from "wagmi";
import eggsABI from "../../assets/abis/breeder.json";
import { getChainId, getMarketAddress } from "../../services/utils";
import { getEggsAddress } from "../../services/eggs";

export const useApprovedForAll = () => {
  const { address } = useAccount();

  const { data: isApprovedForAll, refetch: refetchApprovedForAll } =
    useReadContract({
      abi: eggsABI,
      functionName: "isApprovedForAll",
      address: getEggsAddress(),
      chainId: getChainId(),
      args: [address, getMarketAddress()],
    });

  return { isApprovedForAll, refetchApprovedForAll };
};
