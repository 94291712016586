import { useEffect, useState } from "react";
import styles from "./account-settings-profile-details.module.css";
import { ArkerConnectButton } from "../../../account/buttons/arker-connect-button/arker-connect-button";
import { useAccount, useBlockNumber } from "wagmi";
import { ArkerPairWalletButton } from "../../../account/buttons/arker-pair-wallet-button/arker-pair-wallet-button";
import { useUserData } from "../../../../hooks/useUserData";
import {
  getArkerTokenAddress,
  getChainId,
  getCharacterClassId,
  getPaymentManagerAddress,
  MAX_INT,
  truncateETHAddress,
} from "../../../../services/utils";
import {
  changeUsername,
  getChangeUsernamePrice,
} from "../../../../services/account";
import { Modal } from "../../../Modal";
import toast, { Toaster } from "react-hot-toast";
import { useAllowance } from "../../../../hooks/tokenHooks/useAllowance";
import { useApprove } from "../../../../hooks/tokenHooks/useApprove";
import { erc20Abi } from "viem";
import moment from "moment/moment";
import { getWithdraws, withdraw } from "../../../../services/payment";
import { useClaimPayment } from "../../../../hooks/paymentHooks/useClaimPayment";
import paymentManagerABI from "../../../../assets/abis/payments.json";
import { WithdrawTableItem } from "./withdraw-table-item";

export const AccountSettingsProfileDetails = () => {
  //USESTATE VARIABLES
  const [username, setUsername] = useState("");
  const [emailAddress, setEmailAddress] = useState(
    localStorage.getItem("user.email") ?? ""
  );
  const [imageProfileId, setImageProfileId] = useState();
  const [imageProfileIdLoading, setImageProfileIdLoading] = useState(true);
  const [walletPaired, setWalletPaired] = useState("Not yet");
  const [changeUsernamePrice, setChangeUsernamePrice] = useState();
  const [isChangeUsername, setIsChangeUsername] = useState(false);
  const [showPriceAlertModal, setShowPriceAlertModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amountWithdraw, setAmountWithdraw] = useState();
  const [refetchUserData, setRefetchUserData] = useState(false);
  const [lastWithdraws, setLastWithdraws] = useState();

  //WAGMI HOOKS
  const { isConnected, address } = useAccount();
  const { data: blockNumber } = useBlockNumber({ watch: true });

  //USER HOOKS
  const { userData, userDataLoading } = useUserData(refetchUserData);

  //TOKENS HOOKS
  const { allowance, refetchAllowance } = useAllowance(
    getPaymentManagerAddress()
  );
  const { approveWrite, approveError, isApproveError } = useApprove();

  //PAYMENT HOOKS
  const { claimPaymentWrite, claimPaymentError, isClaimPaymentError } =
    useClaimPayment();

  const handleApproveARKER = async () => {
    setLoading(true);
    try {
      await approveWrite({
        abi: erc20Abi,
        address: getArkerTokenAddress(),
        functionName: "approve",
        args: [getPaymentManagerAddress(), MAX_INT],
        chainId: getChainId(),
      });
      toast.success("Approve ARKER Successfully!");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleWithdraw = async () => {
    setLoading(true);

    if (!amountWithdraw) {
      toast.error("Enter an amount to withdraw");
      setLoading(false);
      return;
    }

    if (parseFloat(amountWithdraw) <= 0) {
      toast.error("Enter an amount greater than 0");
      setLoading(false);
      return;
    }

    try {
      const data = await withdraw(amountWithdraw);
      await claimPaymentWrite({
        abi: paymentManagerABI,
        address: getPaymentManagerAddress(),
        functionName: "claimPayment",
        args: [data.finalAmount, data.nonce, data.signature],
        chainId: getChainId(),
      });

      toast.success("Withdraw correctly");
      setRefetchUserData(true);
      setAmountWithdraw("");
    } catch (error) {
      toast.error(error.toString());
      setLoading(false);
    }
  };

  const handleSetWalletPaired = (newWallet) => {
    setWalletPaired(newWallet);
  };

  const handleChangeUsername = async () => {
    setLoading(true);
    try {
      await changeUsername(username);
      setLoading(false);
      setShowPriceAlertModal(false);
      toast.success("Username changed correctly");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(error.toString());
    }
  };

  useEffect(() => {
    const getCharacterIdForProfileIMG = async () => {
      const characterId = await getCharacterClassId(userData?.characterId);

      if (characterId) {
        setImageProfileId(characterId);
      }

      setImageProfileIdLoading(false);
    };

    const getPriceForChangeUsername = async () => {
      try {
        setChangeUsernamePrice(await getChangeUsernamePrice());
      } catch (error) {
        console.log(error);
      }
    };

    const getLastWithdraws = async () => {
      const withdraws = await getWithdraws(20);

      setLastWithdraws(withdraws);
    };

    getPriceForChangeUsername();
    getCharacterIdForProfileIMG();
    getLastWithdraws();

    !userDataLoading && userData && setUsername(userData.username);
  }, [userDataLoading, userData]);

  useEffect(() => {
    setWalletPaired(userData?.externalWallet);
  }, [userData]);

  useEffect(() => {
    refetchAllowance();
  }, [blockNumber, refetchAllowance]);

  useEffect(() => {
    if (isApproveError) {
      toast.error(approveError.shortMessage);
    }

    if (isClaimPaymentError) {
      toast.error(claimPaymentError.shortMessage);
    }
  }, [approveError, isApproveError, claimPaymentError, isClaimPaymentError]);

  if (userDataLoading) {
    return (
      <div className={styles.loading}>
        <h1>Loading…</h1>
      </div>
    );
  }

  return (
    <>
      <Toaster />
      <div className={styles["profile-details"]}>
        <h1>Profile details</h1>
        <div className={styles.content}>
          <div className={styles.details}>
            <form>
              <div className={styles["form-item"]}>
                <label htmlFor="username">Username</label>

                <input
                  className={styles.input}
                  type="text"
                  placeholder="Enter username"
                  onChange={(e) => {
                    setUsername(e.target.value);
                    setIsChangeUsername(true);
                  }}
                  value={username}
                  minLength="4"
                  maxLength="14"
                />
              </div>

              {isChangeUsername ? (
                <button
                  type="button"
                  className={`see-detail-button ${styles["save-button"]}`}
                  onClick={() => setShowPriceAlertModal(true)}
                >
                  Save
                </button>
              ) : (
                <button
                  type="button"
                  className={`see-detail-button ${styles["save-button"]}`}
                >
                  Save
                </button>
              )}

              <div className={styles["form-item"]}>
                <label htmlFor="email">Email Address</label>
                <input
                  className={styles.input}
                  type="text"
                  placeholder="Enter email"
                  onChange={(e) => setEmailAddress(e.target.value)}
                  value={emailAddress}
                  disabled
                />
              </div>
            </form>

            <div className={styles.connections}>
              <h4>Connections</h4>

              <div className={styles["pair-wallet-container"]}>
                <h4 className="mt-3">Wallet paired</h4>
                {<p>{truncateETHAddress(walletPaired)}</p>}
              </div>

              <span>Connect your preferred wallet</span>
              <div className={styles["buttons-container"]}>
                <ArkerConnectButton />
                {isConnected && (
                  <>
                    {!userData.externalWallet ||
                    userData.externalWallet !== address ? (
                      <ArkerPairWalletButton
                        setNewWalletPaired={handleSetWalletPaired}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className={`mt-4 ${styles["approve-container"]}`}>
              <h2>
                {parseInt(allowance) <= 1000000
                  ? "Approve to use your ARKER in-game"
                  : "You can use your ARKER in-game"}
              </h2>

              {parseInt(allowance) <= 1000000 ? (
                loading ? (
                  <button
                    type="button"
                    className={`see-detail-button ${styles["save-button"]}`}
                  >
                    Loading…
                  </button>
                ) : (
                  <button
                    type="button"
                    className={`see-detail-button ${styles["save-button"]}`}
                    onClick={handleApproveARKER}
                  >
                    Approve
                  </button>
                )
              ) : (
                <button
                  type="button"
                  className={`see-detail-button ${styles["save-button"]} ${styles["already-approve-button"]}`}
                >
                  Already Approved
                </button>
              )}
            </div>
          </div>

          <div className={styles["profile-image"]}>
            <h4>
              Profile image{" "}
              <img
                title="Obtained from selected character in game"
                src="/assets/images/marketplace/icons/info-icon.svg"
                alt=""
              />
            </h4>

            <div className={styles["image-selected"]}>
              <img
                src={`/assets/images/marketplace/portrait/profile-icon-${
                  imageProfileIdLoading ? "1" : imageProfileId
                }.png`}
                alt=""
              />
            </div>

            {/* <div className={styles["image-selector"]}>
          {[...Array(profileIconsNum)].map((item, i) => {
            return (
              <div
                key={`profile-icon${i}`}
                className={styles["image-selector-item"]}
                onClick={() => setProfileIconSelected(i)}
              >
                <img
                  src={`/assets/images/marketplace/portrait/profile-icon-${i}.png`}
                  alt=""
                />
              </div>
            );
          })}
        </div> */}

            <hr />

            <div className={styles["withdraw-container"]}>
              <h2>Game balance</h2>
              <span>{userData.fragments / 10 ** 8} ARKER</span>

              {!userData.canWithdraw ? (
                <>
                  <p>Next available withdraw</p>
                  <span>
                    {moment(userData.availableWithdrawTime).format("LLLL")}
                  </span>
                </>
              ) : (
                <>
                  <p>Amount to withdraw</p>
                  <input
                    className={styles.input}
                    type="text"
                    placeholder="Enter ARKER amount…"
                    onChange={(e) => setAmountWithdraw(e.target.value)}
                    value={amountWithdraw}
                  />

                  {loading ? (
                    <button
                      type="button"
                      className={`mt-3 see-detail-button ${styles["save-button"]} ${styles["withdraw-button"]}`}
                    >
                      Loading…
                    </button>
                  ) : (
                    <button
                      type="button"
                      className={`mt-3 see-detail-button ${styles["save-button"]} ${styles["withdraw-button"]}`}
                      onClick={handleWithdraw}
                    >
                      Withdraw Now!
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <hr />

        <h2>Withdrawal history</h2>

        <div className={styles["table-container"]}>
          <table className={`mt-3 ${styles["withdraws-table"]}`}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {lastWithdraws?.slice(0, 20).map((withdraw, i) => {
                return withdraw.amount !== "0" ? (
                  <WithdrawTableItem
                    key={`withdraw-item${i}`}
                    withdraw={withdraw}
                    i={i}
                  />
                ) : null;
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        modalIsOpen={showPriceAlertModal}
        setIsOpen={setShowPriceAlertModal}
      >
        <div className={styles["modal-container"]}>
          <span className={styles["price-alert"]}>
            To change the username
            <br /> you have to pay {changeUsernamePrice} ARKER
          </span>

          <button
            type="button"
            className={`see-detail-button mt-4 ${styles["save-button"]} ${styles["accept-button"]}`}
            onClick={handleChangeUsername}
            disabled={loading}
          >
            {loading ? "Loading…" : "Accept"}
          </button>
          <button
            type="button"
            className={`see-detail-button ${styles["save-button"]} ${styles["cancel-button"]}`}
            onClick={() => setShowPriceAlertModal(false)}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};
