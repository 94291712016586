import { useEffect, useState } from "react";
import { useEggData } from "./useEggData";
import { getTypeName } from "../../services/pets";
import { getBlockNumber } from "../../services/utils";
import { useGetOnSale } from "../marketHooks/useGetOnSale";

const blockTimeInSec = 3;

export const useParseEggData = (eggId, saleId) => {
  //USESTATE VARIABLES
  const [name, setName] = useState();
  const [type, setType] = useState();
  const [canHatch, setCanHatch] = useState(false);
  const [hoursToHatch, setHoursToHatch] = useState();
  const [fatherData, setFatherData] = useState();
  const [motherData, setMotherData] = useState();

  const { onSale, onSaleLoading } = useGetOnSale(saleId);

  //EGGS HOOKS
  const { egg, eggLoading } = useEggData(eggId);

  useEffect(() => {
    if (egg && !eggLoading) {
      getBlockNumber().then((currentBlock) => {
        setCanHatch(parseInt(currentBlock) > parseInt(egg._targetBlock));

        if (parseInt(currentBlock) < parseInt(egg._targetBlock)) {
          const time =
            ((parseInt(egg._targetBlock) - parseInt(currentBlock)) *
              blockTimeInSec) /
            3600;

          if (time < 0.5) {
            setHoursToHatch("Less than 30 mins");
          } else if (time < 1) {
            setHoursToHatch("Less than one hour");
          } else {
            setHoursToHatch(time.toFixed(0) + "H to hatch");
          }
        }

        const petType = getTypeName(egg._fatherData._type);
        setType(petType);

        setName(`${petType.toUpperCase()} Egg#${eggId}`);

        setFatherData({ ...egg._fatherData, id: egg._fatherId });
        setMotherData({ ...egg._motherData, id: egg._motherId });
      });
    }
  }, [egg, eggLoading, eggId]);

  return {
    eggData: {
      name,
      type,
      canHatch,
      hoursToHatch,
      fatherData,
      motherData,
      onSale,
    },
    eggDataLoading: eggLoading || onSaleLoading,
  };
};
