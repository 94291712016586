import { useWriteContract } from "wagmi";

export const useApproveForAll = () => {
  const {
    writeContractAsync: approveForAllWrite,
    error: approveForAllError,
    isError: isApproveForAllError,
  } = useWriteContract();

  return { approveForAllWrite, approveForAllError, isApproveForAllError };
};
