import styles from "../items-slider.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PrevArrow } from "../../../../slick-slider-arrows/prev-arrow";
import { NextArrow } from "../../../../slick-slider-arrows/next-arrow";

export const WeaponsSlider = () => {
  const slickSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <NextArrow itemsSlider={true} />,
    prevArrow: <PrevArrow itemsSlider={true} />,
    responsive: [
      { breakpoint: 1520, settings: { slidesToShow: 4 } },
      { breakpoint: 1090, settings: { slidesToShow: 3 } },
      { breakpoint: 835, settings: { slidesToShow: 2 } },
      { breakpoint: 605, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <Slider {...slickSliderSettings}>
      <div className={styles["slick-slider-item"]}>
        <img src="/assets/images/items/weapons/ar-weapon-01.png" alt="" />
        <div className={styles["slick-slider-item-content"]}>
          <p>Pulser Dominus "Azur Cordis"</p>
        </div>
      </div>

      <div className={styles["slick-slider-item"]}>
        <img src="/assets/images/items/weapons/ar-weapon-02.png" alt="" />
        <div className={styles["slick-slider-item-content"]}>
          <p>Spiritum Spyr "Iridis Verite"</p>
        </div>
      </div>

      <div className={styles["slick-slider-item"]}>
        <img src="/assets/images/items/weapons/bz-weapon-01.png" alt="" />
        <div className={styles["slick-slider-item-content"]}>
          <p>Shell-Chain Edgeredder</p>
        </div>
      </div>

      <div className={styles["slick-slider-item"]}>
        <img src="/assets/images/items/weapons/bz-weapon-02.png" alt="" />
        <div className={styles["slick-slider-item-content"]}>
          <p>Massive Carnager</p>
        </div>
      </div>

      <div className={styles["slick-slider-item"]}>
        <img src="/assets/images/items/weapons/iz-weapon-01.png" alt="" />
        <div className={styles["slick-slider-item-content"]}>
          <p>Slong Klaw "Ish-Naqqar"</p>
        </div>
      </div>

      <div className={styles["slick-slider-item"]}>
        <img src="/assets/images/items/weapons/iz-weapon-02.png" alt="" />
        <div className={styles["slick-slider-item-content"]}>
          <p>Krescent Krests "S'hul-Shaar"</p>
        </div>
      </div>

      <div className={styles["slick-slider-item"]}>
        <img src="/assets/images/items/weapons/sh-weapon-01.png" alt="" />
        <div className={styles["slick-slider-item-content"]}>
          <p>Winger SLW Ravencross</p>
        </div>
      </div>

      <div className={styles["slick-slider-item"]}>
        <img src="/assets/images/items/weapons/sh-weapon-02.png" alt="" />
        <div className={styles["slick-slider-item-content"]}>
          <p>Anblast PLC Shadowing</p>
        </div>
      </div>

      <div className={styles["slick-slider-item"]}>
        <img src="/assets/images/items/weapons/was-weapon-01.png" alt="" />
        <div className={styles["slick-slider-item-content"]}>
          <p>"Sulfur Rain" Radsaw Guitark Hillaryous Jane Signature Series</p>
        </div>
      </div>

      <div className={styles["slick-slider-item"]}>
        <img src="/assets/images/items/weapons/was-weapon-02.png" alt="" />
        <div className={styles["slick-slider-item-content"]}>
          <p>"High Voltedge" Longblade Guitark Rusty Thorn Signature Series</p>
        </div>
      </div>
    </Slider>
  );
};
