import { useParsePetData } from "../../../../../../hooks/petsHooks/useParsePetData";
import { MarketplaceItemCharacteristics } from "../../../../marketplace-item-characteristics/marketplace-item-characteristics";
import styles from "./pet-selected-to-show.module.css";

export const PetSelectedToShowFooter = ({ id }) => {
  //PETS HOOKS
  const { petData, petDataLoading } = useParsePetData(id);

  if (petDataLoading) {
    return <h1>Loading…</h1>;
  }

  return (
    <div className={styles["pet-to-show-footer-container"]}>
      <p>{petData.name}</p>
      <MarketplaceItemCharacteristics
        rarity={petData.rarity}
        classType={"fheral"}
        genre={petData.genre ?? undefined}
        type={petData.type}
      />
    </div>
  );
};
