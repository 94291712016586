import { useReadContract } from "wagmi";
import marketABI from "../../assets/abis/marketplace.json";
import { getChainId, getMarketAddress } from "../../services/utils";

export const useIsSaleAvailable = (saleId) => {
  const { data: isSaleAvailable, isLoading: isSaleAvailableLoading } =
    useReadContract({
      abi: marketABI,
      address: getMarketAddress(),
      functionName: "isSaleAvailable",
      args: [saleId],
      chainId: getChainId(),
    });

  return { isSaleAvailable, isSaleAvailableLoading };
};
