import { useState } from "react";
import { MarketplaceItemPet } from "../../marketplace-item/marketplace-item-pet";
import styles from "../inventory.module.css";
import { useAccount } from "wagmi";
import { useTokensOfOwner } from "../../../../hooks/useTokensOfOwner";
import { getPetsAddress } from "../../../../services/pets";

export const InventoryFheralPets = () => {
  //USESTATE VARIABLES
  const [showFilters, setShowFilters] = useState(false);
  const [filterSelected, setFilterSelected] = useState("PRICE_LOW_TO_HIGH");
  const [viewSelected, setViewSelected] = useState("LIST");
  const [qtyPetsToShow, setQtyPetsToShow] = useState(10);

  //WAGMI HOOKS
  const { address } = useAccount();

  //TOKENS HOOKS
  const { tokensOfOwner, tokensOfOwnerLoading } = useTokensOfOwner(
    getPetsAddress()
  );

  return (
    <>
      <div className={styles.header}>
        <div className={styles["category-items"]}>
          <h1>Fherals / pets</h1>
          <span>{tokensOfOwner?.length ?? 0} items</span>
        </div>
        {/* <div className={styles["right-items"]}>
          <div
            className={styles["filters"]}
            onClick={() => setShowFilters(!showFilters)}
          >
            <span className={styles["filters-text"]}>
              {filterSelected === "PRICE_LOW_TO_HIGH" && "Price low to high"}
              {filterSelected === "PRICE_HIGH_TO_LOW" && "Price high to low"}
            </span>
            <img
              className={`${styles.arrow} ${
                showFilters && styles["arrow-open"]
              }`}
              src="/assets/images/icons/bottom-arrow-icon-white.svg"
              alt=""
            />
            {showFilters && (
              <div className={styles["filters-dropdown"]}>
                <ul>
                  <li onClick={() => setFilterSelected("PRICE_LOW_TO_HIGH")}>
                    Price low to high
                  </li>
                  <li onClick={() => setFilterSelected("PRICE_HIGH_TO_LOW")}>
                    Price high to low
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div className={styles["select-view-container"]}>
            <div
              className={`${styles["select-view-item"]} ${
                viewSelected === "LIST" && styles["active"]
              }`}
              onClick={() => setViewSelected("LIST")}
            >
              <img
                className={styles["list"]}
                src={`/assets/images/icons/list-items${
                  viewSelected === "LIST" ? "-selected" : ""
                }.svg`}
                alt=""
              />
            </div>
            <div
              className={`${styles["select-view-item"]} ${
                viewSelected === "GRID" && styles["active"]
              }`}
              onClick={() => setViewSelected("GRID")}
            >
              <img
                src={`/assets/images/icons/normal-view-list${
                  viewSelected === "GRID" ? "-selected" : ""
                }.svg`}
                alt=""
              />
            </div>
          </div>
        </div> */}
      </div>

      <div className={styles.content}>
        <div className="row">
          {!address ? (
            <h1 className="text-center">Wallet not connected</h1>
          ) : !tokensOfOwnerLoading ? (
            tokensOfOwner?.length > 0 ? (
              tokensOfOwner.slice(0, qtyPetsToShow).map((pet, i) => {
                return (
                  <MarketplaceItemPet
                    key={`-inventory-pets${i}`}
                    id={parseInt(pet)}
                    inventory={true}
                    tokenId={parseInt(pet)}
                  />
                );
              })
            ) : (
              <h1 className="text-center">You don't have any pets</h1>
            )
          ) : (
            <h1 className="text-center">Loading…</h1>
          )}
        </div>

        {tokensOfOwner?.length >
          tokensOfOwner?.slice(0, qtyPetsToShow).length && (
          <button
            className={`load-more-button ${styles["load-more"]}`}
            onClick={() => setQtyPetsToShow(qtyPetsToShow + 5)}
          >
            Load More
          </button>
        )}
      </div>
    </>
  );
};
