import styles from "./pet-stats.module.css";

export const PetStats = ({ petData }) => {
  return (
    <div className={styles["status-container"]}>
      <div className={`${styles["status-item"]} ${styles["odd"]}`}>
        <span>Attack</span>
        <span>{petData.attack}</span>
      </div>
      <div className={`${styles["status-item"]}`}>
        <span>Life</span>
        <span>{petData.life}</span>
      </div>
      <div className={`${styles["status-item"]} ${styles["odd"]}`}>
        <span>Agility</span>
        <span>{petData.agility}</span>
      </div>
      <div className={`${styles["status-item"]}`}>
        <span>Power</span>
        <span>{petData.power}</span>
      </div>
      <div className={`${styles["status-item"]} ${styles["odd"]}`}>
        <span>Energy</span>
        <span>{petData.energy}</span>
      </div>
    </div>
  );
};
