import { useWriteContract } from "wagmi";

export const useBreed = () => {
  const {
    writeContractAsync: breedWrite,
    error: breedError,
    isError: isBreedError,
  } = useWriteContract();

  return { breedWrite, breedError, isBreedError };
};
