import { useEffect, useState } from "react";
import { Modal } from "../../../../Modal";
import styles from "./inventory-fheral-breeding.module.css";
import { useTokensOfOwner } from "../../../../../hooks/useTokensOfOwner";
import { SelectPetItem } from "./select-pet-item/select-pet-item";
import { PetSelectedToShow } from "./pet-selected-to-show/pet-selected-to-show";
import { PetSelectedToShowFooter } from "./pet-selected-to-show/pet-selected-to-show-footer";
import { useMaxBreeds } from "../../../../../hooks/eggsHooks/useMaxBreeds";
import { useBreedArkerFees } from "../../../../../hooks/eggsHooks/useBreedArkerFees";
import { useBreedCount } from "../../../../../hooks/eggsHooks/useBreedCount";
import { useParsePetData } from "../../../../../hooks/petsHooks/useParsePetData";
import { erc20Abi, formatEther } from "viem";
import toast from "react-hot-toast";
import { useBreed } from "../../../../../hooks/eggsHooks/useBreed";
import { getEggsAddress } from "../../../../../services/eggs";
import {
  MAX_INT,
  getArkerTokenAddress,
  getChainId,
} from "../../../../../services/utils";
import { useAccount, useBlockNumber } from "wagmi";
import eggsABI from "../../../../../assets/abis/breeder.json";
import { useAllowance } from "../../../../../hooks/tokenHooks/useAllowance";
import { useApprove } from "../../../../../hooks/tokenHooks/useApprove";
import { useNavigate } from "react-router-dom";
import { getPetsAddress } from "../../../../../services/pets";
import { PetStats } from "./pet-stats/pet-stats";

export const InventoryFheralBreeding = () => {
  //LOCATION VARIABLES
  const navigate = useNavigate();

  //WAGMI HOOKS
  const { address } = useAccount();
  const { data: blockNumber } = useBlockNumber({ watch: true });

  //USESTATE VARIABLES
  const [male, setMale] = useState(false);
  const [malePetSelected, setMalePetSelected] = useState();
  const [maleTypePetSelected, setMaleTypePetSelected] = useState();
  const [femalePetSelected, setFemalePetSelected] = useState();
  const [femaleTypePetSelected, setFemaleTypePetSelected] = useState();
  const [openSelectFheralModal, setOpenSelectFheralModal] = useState(false);
  const [loadingWrite, setLoadingWrite] = useState(false);

  //TOKENS HOOKS
  const { tokensOfOwner, tokensOfOwnerLoading } = useTokensOfOwner(
    getPetsAddress()
  );
  const { allowance, refetchAllowance } = useAllowance(getEggsAddress());
  const { approveWrite, approveError, isApproveError } = useApprove();

  //EGGS HOOKS
  const { maxBreeds, maxBreedsLoading } = useMaxBreeds();
  const {
    breedCount: breedCountMale,
    breedCountLoading: breedCountMaleLoading,
  } = useBreedCount(malePetSelected);
  const {
    breedCount: breedCountFemale,
    breedCountLoading: breedCountFemaleLoading,
  } = useBreedCount(femalePetSelected);
  const { arkerCost, arkerCostLoading } = useBreedArkerFees(breedCountFemale);
  const { breedWrite, breedError, isBreedError } = useBreed();

  //PETS HOOKS
  const { petData: malePetData, petDataLoading: malePetDataLoading } =
    useParsePetData(malePetSelected);
  const { petData: femalePetData, petDataLoading: femalePetDataLoading } =
    useParsePetData(femalePetSelected);

  const handleBreedingNow = async () => {
    setLoadingWrite(true);

    if (!malePetSelected) {
      toast.error("Select a male pet");
      setLoadingWrite(false);
      return;
    }

    if (!femalePetSelected) {
      toast.error("Select a female pet");
      setLoadingWrite(false);
      return;
    }

    if (breedCountMale >= maxBreeds && breedCountFemale >= maxBreeds) {
      toast.error("Reached maximum breed for both pets");
      setLoadingWrite(false);
      return;
    }

    if (breedCountMale >= maxBreeds) {
      toast.error("Reached maximum breed for male pet");
      setLoadingWrite(false);
      return;
    }

    if (breedCountFemale >= maxBreeds) {
      toast.error("Reached maximum breed for female pet");
      setLoadingWrite(false);
      return;
    }

    try {
      await breedWrite({
        abi: eggsABI,
        address: getEggsAddress(),
        functionName: "breed",
        args: [malePetSelected, femalePetSelected, address],
        chainId: getChainId(),
      });

      toast.success("Breed Successfully!");
      navigate(`/inventory/fheral/eggs`);

      setLoadingWrite(false);
    } catch (error) {
      console.log(error);
      setLoadingWrite(false);
    }
  };

  const handleApprove = async () => {
    setLoadingWrite(true);
    try {
      await approveWrite({
        abi: erc20Abi,
        address: getArkerTokenAddress(),
        functionName: "approve",
        args: [getEggsAddress(), MAX_INT],
        chainId: getChainId(),
      });
      toast.success("Approve ARKER Successfully!");
      setLoadingWrite(false);
    } catch (error) {
      console.log(error);
      setLoadingWrite(false);
    }
  };

  useEffect(() => {
    refetchAllowance();
  }, [blockNumber, refetchAllowance]);

  useEffect(() => {
    if (isBreedError) {
      toast.error(breedError.shortMessage);
    }
    if (isApproveError) {
      toast.error(approveError.shortMessage);
    }
  }, [breedError, isBreedError, approveError, isApproveError]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles["male-container"]}>
          <div className={styles["fheral-box"]}>
            <div className={styles["box-header"]}>
              <img
                src="/assets/images/marketplace/icons/male-fheral.svg"
                alt=""
              />
              <p>MALE FHERAL</p>
            </div>
            {malePetSelected ? (
              <>
                <div
                  className={styles["box-content-selected"]}
                  onClick={() => {
                    setOpenSelectFheralModal(true);
                    setMale(true);
                  }}
                >
                  <PetSelectedToShow id={malePetSelected} />
                </div>
                <div className={styles["box-footer"]}>
                  <PetSelectedToShowFooter id={malePetSelected} />
                </div>
              </>
            ) : (
              <div
                className={styles["box-content"]}
                onClick={() => {
                  setOpenSelectFheralModal(true);
                  setMale(true);
                }}
              >
                <img
                  src="/assets/images/marketplace/icons/select-pet-icon.svg"
                  alt=""
                />
                <p>Select a pet</p>
              </div>
            )}
          </div>

          {malePetSelected && (
            <>
              <span
                className={`load-more-button ${styles["breeds-count-label"]}`}
              >
                <strong>BREEDS</strong>{" "}
                {maxBreedsLoading || breedCountMaleLoading
                  ? "Loading…"
                  : `${breedCountMale}/${maxBreeds}`}
              </span>

              <p className={styles.status}>
                Status {malePetDataLoading ? "Loading…" : malePetData.name}
              </p>

              <PetStats petData={malePetData} />
            </>
          )}
        </div>

        <div className={styles["breeding-main-content"]}>
          <div className={styles["egg-container"]}>
            {femalePetSelected && malePetSelected ? (
              <>
                {femalePetData.type === "crab" &&
                  malePetData.type === "crab" && (
                    <img
                      src="/assets/images/marketplace/eggs/crab.png"
                      alt=""
                    />
                  )}

                {femalePetData.type === "wolf" &&
                  malePetData.type === "wolf" && (
                    <img
                      src="/assets/images/marketplace/eggs/wolf.png"
                      alt=""
                    />
                  )}

                {femalePetData.type === "tiger" &&
                  malePetData.type === "tiger" && (
                    <img
                      src="/assets/images/marketplace/eggs/tiger.png"
                      alt=""
                    />
                  )}

                {femalePetData.type === "phoenix" &&
                  malePetData.type === "phoenix" && (
                    <img
                      src="/assets/images/marketplace/eggs/phoenix.png"
                      alt=""
                    />
                  )}

                {femalePetData.type === "beetle" &&
                  malePetData.type === "beetle" && (
                    <img
                      src="/assets/images/marketplace/eggs/beetle.png"
                      alt=""
                    />
                  )}
              </>
            ) : (
              <img
                src="/assets/images/marketplace/eggs/shadow-egg.svg"
                alt=""
              />
            )}
          </div>

          <div className={styles["price-container"]}>
            <span>Current Price:</span>
            <p>
              {!femalePetSelected || !malePetSelected ? (
                <span>
                  <img src="/assets/images/icons/arker-icon.svg" alt="" />0
                  ARKER
                </span>
              ) : (
                <span>
                  <img src="/assets/images/icons/arker-icon.svg" alt="" />
                  {arkerCostLoading ? (
                    "Loading…"
                  ) : (
                    <>{formatEther(arkerCost ?? 0n) ?? "0"} ARKER</>
                  )}
                </span>
              )}
            </p>
          </div>

          {allowance < arkerCost ? (
            <button onClick={handleApprove} className={`button-green`}>
              {loadingWrite ? "Loading…" : <>Approve</>}
            </button>
          ) : (
            <button onClick={handleBreedingNow} className={`button-green`}>
              {loadingWrite ? (
                "Loading…"
              ) : (
                <>
                  <img src="/assets/images/icons/Heart-icon-black.svg" alt="" />
                  Breeding now!
                </>
              )}
            </button>
          )}
        </div>

        <div className={styles["female-container"]}>
          <div className={styles["fheral-box"]}>
            <div className={styles["box-header"]}>
              <img
                src="/assets/images/marketplace/icons/female-fheral.svg"
                alt=""
              />
              <p>FEMALE FHERAL</p>
            </div>
            {femalePetSelected ? (
              <>
                <div
                  className={styles["box-content-selected"]}
                  onClick={() => {
                    setOpenSelectFheralModal(true);
                    setMale(false);
                  }}
                >
                  <PetSelectedToShow id={femalePetSelected} />
                </div>
                <div className={styles["box-footer"]}>
                  <PetSelectedToShowFooter id={femalePetSelected} />
                </div>
              </>
            ) : (
              <div
                className={styles["box-content"]}
                onClick={() => {
                  setOpenSelectFheralModal(true);
                  setMale(false);
                }}
              >
                <img
                  src="/assets/images/marketplace/icons/select-pet-icon.svg"
                  alt=""
                />
                <p>Select a pet</p>
              </div>
            )}
          </div>

          {femalePetSelected && (
            <>
              <span
                className={`load-more-button ${styles["breeds-count-label"]}`}
              >
                <strong>BREEDS</strong>{" "}
                {maxBreedsLoading || breedCountFemaleLoading
                  ? "Loading…"
                  : `${breedCountFemale}/${maxBreeds}`}
              </span>

              <p className={styles.status}>
                Status {femalePetDataLoading ? "Loading…" : femalePetData.name}
              </p>
              <PetStats petData={femalePetData} />
            </>
          )}
        </div>
      </div>
      <Modal
        modalIsOpen={openSelectFheralModal}
        setIsOpen={setOpenSelectFheralModal}
      >
        <div className={styles["modal-container"]}>
          {" "}
          <h1 className={styles["select-pet-title"]}>
            {!address ? "Wallet not connected" : "Select a pet"}
          </h1>
          {!address ? (
            <h2 className="mt-4">Connect wallet first!</h2>
          ) : !tokensOfOwnerLoading ? (
            <div className={styles["select-pet-item-container"]}>
              {tokensOfOwner.map((item) => {
                return (
                  <SelectPetItem
                    key={`select-pet-item${item.toString()}`}
                    id={parseInt(item)}
                    male={male}
                    malePetSelected={malePetSelected}
                    setMalePetSelected={setMalePetSelected}
                    femalePetSelected={femalePetSelected}
                    setFemalePetSelected={setFemalePetSelected}
                    maleTypePetSelected={maleTypePetSelected}
                    setMaleTypePetSelected={setMaleTypePetSelected}
                    femaleTypePetSelected={femaleTypePetSelected}
                    setFemaleTypePetSelected={setFemaleTypePetSelected}
                  />
                );
              })}
            </div>
          ) : (
            <img
              className="mt-4"
              width={35}
              alt="Loading pet item"
              src="/assets/images/icons/loading.svg"
            />
          )}
          <button
            onClick={() => setOpenSelectFheralModal(false)}
            className={`see-detail-button ${styles["close-button"]}`}
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};
