import { useReadContract } from "wagmi";
import { getEggsAddress } from "../../services/eggs";
import { getChainId } from "../../services/utils";
import eggsABI from "../../assets/abis/breeder.json";

export const useEggData = (eggId) => {
  const { data: egg, isLoading: eggLoading } = useReadContract({
    abi: eggsABI,
    address: getEggsAddress(),
    functionName: "getEggData",
    args: [eggId],
    chainId: getChainId(),
  });

  return { egg, eggLoading };
};
