/* eslint-disable jsx-a11y/anchor-has-content */
import styles from "./footer.module.css";

export const Footer = () => {
  return (
    <footer>
      <div className={styles.footer}>
        <div className={styles["footer-content"]}>
          <h2 className={styles["footer-title"]}>
            ARE YOU READY FOR THE FIGHT?
          </h2>
          <p className={styles["footer-text"]}>
            Still thinking about it? The Ohm world needs new heroes to protect
            it and it’s precious resources. Download it now for free, create
            your first hero, open your first cards and start fighting right now.
          </p>
        </div>
        <button
          className={`button-green button-green-small ${styles["play-free"]}`}
        >
          <a href="/account-settings/download">Play arker</a>
        </button>

        <div className={styles["footer-links"]}>
          <ul>
            <li>
              <a
                href="https://docs.playarker.com/"
                target="_blank"
                rel="noreferrer"
              >
                docs
              </a>
            </li>
            <li>
              <a href="/marketplace">marketplace</a>
            </li>
            <li>
              <a
                href="https://docs.playarker.com/"
                target="_blank"
                rel="noreferrer"
              >
                how to play
              </a>
            </li>
            <li>
              <a href="/assets/docs/whitepaper.pdf" target="_blank">
                whitepaper
              </a>
            </li>
            {/* <li>pet fight</li> */}
            {/* <li>support</li> */}
          </ul>
          <ul>
            <li>
              <a href="https://medium.com/@arker-official">medium</a>
            </li>
            <li>
              <a
                href="https://github.com/Arker-The-legend-of-Ohm"
                target="_blank"
                rel="noreferrer"
              >
                github
              </a>
            </li>
            <li>
              <a
                href="https://t.me/ArkerOfficial"
                target="_blank"
                rel="noreferrer"
              >
                news
              </a>
            </li>
            <li>
              <a
                href="https://skynet.certik.com/projects/arker"
                target="_blank"
                rel="noreferrer"
              >
                certik audit
              </a>
            </li>
            <li>
              <a href="https://app.playarker.com/privacy">privacy & policies</a>
            </li>
          </ul>
        </div>

        <img
          className={styles["footer-logo"]}
          src="/assets/images/logos/logo-arker.png"
          alt=""
        />

        <div className={styles["sub-footer"]}>
          <p>Arker the legend of ohm - all rights reserved 2024</p>
          <ul>
            <li>
              <a
                href="https://t.me/ArkerCommunity"
                target="_blank"
                rel="noreferrer"
                className={styles["telegram"]}
              ></a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@ArkerOfficial"
                target="_blank"
                rel="noreferrer"
                className={styles["youtube"]}
              ></a>
            </li>
            <li>
              <a
                href="https://twitter.com/ArkerCommunity"
                target="_blank"
                rel="noreferrer"
                className={styles["twitter"]}
              ></a>
            </li>
            <li>
              <a
                href="https://github.com/Arker-The-legend-of-Ohm"
                target="_blank"
                rel="noreferrer"
                className={styles["github"]}
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};
