import { useReadContract } from "wagmi";
import eggsABI from "../../assets/abis/breeder.json";
import { getEggsAddress } from "../../services/eggs";
import { getChainId } from "../../services/utils";

export const useBreedCount = (petId) => {
  const { data: breedCount, isLoading: breedCountLoading } = useReadContract({
    abi: eggsABI,
    functionName: "_breedCount",
    address: getEggsAddress(),
    chainId: getChainId(),
    args: [petId],
  });

  return { breedCount, breedCountLoading };
};
