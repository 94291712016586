import { ConnectButton } from "@rainbow-me/rainbowkit";
import styles from "../buttons.module.css";
import { useAccount, useDisconnect } from "wagmi";

import { formatUnits } from "viem";
import { useGetArkerBalance } from "../../../../hooks/useGetArkerBalance";

export const ArkerConnectButton = ({ header }) => {
  /* WAGMI HOOKS */
  const { disconnect } = useDisconnect();
  const { address } = useAccount();

  //USER HOOKS
  const { arkerBalance, arkerBalanceLoading } = useGetArkerBalance();

  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openChainModal,
        openAccountModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");

        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                localStorage.removeItem("address.connected");
                return (
                  <button
                    onClick={openConnectModal}
                    className={`${
                      header
                        ? styles["account-button-header"]
                        : styles["account-button"]
                    }`}
                    type="button"
                  >
                    Connect wallet
                  </button>
                );
              } else {
                localStorage.setItem("address.connected", address);
              }

              if (chain.unsupported) {
                return (
                  <button
                    className={`${
                      header
                        ? styles["account-button-header"]
                        : styles["account-button"]
                    }`}
                    onClick={openChainModal}
                    type="button"
                  >
                    Wrong network
                  </button>
                );
              }

              if (header) {
                return (
                  <div
                    className={styles["account-button-header"]}
                    onClick={openAccountModal}
                  >
                    <img src="/assets/images/icons/arker-icon.svg" alt="" />
                    {arkerBalanceLoading
                      ? "Loading…"
                      : `${
                          parseFloat(
                            formatUnits(
                              arkerBalance?.value,
                              arkerBalance?.decimals
                            )
                          ).toFixed(2) ?? "0"
                        } ARKER`}
                  </div>
                );
              } else {
                return (
                  <div
                    style={{
                      display: "flex",
                      gap: 12,
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <button
                      className={`${styles["account-button"]}`}
                      onClick={disconnect}
                      type="button"
                    >
                      Disconnect wallet
                    </button>
                  </div>
                );
              }
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};
