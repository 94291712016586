import { ChampionsSlider } from "./champions-slider/champions-slider";
import { RelicsSlider } from "./items-slider/relics-slider/relics-slider";
import { ArkanesSlider } from "./items-slider/arkanes-slider/arkanes-slider";
import { PetsSlider } from "./items-slider/pets-slider/pets-slider";
import { SkinsSlider } from "./items-slider/skins-slider/skins-slider";
import { WeaponsSlider } from "./items-slider/weapons-slider/weapons-slider";
import styles from "./sliders-section.module.css";
import { useState } from "react";

export const SlidersSection = () => {
  const [sliderSelected, setSliderSelected] = useState("RELICS");

  return (
    <div className={styles["sliders-section"]}>
      <div className={styles.champions}>
        <h3 className={styles["champions-section-header"]}>
          CHOOSE YOUR CHAMPIONS
        </h3>
        <img
          className="divider-s"
          src="/assets/images/elements/home-divider.svg"
          alt=""
        />
        <p className={styles["champions-section-text"]}>
          Who will you choose for your journey? Each champion has different
          basic stats and different fight styles.
        </p>
        <div className={styles["champions-slider"]}>
          <ChampionsSlider />
        </div>
      </div>

      <div className={styles.items}>
        <ul className="center">
          <li
            className={sliderSelected === "RELICS" ? styles.active : undefined}
            onClick={() => setSliderSelected("RELICS")}
          >
            RELICS
          </li>
          <li
            className={sliderSelected === "WEAPONS" ? styles.active : undefined}
            onClick={() => setSliderSelected("WEAPONS")}
          >
            WEAPONS
          </li>
          <li
            className={sliderSelected === "SKINS" ? styles.active : undefined}
            onClick={() => setSliderSelected("SKINS")}
          >
            SKINS
          </li>
          <li
            className={sliderSelected === "FHERALS" ? styles.active : undefined}
            onClick={() => setSliderSelected("FHERALS")}
          >
            FHERALS
          </li>
          <li
            className={sliderSelected === "ARKANES" ? styles.active : undefined}
            onClick={() => setSliderSelected("ARKANES")}
          >
            ARKANES
          </li>
        </ul>
        <div className={styles["items-slider"]}>
          {sliderSelected === "RELICS" && <RelicsSlider />}
          {sliderSelected === "WEAPONS" && <WeaponsSlider />}
          {sliderSelected === "SKINS" && <SkinsSlider />}
          {sliderSelected === "FHERALS" && <PetsSlider />}
          {sliderSelected === "ARKANES" && <ArkanesSlider />}
        </div>

        <button
          className={`button-green button-green-small ${styles["play-button"]}`}
        >
          <a href="/account-settings/download">Play free</a>
        </button>
      </div>
    </div>
  );
};
