import { useReadContract } from "wagmi";
import { getChainId, getMarketAddress } from "../../services/utils";
import marketABI from "../../assets/abis/marketplace.json";

export const useGetSale = (saleId) => {
  const { data: sale, isLoading: saleLoading } = useReadContract({
    abi: marketABI,
    functionName: "sales",
    address: getMarketAddress(),
    chainId: getChainId(),
    args: [saleId],
  });

  return {
    sale,
    saleLoading,
  };
};
