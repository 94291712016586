import { useEffect, useState } from "react";
import styles from "./blog-posts.module.css";
import { BlogPostCard } from "./blog-post-card/blog-post-card";
import { BlogPostCardSkeleton } from "./blog-post-card/blog-post-card-skeleton/blog-post-card-skeleton";
import { getMediumPosts } from "../../../../services/medium";

export const BlogPosts = ({ category }) => {
  const [posts, setPosts] = useState([]);
  const [isLoadingPosts, setIsLoadingPosts] = useState(true);
  /* const [blogCategorySelected, setBlogCategorySelected] = useState("ALL"); */

  useEffect(() => {
    //CALL TO GET BLOG POSTS BY CATEGORY
    getMediumPosts().then((items) => {
      if (items === "ERROR") {
        setPosts([1, 2, 3]);
      } else {
        setPosts(items);
        setIsLoadingPosts(false);
      }
    });
  }, []);

  return (
    <>
      {/* <ul className={styles["blog-post-selector-container"]}>
        <li
          className={blogCategorySelected === "ALL" && styles.active}
          onClick={() => setBlogCategorySelected("ALL")}
        >
          All
        </li>
        <li
          className={blogCategorySelected === "NEWS" && styles.active}
          onClick={() => setBlogCategorySelected("NEWS")}
        >
          news
        </li>
        <li
          className={blogCategorySelected === "METAGAME" && styles.active}
          onClick={() => setBlogCategorySelected("METAGAME")}
        >
          metagame
        </li>
        <li
          className={blogCategorySelected === "GAME_UPDATES" && styles.active}
          onClick={() => setBlogCategorySelected("GAME_UPDATES")}
        >
          game updates
        </li>
      </ul> */}
      <p>{isLoadingPosts.toString()}</p>
      <div className={styles["posts-container"]}>
        {isLoadingPosts ? (
          <>
            {Array.from([1, 2, 3]).map((item, i) => {
              return <BlogPostCardSkeleton key={`skeleton-post-${i}`} />;
            })}
          </>
        ) : (
          <>
            {posts.map((post) => {
              return (
                <BlogPostCard
                  key={post.guid}
                  title={post.title}
                  img={post.thumbnail}
                  text={post.content}
                  href={post.link}
                />
              );
            })}
          </>
        )}
      </div>
    </>
  );
};
