import { useWriteContract } from "wagmi";

export const useApprove = () => {
  const {
    writeContractAsync: approveWrite,
    error: approveError,
    isError: isApproveError,
  } = useWriteContract();

  return { approveWrite, approveError, isApproveError };
};
