import { Link, Outlet, useLocation } from "react-router-dom";
import { MarketplaceHeader } from "../../header/marketplace-header/marketplace-header";
import { MarketplaceFooter } from "../../footer/marketplace-footer/marketplace-footer";
import styles from "./inventory-layout.module.css";
import { useState } from "react";
import { useUserData } from "../../../hooks/useUserData";
import toast, { Toaster } from "react-hot-toast";
import { truncateETHAddress } from "../../../services/utils";

export const InventoryLayout = () => {
  //LOCATION VARIABLES
  const location = useLocation();

  //USER HOOKS
  const { userData, userDataLoading } = useUserData();

  //USESTATE VARIABLES
  const [sidebarItemSelected, setSidebarItemSelected] = useState(
    location.pathname.split("/")[2].toUpperCase()
  );
  const [sidebarFheralItemSelected, setSidebarFheralItemSelected] = useState(
    location.pathname.split("/")[3]
      ? location.pathname.split("/")[3].toUpperCase()
      : ""
  );
  const [showFherals, setShowFherals] = useState(
    location.pathname.split("/")[3] ? true : false
  );

  const handleSetSidebarItemSelected = (item) => {
    setSidebarItemSelected(item);
    setSidebarFheralItemSelected("");
    if (item !== "FHERAL") {
      setShowFherals(false);
    }
  };

  const handleSetSidebarFheralItemSelected = (item) => {
    setSidebarFheralItemSelected(item);
  };

  return (
    <>
      <Toaster />
      <MarketplaceHeader />

      <div className={`${styles.content}`}>
        <div className="marketplace-container">
          <div className={styles.sidebar}>
            <img
              src="/assets/images/marketplace/portrait/profile-icon-5.png"
              alt=""
            />
            <h4>{userDataLoading ? "Loading…" : userData.username}</h4>
            <div
              className={styles["address-container"]}
              onClick={() => {
                if (userData?.externalWallet) {
                  navigator.clipboard.writeText(userData?.externalWallet);
                  toast.success("Address copied to clipboard");
                }
              }}
            >
              <img
                src="/assets/images/marketplace/icons/copy-paste.svg"
                alt=""
              />
              <span>
                {truncateETHAddress(userData?.externalWallet) ??
                  "NO PAIRED WALLET"}
              </span>
            </div>

            <ul>
              {/* ALL */}
              <li
                onClick={() => handleSetSidebarItemSelected("ALL")}
                className={`${sidebarItemSelected === "ALL" && styles.active}`}
              >
                <div>
                  <img src="/assets/images/icons/inventory.svg" alt="" />
                </div>
                <Link to={"/inventory/all"}>Inventory</Link>
              </li>

              {/* ARKANES */}
              {/* <li
                onClick={() => handleSetSidebarItemSelected("ARKANES")}
                className={`${
                  sidebarItemSelected === "ARKANES" && styles.active
                }`}
              >
                <div>
                  <img
                    className={styles.arkanes}
                    src="/assets/images/marketplace/inventory/icons/arkane.svg"
                    alt=""
                  />
                </div>
                <Link to={"/inventory/arkanes"}>Arkanes</Link>
              </li> */}

              {/* SKINS */}
              {/*  <li
                onClick={() => handleSetSidebarItemSelected("SKINS")}
                className={`${
                  sidebarItemSelected === "SKINS" && styles.active
                }`}
              >
                <div>
                  <img
                    className={styles.skins}
                    src="/assets/images/marketplace/inventory/icons/skin.svg"
                    alt=""
                  />
                </div>
                <Link to={"/inventory/skins"}>Skins</Link>
              </li> */}

              {/* WEAPONS */}
              {/* <li
                onClick={() => handleSetSidebarItemSelected("WEAPONS")}
                className={`${
                  sidebarItemSelected === "WEAPONS" && styles.active
                }`}
              >
                <div>
                  <img
                    className={styles.weapons}
                    src="/assets/images/marketplace/inventory/icons/weapon.svg"
                    alt=""
                  />
                </div>
                <Link to={"/inventory/weapons"}>Weapons</Link>
              </li> */}

              {/* RELICS */}
              {/* <li
                onClick={() => handleSetSidebarItemSelected("RELICS")}
                className={`${
                  sidebarItemSelected === "RELICS" && styles.active
                }`}
              >
                <div>
                  <img
                    className={styles.relics}
                    src="/assets/images/marketplace/inventory/icons/relic.svg"
                    alt=""
                  />
                </div>
                <Link to={"/inventory/relics"}>Relics</Link>
              </li> */}

              {/* CHESTS */}
              {/* <li
                onClick={() => handleSetSidebarItemSelected("CHESTS")}
                className={`${
                  sidebarItemSelected === "CHESTS" && styles.active
                }`}
              >
                <div>
                  <img
                    className={styles.chests}
                    src="/assets/images/marketplace/inventory/icons/chest.svg"
                    alt=""
                  />
                </div>
                <Link to={"/inventory/chests"}>Chests</Link>
              </li> */}

              {/* FHERAL */}
              <li
                onClick={() => {
                  handleSetSidebarItemSelected("FHERAL");
                  setShowFherals(true);
                }}
                className={`${
                  sidebarItemSelected === "FHERAL" && styles.active
                }`}
              >
                <div>
                  <img
                    className={styles.fheral}
                    src="/assets/images/marketplace/inventory/icons/fheral.svg"
                    alt=""
                  />
                </div>
                Fheral{" "}
                <img
                  className={styles.arrow}
                  src="/assets/images/icons/bottom-arrow-icon-green.svg"
                  alt=""
                />
              </li>
              <div
                className={`${styles["fherals-dropdown"]} ${
                  showFherals && styles["show-fherals-dropdown"]
                }`}
              >
                <ul>
                  <li
                    onClick={() => handleSetSidebarFheralItemSelected("PETS")}
                    className={`${
                      sidebarFheralItemSelected === "PETS" && styles.active
                    }`}
                  >
                    <Link to={"/inventory/fheral/pets"}>Pets</Link>
                  </li>
                  <li
                    onClick={() => handleSetSidebarFheralItemSelected("EGGS")}
                    className={`${
                      sidebarFheralItemSelected === "EGGS" && styles.active
                    }`}
                  >
                    <Link to={"/inventory/fheral/eggs"}>Eggs</Link>
                  </li>
                  <li
                    onClick={() =>
                      handleSetSidebarFheralItemSelected("BREEDING")
                    }
                    className={`${
                      sidebarFheralItemSelected === "BREEDING" && styles.active
                    }`}
                  >
                    <Link to={"/inventory/fheral/breeding"}>Breeding</Link>
                  </li>
                  {/* <li
                    onClick={() =>
                      handleSetSidebarFheralItemSelected("PET-FIGHT")
                    }
                    className={`${
                      sidebarFheralItemSelected === "PET-FIGHT" && styles.active
                    }`}
                  >
                    <Link to={"/inventory/fheral/pet-fight"}>Pet fight</Link>
                  </li> */}
                </ul>
              </div>

              {/* ARCHIVEMENTS */}
              {/* <li
                onClick={() => handleSetSidebarItemSelected("ARCHIVEMENTS")}
                className={`${
                  sidebarItemSelected === "ARCHIVEMENTS" && styles.active
                }`}
              >
                <div>
                  <img
                    className={styles.achivements}
                    src="/assets/images/marketplace/inventory/icons/achivements.svg"
                    alt=""
                  />
                </div>
                <Link to={"/inventory/Achivements"}>Achivements</Link>
              </li> */}

              {/* WISHLIST */}
              {/* <li
                onClick={() => handleSetSidebarItemSelected("WISHLIST")}
                className={`${
                  sidebarItemSelected === "WISHLIST" && styles.active
                }`}
              >
                <div>
                  <img
                    className={styles.wishlist}
                    src="/assets/images/icons/wishlist.svg"
                    alt=""
                  />
                </div>
                <Link to={"/inventory/wishlist"}>Wishlist</Link>
              </li> */}

              {/* COMMUNITY */}
              <li>
                <div>
                  <img
                    className={styles.community}
                    src="/assets/images/marketplace/inventory/icons/community.svg"
                    alt=""
                  />
                </div>
                <Link
                  to={"https://t.me/ArkerCommunity"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Community
                </Link>
              </li>
            </ul>
          </div>

          <div className={styles.outlet}>
            <Outlet />
          </div>
        </div>
      </div>
      <MarketplaceFooter />
    </>
  );
};
