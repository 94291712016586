/* eslint-disable no-undef */
import { formatUnits } from "viem";
import { getProfileData } from "./account";
import { publicClientBlockNumber } from "./viem-client";

export const MAX_INT = BigInt(
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
);

export const getRandNum = (max) => {
  return Math.floor(Math.random() * max) + 1;
};
export const removeHTMLTags = (string) => {
  return string
    .replace("<p>", "")
    .replace("<h1>", "")
    .replace("<h2>", "")
    .replace("<h3>", "")
    .replace("<h4>", "")
    .replace("<h5>", "")
    .replace("<h6>", "")
    .replace("<img>", "")
    .replace("<span>", "")
    .replace("</p>", "")
    .replace("</h1>", "")
    .replace("</h2>", "")
    .replace("</h3>", "")
    .replace("</h4>", "")
    .replace("</h5>", "")
    .replace("</h6>", "")
    .replace("</img>", "")
    .replace("</span>", "")
    .replace("i", "")
    .replace("/i", "")
    .replace("b", "")
    .replace("/b", "")
    .replace("br", "")
    .replace("/br", "")
    .replace("a", "")
    .replace("/a", "");
};
export const getPasswordStrength = (value) => {
  let point = 0;
  const widthPower = ["1%", "25%", "50%", "75%", "100%"];
  let arrayTest = [/[0-9]/, /[a-z]/, /[A-Z]/, /[^0-9a-zA-Z]/];
  arrayTest.forEach((item) => {
    if (item.test(value)) {
      point += 1;
    }
  });

  return widthPower[point];
};

export const getApiURL = () => {
  return process.env.REACT_APP_PRODUCTION === "1"
    ? process.env.REACT_APP_BASE_URL_PROD
    : process.env.REACT_APP_BASE_URL_TEST;
};
export const getMarketAddress = () => {
  return process.env.REACT_APP_PRODUCTION === "1"
    ? process.env.REACT_APP_BSC_MARKET
    : process.env.REACT_APP_SEPOLIA_MARKET;
};
export const getArkerTokenAddress = () => {
  return process.env.REACT_APP_PRODUCTION === "1"
    ? process.env.REACT_APP_BSC_ARKER_TOKEN
    : process.env.REACT_APP_SEPOLIA_ARKER_TOKEN;
};
export const getNFTUtilsAddress = () => {
  return process.env.REACT_APP_PRODUCTION === "1"
    ? process.env.REACT_APP_BSC_NFT_UTILS
    : process.env.REACT_APP_SEPOLIA_NFT_UTILS;
};
export const getChainId = () => {
  return process.env.REACT_APP_PRODUCTION === "1"
    ? parseInt(process.env.REACT_APP_BSC_CHAIN_ID)
    : parseInt(process.env.REACT_APP_SEPOLIA_CHAIN_ID);
};
export const getExplorerURL = () => {
  return process.env.REACT_APP_PRODUCTION === "1"
    ? process.env.REACT_APP_EXPLORER_BSC
    : process.env.REACT_APP_EXPLORER_SEPOLIA;
};
export const getPaymentManagerAddress = () => {
  return process.env.REACT_APP_PRODUCTION === "1"
    ? process.env.REACT_APP_BSC_PAYMENT_MANAGER
    : process.env.REACT_APP_SEPOLIA_PAYMENT_MANAGER;
};

export const getArkerPriceFromKucoin = async () => {
  const res = await fetch(
    "https://api.coingecko.com/api/v3/simple/price?ids=ARKER-2&vs_currencies=usd"
  );
  const data = await res.json();
  return data["arker-2"].usd;
};

export const getArkerBalanceInUSD = (arkerBalance, arkerPrice) => {
  return (
    formatUnits(arkerBalance?.value ?? 0n, arkerBalance?.decimals, 18) *
      arkerPrice ?? 0
  ).toFixed(2);
};

export const getCharacterClassId = async (characterId) => {
  const characters = (await getProfileData()).characters;
  const character = characters.find(async (el) => {
    return (await el.id) === characterId;
  });
  return character?.classId ?? "NOT_SELECTED";
};

export function truncateETHAddress(address) {
  return address
    ? `0x${address?.slice(2, 2 + 4)}…${address.slice(address.length - 4)}`
    : "";
}

export const getBlockNumber = async () => {
  return await publicClientBlockNumber.getBlockNumber();
};

export const parseSkinNumToImage = (skin) => {
  return parseInt(skin) === 2 ? "1" : skin;
};
