import { useWriteContract } from "wagmi";

export const useClaimPayment = () => {
  const {
    writeContractAsync: claimPaymentWrite,
    error: claimPaymentError,
    isError: isClaimPaymentError,
  } = useWriteContract();

  return { claimPaymentWrite, claimPaymentError, isClaimPaymentError };
};
