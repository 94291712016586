import { createPublicClient, http } from "viem";
import { arbitrumSepolia, bsc, sepolia } from "wagmi/chains";

export const publicClientBlockNumber = createPublicClient({
  chain: process.env.REACT_APP_PRODUCTION === "1" ? bsc : sepolia,
  transport: http(),
});

export const publicClient = createPublicClient({
  chain: process.env.REACT_APP_PRODUCTION === "1" ? bsc : arbitrumSepolia,
  transport: http(),
});
