import { Link, useLocation } from "react-router-dom";
import styles from "./recover-password.module.css";
import toast, { Toaster } from "react-hot-toast";
import { useState } from "react";
import { recoverPassword, resetPassword } from "../../../services/account";
import { getPasswordStrength } from "../../../services/utils";
import { PasswordStrength } from "../password-strength/password-strength";

export const RecoverPassword = () => {
  //LOCATION VARIABLES
  const { pathname, state } = useLocation();
  const code = pathname.split("/")[3];

  //USESTATE VARIABLES
  const [email, setEmail] = useState(state?.email ?? "");
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState();
  const [showConfirmPassword, setShowConfirmPassword] = useState();

  const handleOnchangePassword = (e) => {
    let passwordStrength = "1%";
    const value = e.target.value;

    if (value.length >= 6) {
      setPassword(e.target.value);
      passwordStrength = getPasswordStrength(value);
    }

    document.getElementById("power-point").style.width = passwordStrength;
  };

  const handleOnchangeConfirmPassword = (e) => {
    let passwordStrength = "1%";
    const value = e.target.value;

    if (value.length >= 6) {
      setConfirmPassword(e.target.value);
      passwordStrength = getPasswordStrength(value);
    }

    document.getElementById("power-point").style.width = passwordStrength;
  };

  const handleRecoverPassword = async () => {
    try {
      setLoading(true);
      await recoverPassword(email);
      setLoading(false);
      toast.success("Check your email address!");
    } catch (error) {
      setLoading(false);
      toast.error(error.toString());
    }
  };

  const handleResetPassword = async () => {
    setLoading(true);
    if (password !== confirmPassword) {
      toast.error("Passwords don't match");
      setLoading(false);
      return;
    }
    try {
      await resetPassword(password, code);
      toast.success("You can login now!");
      setLoading(false);
      window.location.replace("/login");
    } catch (error) {
      setLoading(false);
      toast.error(error.toString());
    }
  };

  return (
    <div className={styles["main-container"]}>
      <Toaster />
      <div className={styles["login-container"]}>
        <Link className={styles["close-button"]} to="/login">
          <img src="/assets/images/icons/close-icon.svg" alt="" />
        </Link>
        {code ? <h1>Set new password</h1> : <h1>Recover an Arker password</h1>}
        <hr />
        <p>
          Setup a new password for your account. Remember that you will need
          this new password to access your account.
        </p>
        <div className={styles["form-container"]}>
          <form>
            {code ? (
              <>
                <div className={styles["form-item"]}>
                  <label htmlFor="password">New Password</label>
                  <div className={styles["input-container"]}>
                    <div>
                      <img
                        className={styles["password-icon"]}
                        src="/assets/images/icons/key-password.svg"
                        alt=""
                      />
                    </div>
                    <input
                      onChange={handleOnchangePassword}
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      autoComplete="new-password"
                      placeholder="Enter password"
                    />
                  </div>
                  <button
                    type="button"
                    className={`${styles["show-pass"]} ${
                      showPassword && styles["hide-pass"]
                    }`}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                  <PasswordStrength />
                </div>

                <div className={styles["form-item"]}>
                  <label htmlFor="password">Repeat Password</label>
                  <div className={styles["input-container"]}>
                    <div>
                      <img
                        className={styles["password-icon"]}
                        src="/assets/images/icons/key-password.svg"
                        alt=""
                      />
                    </div>
                    <input
                      onChange={handleOnchangeConfirmPassword}
                      type={showConfirmPassword ? "text" : "password"}
                      id="repeat-password"
                      name="password"
                      autoComplete="new-password"
                      placeholder="Enter password"
                    />
                  </div>
                  <button
                    type="button"
                    className={`${styles["show-pass"]} ${
                      showConfirmPassword && styles["hide-pass"]
                    }`}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                </div>
              </>
            ) : (
              <>
                <div className={styles["form-item"]}>
                  <label htmlFor="email">Email Address</label>
                  <div className={styles["input-container"]}>
                    <div>
                      <img
                        className={styles["password-icon"]}
                        src="/assets/images/icons/email-icon.svg"
                        alt=""
                      />
                    </div>
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      placeholder="Enter email"
                      value={email}
                    />
                  </div>
                </div>
              </>
            )}

            <button
              type="button"
              className={`button-green ${styles["login-button"]}`}
              onClick={code ? handleResetPassword : handleRecoverPassword}
            >
              {loading ? "Loading…" : "Let's Go!"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
