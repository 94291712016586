import toast from "react-hot-toast";
import { useParsePetData } from "../../../../../../hooks/petsHooks/useParsePetData";
import { MarketplaceItemCharacteristics } from "../../../../marketplace-item-characteristics/marketplace-item-characteristics";
import styles from "./select-pet-item.module.css";
import { parseSkinNumToImage } from "../../../../../../services/utils";

export const SelectPetItem = ({
  id,
  male,
  malePetSelected,
  setMalePetSelected,
  maleTypePetSelected,
  setMaleTypePetSelected,
  setFemalePetSelected,
  femalePetSelected,
  femaleTypePetSelected,
  setFemaleTypePetSelected,
}) => {
  //PETS HOOKS
  const { petData, petDataLoading } = useParsePetData(id);

  const handleSelectMalePet = () => {
    const type = petData.type;

    setMaleTypePetSelected(type);

    if (femalePetSelected && femaleTypePetSelected !== type) {
      toast.error("The pet type must be the same");
      return;
    } else {
      setMalePetSelected(id);
    }
  };

  const handleRemoveMalePet = () => {
    setMalePetSelected();
    setMaleTypePetSelected();
  };

  const handleSelectFemalePet = () => {
    const type = petData.type;

    setFemaleTypePetSelected(type);

    if (malePetSelected && maleTypePetSelected !== type) {
      toast.error("The pet type must be the same");
      return;
    } else {
      setFemalePetSelected(id);
    }
  };

  const handleRemoveFemalePet = () => {
    setFemalePetSelected();
    setFemaleTypePetSelected();
  };

  if (petDataLoading) {
    return <h1>Loading…</h1>;
  }

  if (male && petData.genre === "male") {
    return (
      <>
        <div className={styles["select-pet-item"]}>
          <div className={styles["pet-img"]}>
            <img
              src={`/assets/images/marketplace/pets/${petData.type}/${parseSkinNumToImage(petData.skin)}.gif`}
              alt=""
              width={"150px"}
            />
          </div>

          <p>{petData.name}</p>
          <MarketplaceItemCharacteristics
            rarity={petData.rarity}
            classType={"fheral"}
            genre={petData.genre ?? undefined}
            type={petData.type}
            small={true}
          />

          {malePetSelected === id ? (
            <button
              onClick={handleRemoveMalePet}
              className={`see-detail-button`}
            >
              Deselect
            </button>
          ) : (
            <button
              onClick={handleSelectMalePet}
              className={`see-detail-button`}
            >
              Select
            </button>
          )}
        </div>
      </>
    );
  }

  if (!male && petData.genre === "female") {
    return (
      <>
        <div className={styles["select-pet-item"]}>
          <div className={styles["pet-img"]}>
            <img
              src={`/assets/images/marketplace/pets/${petData.type}/${parseSkinNumToImage(petData.skin)}.gif`}
              alt=""
              width={"150px"}
            />
          </div>

          <p>{petData.name}</p>
          <MarketplaceItemCharacteristics
            rarity={petData.rarity}
            classType={"fheral"}
            genre={petData.genre ?? undefined}
            type={petData.type}
            small={true}
          />
          {femalePetSelected === id ? (
            <button
              onClick={handleRemoveFemalePet}
              className={`see-detail-button`}
            >
              Deselect
            </button>
          ) : (
            <button
              onClick={handleSelectFemalePet}
              className={`see-detail-button`}
            >
              Select
            </button>
          )}
        </div>
      </>
    );
  }
};
