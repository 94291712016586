import { useReadContract } from "wagmi";
import petsABI from "../../assets/abis/pets.json";
import { getChainId } from "../../services/utils";
import { getPetsAddress } from "../../services/pets";

export const usePetData = (petId) => {
  const { data: pet, isLoading: petLoading } = useReadContract({
    abi: petsABI,
    functionName: "_petData",
    address: getPetsAddress(),
    chainId: getChainId(),
    args: [petId],
  });

  return { pet, petLoading };
};
