import { useLocation } from "react-router-dom";
import styles from "./validate.module.css";
import { useEffect, useState } from "react";
import { validateEmail } from "../../../services/account";

export const Validate = () => {
  const { pathname } = useLocation();
  const validateCode = pathname.split("/")[3];
  const [loading, setLoading] = useState(true);
  const [statusMessage, setStatusMessage] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    const validate = async () => {
      try {
        await validateEmail(validateCode);
        setLoading(false);
        setStatusMessage("Your email has been validated sucessfully!");
      } catch (error) {
        setError(true);
        setStatusMessage(error.toString());
        setLoading(false);
      }
    };
    validate();
  }, [validateCode]);

  if (loading) {
    return (
      <div className={styles.container}>
        <h1>Loading…</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h1>Validate email</h1>
      <hr />
      <p className={`${styles["status-message"]} ${error && styles["error"]}`}>
        {statusMessage}
      </p>
    </div>
  );
};
