import { Link, Navigate } from "react-router-dom";
import styles from "./register.module.css";
import { useState } from "react";
import { getPasswordStrength } from "../../../services/utils";
import toast, { Toaster } from "react-hot-toast";
import { linkAccountWithWombat, register } from "../../../services/account";
import { useUserAuthenticated } from "../../../hooks/useUserAuthenticated";
import { PasswordStrength } from "../password-strength/password-strength";

export const Register = () => {
  //USER HOOKS
  const { isAuthenticated } = useUserAuthenticated();

  //USESTATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [username, setUsername] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onChangeHandle = (e) => {
    let passwordStrength = "1%";
    const value = e.target.value;

    if (value.length >= 6) {
      setPassword(e.target.value);
      passwordStrength = getPasswordStrength(value);
    }

    document.getElementById("power-point").style.width = passwordStrength;
  };

  const signup = async () => {
    if (!username) {
      toast.error("Enter an username");
      return;
    }

    if (!email) {
      toast.error("Enter an email");
      return;
    }

    if (!password) {
      toast.error("Enter a password");
      return;
    }

    if (!confirmPassword) {
      toast.error("Confirm your password");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords don't match");
      return;
    }

    if (password.length < 8) {
      toast.error("Password must have min 8 characters");
      return;
    }

    if (!acceptTerms) {
      toast.error("Must to accept terms");
      return;
    }

    try {
      setLoading(true);
      await register(email, password, username);

      const wombat_UID = localStorage.getItem("wombat.uid");
      if (wombat_UID) {
        await linkAccountWithWombat(wombat_UID);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.toString());
    }
  };

  if (isAuthenticated) {
    return (
      <div style={{ height: "100vh" }}>
        <Navigate to={"/marketplace"} />
      </div>
    );
  }

  return (
    <div className={styles["main-container"]}>
      <Toaster />
      <div className={styles["register-container"]}>
        <a className={styles["close-button"]} href="/marketplace">
          <img src="/assets/images/icons/close-icon.svg" alt="" />
        </a>
        <h1>Create a new account</h1>
        <hr />
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quos ex
          quasi ratione possimus sequi cupiditate quidem a dignissimos at
        </p>
        <div className={styles["form-container"]}>
          <div className={styles.form}>
            <div className={styles["form-item"]}>
              <label htmlFor="username">Username</label>
              <div className={styles["input-container"]}>
                <div>
                  <img src="/assets/images/icons/user-icon-green.svg" alt="" />
                </div>
                <input
                  type="text"
                  id="username"
                  name="username"
                  placeholder="Enter username"
                  minLength="4"
                  maxLength="14"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </div>

            <div className={styles["form-item"]}>
              <label htmlFor="email">Email Address</label>
              <div className={styles["input-container"]}>
                <div>
                  <img src="/assets/images/icons/email-icon.svg" alt="" />
                </div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div className={styles["form-item"]}>
              <label htmlFor="password">Password</label>
              <div className={styles["input-container"]}>
                <div>
                  <img
                    className={styles["password-icon"]}
                    src="/assets/images/icons/key-password.svg"
                    alt=""
                  />
                </div>
                <input
                  onChange={onChangeHandle}
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  autoComplete="new-password"
                  placeholder="Enter the password"
                />
              </div>
              <button
                type="button"
                className={`${styles["show-pass"]} ${
                  showPassword && styles["hide-pass"]
                }`}
                onClick={() => setShowPassword(!showPassword)}
              />
              <PasswordStrength />
            </div>

            <div className={styles["form-item"]}>
              <label htmlFor="confirm-password">Confirm password</label>
              <div className={styles["input-container"]}>
                <div>
                  <img
                    className={styles["password-icon"]}
                    src="/assets/images/icons/key-password.svg"
                    alt=""
                  />
                </div>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirm-password"
                  name="password"
                  autoComplete="new-password"
                  placeholder="Enter the password again"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button
                  type="button"
                  className={`${styles["show-pass"]} ${
                    showConfirmPassword && styles["hide-pass"]
                  }`}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              </div>
            </div>
            <p>
              Already have an account? <Link to="/login">Log in</Link>
            </p>

            <div className={styles["accept-terms"]}>
              <div
                className={styles["accept-terms-checkbox"]}
                onClick={() => setAcceptTerms(!acceptTerms)}
              >
                {acceptTerms && (
                  <img src="/assets/images/icons/checkbox-green.svg" alt="" />
                )}
                <input type="checkbox" name="" id="" />
              </div>

              <label htmlFor="">
                I agree to the Arker The Legend of Ohm{" "}
                <a href="/privacy">Terms of Use</a> and{" "}
                <a href="/privacy">Privacy Policy</a>.
              </label>
            </div>

            <button
              type="button"
              className={`button-green ${styles["register-button"]}`}
              onClick={() => signup()}
            >
              {loading ? "Loading…" : "Sign up"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
