import { useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./header.module.css";
import { useUserAuthenticated } from "../../hooks/useUserAuthenticated";
import { logout } from "../../services/account";

export const Header = () => {
  const { isAuthenticated } = useUserAuthenticated();
  const [showGameInfo, setShowGameInfo] = useState(false);
  const [showExchanges, setShowExchanges] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);

  const handleLogout = () => {
    logout();
  };

  return (
    <header>
      <div className={`${styles.navbar} arker-container`}>
        <button
          className={styles["menu-icon"]}
          onClick={() => {
            setShowSideMenu(true);
          }}
        >
          <img src="/assets/images/icons/menu-icon.svg" alt="" />
        </button>
        <NavLink
          to="/"
          className={({ isActive }) => (isActive ? styles.active : "")}
        >
          <img
            className={styles.logo}
            src="/assets/images/logos/logo-arker.png"
            alt="Arker the legend of ohm logo"
          />
        </NavLink>

        <div
          className={`${styles["mobile-side-menu"]} ${
            showSideMenu ? styles["show-menu"] : undefined
          }`}
        >
          <div className={styles["mobile-side-menu-header"]}>
            <button
              className={styles["close-icon"]}
              onClick={() => {
                setShowSideMenu(false);
              }}
            >
              <img src="/assets/images/icons/close-icon.svg" alt="" />
            </button>
            <img
              className={styles.logo}
              src="/assets/images/logos/logo-arker.png"
              alt="Arker the legend of ohm logo"
            />
          </div>
          <div className={styles["navbar-items"]}>
            <div className={styles["dropdown-container"]}>
              <button
                className={showGameInfo ? styles["button-active"] : undefined}
                onClick={() => {
                  setShowGameInfo(!showGameInfo);
                  setShowExchanges(false);
                  setShowAccount(false);
                }}
              >
                Game Info{" "}
                <img
                  className={styles["arrow-icon"]}
                  src={`/assets/images/icons/${
                    showGameInfo
                      ? "bottom-arrow-icon-green"
                      : "bottom-arrow-icon"
                  }.svg`}
                  alt="Arrow icon to dropdown"
                />
              </button>
              {showGameInfo && (
                <div className={styles["dropdown-container-items"]}>
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://docs.playarker.com/"
                      >
                        About arker
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://docs.playarker.com/gameplay"
                      >
                        gameplay
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://docs.playarker.com/pets"
                      >
                        pets
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://docs.playarker.com/items"
                      >
                        items
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://docs.playarker.com/characters"
                      >
                        characters
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <NavLink
              to="https://medium.com/@arker-official"
              target="_blank"
              className={({ isActive }) => (isActive ? styles.active : "")}
            >
              news
            </NavLink>

            <NavLink
              to="/assets/docs/whitepaper.pdf"
              target="_blank"
              className={({ isActive }) => (isActive ? styles.active : "")}
            >
              whitepaper
            </NavLink>
            {/* <NavLink
              to="https://app.playarker.com/pet-fight"
              className={({ isActive }) => (isActive ? styles.active : "")}
            >
              pet fight
            </NavLink> */}

            <div className={styles["dropdown-container"]}>
              <button
                className={showExchanges ? styles["button-active"] : undefined}
                onClick={() => {
                  setShowExchanges(!showExchanges);
                  setShowAccount(false);
                  setShowGameInfo(false);
                }}
              >
                Buy Arker{" "}
                <img
                  className={styles["arrow-icon"]}
                  src={`/assets/images/icons/${
                    showExchanges
                      ? "bottom-arrow-icon-green"
                      : "bottom-arrow-icon"
                  }.svg`}
                  alt="Arrow icon to dropdown"
                />
              </button>
              {showExchanges && (
                <div className={styles["dropdown-container-items"]}>
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.kucoin.com/trade/ARKER-USDT"
                      >
                        Kucoin
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://pancakeswap.finance/swap?outputCurrency=0x9c67638c4Fa06FD47fB8900fC7F932f7EAB589De"
                      >
                        Pancake
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.mexc.com/exchange/ARKER_USDT"
                      >
                        MEXC
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <NavLink
              to="/marketplace"
              className={({ isActive }) => (isActive ? styles.active : "")}
            >
              shop
            </NavLink>
          </div>
          <div className={styles["right-items"]}>
            {/* <img
              className={styles["lang-icon"]}
              src="/assets/images/icons/world-icon.svg"
              alt=""
            /> */}
            <div className={styles["dropdown-container"]}>
              <button
                className={styles["button-brown"]}
                onClick={() => {
                  setShowAccount(!showAccount);
                  setShowGameInfo(false);
                }}
              >
                <img
                  className={styles["user-icon"]}
                  src="/assets/images/icons/user-icon.svg"
                  alt="User icon to user dropdown"
                />{" "}
                Account{" "}
                <img
                  className={styles["arrow-icon"]}
                  src="/assets/images/icons/bottom-arrow-icon.svg"
                  alt="Arrow icon to dropdown"
                />
              </button>
              {showAccount && (
                <>
                  {isAuthenticated ? (
                    <div
                      className={`${styles["dropdown-container-items"]} ${styles["account-dropdown"]}`}
                    >
                      <button
                        className={`simple-button ${styles["header-login-btn"]}`}
                        onClick={handleLogout}
                      >
                        Log out
                      </button>
                      <ul>
                        <li className="font-normal">
                          <img
                            className={styles["account-dropdown-icon"]}
                            src="/assets/images/icons/settings-icon.svg"
                            alt="Arrow icon to dropdown"
                          />{" "}
                          <a href="/account-settings/overview">
                            Account Settings
                          </a>
                        </li>
                        <li className="font-normal">
                          <img
                            className={styles["account-dropdown-icon"]}
                            src="/assets/images/icons/shop-icon.svg"
                            alt="Arrow icon to dropdown"
                          />{" "}
                          <a href="/marketplace">Shop</a>
                        </li>
                        {/* <li className="font-normal">
                          <img
                            className={styles["account-dropdown-icon"]}
                            src="/assets/images/icons/suport-icon.svg"
                            alt="Arrow icon to dropdown"
                          />{" "}
                          <a href="/account-settings/support">Support</a>
                        </li> */}
                      </ul>
                    </div>
                  ) : (
                    <div
                      className={`${styles["dropdown-container-items"]} ${styles["account-dropdown"]}`}
                    >
                      <button
                        className={`simple-button ${styles["header-login-btn"]}`}
                      >
                        <a href="/login">Log in</a>
                      </button>
                      <ul>
                        <li className="font-normal">
                          <img
                            className={styles["account-dropdown-icon"]}
                            src="/assets/images/icons/signup-icon.svg"
                            alt="Arrow icon to dropdown"
                          />{" "}
                          <a href="/register">Sign Up</a>
                        </li>
                        <li className="font-normal">
                          <img
                            className={styles["account-dropdown-icon"]}
                            src="/assets/images/icons/shop-icon.svg"
                            alt="Arrow icon to dropdown"
                          />{" "}
                          <a href="/marketplace">Shop</a>
                        </li>
                      </ul>
                    </div>
                  )}
                </>
              )}
            </div>

            <button className={`button-green ${styles["header-playnow-btn"]}`}>
              <a
                href="/account-settings/download"
                target="_blank"
                rel="noreferrer"
              >
                Play now
              </a>
            </button>
          </div>
        </div>

        <a
          href="/account-settings/download"
          target="_blank"
          rel="noreferrer"
          className={styles["download-icon"]}
        >
          <img src="/assets/images/icons/download-icon.svg" alt="" />
        </a>
      </div>
    </header>
  );
};
